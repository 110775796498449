import * as actions from './auth-actionTypes';
import { notification } from 'antd';

export const login = (email, password) => {

    return (dispatch) => {
        dispatch({ type: actions.AUTH_LOGIN, payload: { email, password } });
        localStorage.setItem("token", "I am now logged in");
        
        notification["success"]({
            message: "Herzlich willkommen",
            description: "Du hast dich erfolgreich eingeloggt",
        });
    };
};