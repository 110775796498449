import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import treeData from "../../../utils/permissions.json";

import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";

const { SHOW_PARENT } = TreeSelect;

export const CreateRoles = ({ isVisible, onClose, handleGetAllRoles }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [value, setValue] = useState([]);

  const handleSubmit = (formValues) => {
    Api("roles", "POST", formValues)
      .then(() => {
        toastNotification("success", `${t("role_successfully_created")}`);
        handleGetAllRoles();
        form.resetFields();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_create_role")}`)
      );
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  const onChange = (value) => {
    setValue(value);
  };

  const tProps = {
    treeData,
    value: value,
    onChange: onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: `${t("select")}`,
    style: {
      width: "100%",
    },
  };

  return (
    <Drawer
      title={t("create_new_role")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="roles-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="roles-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("create_role")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="permissions"
              label={t("permissions_role")}
              rules={[{ required: true }]}
            >
              <TreeSelect {...tProps} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
