import React, { useState } from "react";
import { Space, Input, Select, Button, Form } from "antd";
import { useTranslation } from "react-i18next";

import "../../../public/sass/components/_filters.scss";

const { Search } = Input;
const { Option } = Select;

export const Filters = ({
  handleFilter,
  handleGetAllCategories,
  search,
  setSearch,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const clearFilters = () => {
    form.resetFields();
    handleGetAllCategories();
    setSearch(false);
  };

  // return null;
  return (
    <div className="wrapper">
      <Space direction="horizontal" className="filters">
        <Form
          form={form}
          id="categories-filters"
          layout="inline"
          scrollToFirstError
          onFinish={handleFilter}
        >
          <Form.Item name="category_type">
            <Select
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              placeholder={t("category_type")}
              className="min-width-select-component"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value="Category">{t("category")}</Option>
              <Option value="Subcategory">{t("subcategory")}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="status">
            <Select
              name="status"
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              placeholder="Status"
              className="min-width-select-component"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value={1}>{t("active")}</Option>
              <Option value={0}>{t("not_active")}</Option>
            </Select>
          </Form.Item>

          <Button
            type="primary"
            form="categories-filters"
            key="submit"
            htmlType="submit"
          >
            Filter
          </Button>
          {search && (
            <Button onClick={clearFilters} className="button-style">
              {t("clear_filter")}
            </Button>
          )}
        </Form>
      </Space>
    </div>
  );
};
