import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Descriptions, Modal } from "antd";
import { ChangePassword } from "../../containers/Dashboard/ChangePassword";
import Api from "../../api";

import Edit from "../../public/images/dashboard/tables/edit.svg";
import "../../public/sass/pages/_my-profile.scss";

const MyProfile = () => {
  const [t, i18n] = useTranslation();
  const [userData, setUserData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getUserData = () => {
    Api("users/me", "GET", null).then((data) => {
      setUserData(data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="description">
      <h3 className="title-head">{t("user_profile")}</h3>
      <Descriptions
        layout="vertical"
        className="description-items"
        column={{ xxl: 5, xl: 5, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item
          label={t("role")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.name?.charAt(0)?.toUpperCase() + userData?.name?.slice(1)}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("user_number")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.user_id}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("firstname")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.firstname}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("lastname")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.lastname}{" "}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("password_2")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          ********
          <a type="primary" onClick={showModal} className="icon-style">
            <img src={Edit} alt="Edit" />
          </a>
          <Modal
            title={t("change_password")}
            visible={isModalVisible}
            onCancel={handleCancel}
          >
            <ChangePassword />
          </Modal>
        </Descriptions.Item>

        <Descriptions.Item
          label={t("phone")}
          className="item"
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
        >
          {userData?.phone}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("email")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.email}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("street")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.street}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("house_number")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.house_number}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("zip")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.zip}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("city")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.city}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("country")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
        >
          {userData?.country}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("Status")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {t(userData?.status === 1 ? "active" : "status_not_active")}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("company")}
          labelStyle={{
            fontSize: "19px",
            color: "#000000",
            lineHeight: "24px",
          }}
          className="item"
        >
          {userData?.company?.name}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
export default MyProfile;
