import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

export const CountPage = () => {
  return (
    <Page size="A4" style={{ backgroundColor: "#fff", padding: "40px" }}>
      <View
        style={{
          color: "#364059",
          padding: "20px 15px",
        }}
      >
        <Text style={{ fontSize: '25px' }}>INHALTSVERZEICHNIS</Text>
      </View>
      <View
        style={{ marginLeft: "20px", marginTop: "50px", marginRight: "5px" }}
      >
        <View style={{ 
            lineHeight: "2",
            color: '#364059',
        }}>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>1. Präambel</Text>
            </View>
            <View
              style={{
                border: "",
                width: "75%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>2. Inhaltsverzeichnis</Text>
            </View>
            <View
              style={{
                border: "",
                width: "60%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>3. Gesamtwertermittlung: Aufteilung</Text>
            </View>
            <View
              style={{
                border: "",
                width: "35%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>4. Gesamtwertermittlung: Übersicht</Text>
            </View>
            <View
              style={{
                border: "",
                width: "35%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>5. Gesamtwertermittlung</Text>
            </View>
            <View
              style={{
                border: "",
                width: "50%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>6. Bemerkung zur Sachaufnahme</Text>
            </View>
            <View
              style={{
                border: "",
                width: "40%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>7. Bemerkung zum Gutachten</Text>
            </View>
            <View
              style={{
                border: "",
                width: "45%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>8. Vollständigkeitsvereinbarung</Text>
            </View>
            <View
              style={{
                border: "",
                width: "45%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>9. Anlagespiegel</Text>
            </View>
            <View
              style={{
                border: "",
                width: "70%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>10. Sicherungsübereignungsverträge</Text>
            </View>
            <View
              style={{
                border: "",
                width: "35%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>11. Inventurlisten</Text>
            </View>
            <View
              style={{
                border: "",
                width: "70%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>12. Fahrzeugpapiere</Text>
            </View>
            <View
              style={{
                border: "",
                width: "65%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>13. Fotodokumentation</Text>
            </View>
            <View
              style={{
                border: "",
                width: "60%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyItems: "start",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              lineHeight: "2",
              color: '#364059',
            }}
          >
            <View>
              <Text>14. Allgemeine Auftragsbedingungen</Text>
            </View>
            <View
              style={{
                border: "",
                width: "35%",
                marginTop: "-2px",
              }}
            ></View>
            <View>
              <Text></Text>
            </View>
          </View>
        </View>
      </View>
      <Text
        style={{
          position: "absolute",
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: "center",
          color: "grey",
        }}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
};
