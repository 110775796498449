import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PlusCircleTwoTone } from "@ant-design/icons";
import { Row, Col, Form, Button } from "antd";
import { CreateAddress } from "../../../components/Drawer/Clients/CreateAddress";
import { AddressForm } from "../../../components/Drawer/Address/AddressForm";

import "../../../public/sass/pages/_address-container.scss";
import api from "../../../api";

export const AddressContainer = ({ id, onClose }) => {
  const { t, i18n } = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const toggleDrawer = () => setVisibleCreate(!isVisibleCreate);

  const getAddressData = () => {
    api("addresses/user/" + id, "GET", null).then((data) => {
      setAddressData(data);
      // form.setFieldsValue(data[0]);
    });
  };

  useEffect(() => {
    getAddressData();
  }, [onClose]);

  return (
    <div>
      {" "}
      <Row className="row-style">
        <Col span={12} className="col-style">
          <Form.Item name="more_addresses" onClick={toggleDrawer}>
            <Button className="btn-style">
              <PlusCircleTwoTone className="icon-style" />{" "}
              {t("add_new_address")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <CreateAddress
        isVisible={isVisibleCreate}
        getAddressData={getAddressData}
        id={id}
        onClose={toggleDrawer}
      />
      <AddressForm
        id={id}
        addressData={addressData}
        getAddressData={getAddressData}
      />
    </div>
  );
};
