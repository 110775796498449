import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PdfFooterCalculation } from "./PdfFooterCalculation";
import _ from "lodash";

const BORDER_COLOR = "#a9a9a9";

const styles = StyleSheet.create({
  body: {
    padding: "15px 25px",
    fontFamily: 'Poppins'
  },
  tableHeader: {
    border: "1px solid #a9a9a9",
    fontSize: "14px",
    padding: "5px",
    // fontWeight: 700,
    color: '#1F2C4B'
  },
  tableCell: {
    flexDirection: "row",
    border: "1px solid #a9a9a9",
  },
  cellLabel: {
    fontSize: "14px",
    padding: "3px",
    marginTop: "-1px",
    borderRight: "1px solid #a9a9a9",
  },
  t10: {
    width: "10%",
    textAlign: "left",
  },
  t45Left: {
    width: "45%",
    textAlign: "left",
  },
  t45: {
    width: "45%",
    textAlign: "right",
  },
  bottomTotal: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px",
    color: '#1F2C4B'
  },
  headerNumber: {
    textAlign: "right",
    fontSize: "10px",
  },
  pageHeader: {
    fontSize: "22px",
    marginTop: "50px",
    color: '#3E5078',
  },
  inlineHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "75%",
    fontSize: "12px",
    lineHeight: 1.7,
    marginTop: 15,
    color: '#3E5078'
  },
  lineGray: {
    height: "1px",
    backgroundColor: "#ECEDF1",
    margin: "20px 0",
  },
  tableContainer: {
    marginTop: "50px",
    // marginLeft: "21.5%",
  },
});

export const CalculationAufteilung = ({ data }) => {
  let totalAmountValue = 0;
  const getAllFreie = data?.products.filter((i) => i.rights === "Freie Masse");
  let groupByAssetType = _.groupBy(getAllFreie, "asset_type");
  const getAbsAussoderung = data?.products.filter((i) => i.rights === "Absonderung" || i.rights === "Aussonderung");
  let groupByThirdParty = _.groupBy(getAbsAussoderung, "third_party_id");


  const getAllByMiete = data?.products.filter((i) => i.rights === "Aussonderung" && i.disposal_rights === "Miete");
  let groupByAssetTypeMeite = _.groupBy(getAllByMiete, "asset_type");
  
  const renderOption = (number) => {
    switch (number) {
      case "12":
        return "Grundstücke und Bauten";
      case "15":
        return "Maschinen- u. techn. Anlagen";
      case "16":
        return "Betriebs- und Geschäftsausstattungn";
      case "39":
        return "Fuhrpark";
      case "23":
        return "Roh-, Hilf- u. Betriebsstoffe";
      case "24":
        return "Unfertige/Halbfertige Waren";
      case "25":
        return "Fertige Erzeugnisse u. Waren";
      default:
        return "Unfertige / Halbfertige Waren";
    }
  };

  return (
    <Page size="A4" style={styles.body} orientation="landscape" wrap>
      <View fixed style={styles.headerNumber}>
        <Text>Seite 1/3</Text>
      </View>

      <View style={styles.pageHeader}>
        <Text>AUFTEILUNG DER GESAMTWERTERMITTLUNG</Text>
        <Text style={{ fontSize: "16px", color: '#8F8F8F' }}>
          (nach Freier Masse sowie Fremd- und Drittrechten)
        </Text>
      </View>

      <View style={styles.inlineHeader}>
        <View>
          <Text style={{ fontWeight: 700, marginRight: "20px" }}>
            Auftraggeber:
          </Text>
          <Text style={{ fontWeight: 700, marginRight: "20px" }}>
            Verfahren:
          </Text>
        </View>
        <View>
          <Text>
            {`${data?.client?.name}, ${data?.client?.street} ${data?.client?.house_number}, ${data?.client?.zip} ${data?.client?.city}, ${data?.client?.country}`}
          </Text>
          <Text>
            {data?.addresses?.length &&
              `${data?.name}, ${data?.addresses[0]?.street || ""} ${data?.addresses[0]?.house_number || ""}, ${data?.addresses[0]?.zip || ""} ${data?.addresses[0]?.city || ""}, ${data?.addresses[0]?.country || ""}`}
          </Text>
        </View>
      </View>

      <View style={styles.lineGray}></View>

      <View style={styles.tableContainer} wrap={false}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: "14px",
            // color: "#1F2C4B",
            color: "#3E5078",
            backgroundColor: '#ecedf1',
            padding: 10,
            paddingBottom: 0,
          }}
        >
          <Text style={{  marginBottom: 10 }}>Freie Masse</Text>
          <Text style={{ marginBottom: 10 }}>
            Zerschlagungswert
          </Text>
        </View>

        <View style={styles.tableHeader}>
          <Text>I Anlagevermögen</Text>
        </View>
        {Object.keys(groupByAssetType).length
          ? Object.keys(groupByAssetType).map((i) => {
              const data = groupByAssetType[i];
              let total = 0;
              data.map((i) => {
                total += parseInt(i?.breakdown_value);
                return i;
              });

              data[0].totalAmount = total;

              data.map((item) => {
                if (item.totalAmount) {
                  totalAmountValue += item?.totalAmount;
                }
              });

              return (
                <View key={i} style={styles.tableCell}>
                  <View style={[styles.cellLabel, styles.t10]}>
                    <Text>{i}</Text>
                  </View>
                  <View style={[styles.cellLabel, styles.t45Left]}>
                    <Text>{renderOption(i)}</Text>
                  </View>
                  <View style={[styles.cellLabel, styles.t45]}>
                    <Text>{data[0].totalAmount} €</Text>
                  </View>
                </View>
              );
            })
          : null}

        <View style={[styles.lineGray, { margin: "10px 0" }]}></View>
        <View style={styles.bottomTotal}>
          <Text style={{ fontSize: "16px" }}>Ergebnis</Text>
          <Text style={{ fontSize: "16px" }}>
            {totalAmountValue} €
          </Text>
        </View>
      </View>

      {Object.keys(groupByThirdParty).length
        ? Object.keys(groupByThirdParty).map((k) => {
          const item = groupByThirdParty[k];

          const data = groupByThirdParty[k];
          let total = 0;
          data.map((i) => {
            total += parseInt(i?.breakdown_value);
            return i;
          });

          data[0].totalAmount = total;

          data.map((item) => {
            if (item.totalAmount) {
              totalAmountValue += item?.totalAmount;
            }
          });

            return (
              <View key={k} style={styles.tableContainer} wrap={false}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "14px",
                    color: "#3E5078",
                    backgroundColor: '#ecedf1',
                    padding: 10,
                    paddingBottom: 0,
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    {item[0]?.third_party?.name || ""}
                  </Text>
                  <Text style={{ fontWeight: 700, marginBottom: 10 }}></Text>
                </View>

                {item.map((i, idx) => {
                  return (
                      <View key={idx} style={styles.tableCell}>
                        <View style={[styles.cellLabel, styles.t10]}>
                          <Text>{i?.asset_type}</Text>
                        </View>
                        <View style={[styles.cellLabel, styles.t45Left]}>
                          <Text>{renderOption(i?.asset_type)}</Text>
                        </View>
                        <View style={[styles.cellLabel, styles.t45]}>
                          <Text>{i?.totalAmount || 0} €</Text>
                        </View>
                      </View>
                  )
                })}

                <View style={[styles.lineGray, { margin: "10px 0" }]}></View>
                <View style={styles.bottomTotal}>
                  <Text style={{ fontSize: "16px"}}>
                    Ergebnis
                  </Text>
                  <Text style={{ fontSize: "16px", }}>
                    {totalAmountValue} €
                  </Text>
                </View>
              </View>
            );
          })
        : null}

      <View style={styles.tableContainer} wrap={false}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "14px",
                    color: "#1F2C4B"
                  }}
                >
                  <Text style={{ fontWeight: 700, marginBottom: 10 }}>
                    Miete
                  </Text>
                  <Text style={{ fontWeight: 700, marginBottom: 10 }}></Text>
                </View>
        {Object.keys(groupByAssetTypeMeite).length
          ? Object.keys(groupByAssetTypeMeite).map((i) => {
              const data = groupByAssetTypeMeite[i];
              let total = 0;
              data.map((i) => {
                total += parseInt(i?.breakdown_value);
                return i;
              });

              data[0].totalAmount = total;

              data.map((item) => {
                if (item.totalAmount) {
                  totalAmountValue += item?.totalAmount;
                }
              });

              return (
                <View key={i} style={styles.tableCell}>
                  <View style={[styles.cellLabel, styles.t10]}>
                    <Text>{i}</Text>
                  </View>
                  <View style={[styles.cellLabel, styles.t45Left]}>
                    <Text>{renderOption(i)}</Text>
                  </View>
                  <View style={[styles.cellLabel, styles.t45]}>
                    <Text>{data[0].totalAmount} €</Text>
                  </View>
                </View>
              );
            })
          : null}

        <View style={[styles.lineGray, { margin: "10px 0" }]}></View>
        <View style={styles.bottomTotal}>
          <Text style={{ fontSize: "16px" }}>Ergebnis</Text>
          <Text style={{ fontSize: "16px"}}>
            {totalAmountValue} €
          </Text>
        </View>
      </View>


      <PdfFooterCalculation />
    </Page>
  );
};
