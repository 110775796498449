import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const Loading = ({ size }) => <Spin size={size} />;
export default Loading;

Loading.propTypes = {
    size: PropTypes.string,
};
  
Loading.defaultProps = {
    size: 'small',
};
