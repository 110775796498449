import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../../../public/sass/pages/_database.scss";
import { Filters } from "../../../components/Drawer/Database/Filter";
import { CreatePositions } from "../../../components/Drawer/Database/CreatePositions";
import DatabaseTable from "../../../components/Tables/Database";

import { Input } from "antd";
import Api from "../../../api";

import { SearchBy } from "../../../components/Tables/Database/SearchBy";
import axios from "axios";
import { toastNotification } from "../../../utils/toastNotification";
import productsJson from "../../../utils/products.json";

const DatabaseContainer = () => {
  const [t, i18n] = useTranslation();

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [filterproducts, setFilterProducts] = useState([]);
  const [total, setTotal] = useState([]);
  const [isSearch, setSearch] = useState(false);

  const handleSearch = (e) => {
    let value = e.toLowerCase();

    if (value.length > 2) {
      setLoading(true);

      Api(`products/search/` + value, "GET", null)
        .then((data) => {
          setProducts(data?.results);
          setTotal(data?.length);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (value.length === 0) {
      handleGetAllProducts(10, 1);
    }
  };

  useEffect(() => {
    handleGetAllProducts(10, 1);
  }, []);

  const handleGetAllProducts = (pageSize, page) => {
    setLoading(true);
    Api(`products?pageSize=${pageSize}&page=${page}`, "GET", null)
      .then((data) => {
        setTotal(data?.total);
        setProducts(data?.results);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className="database-container">
      <SearchBy onSearch={handleSearch} data={products} t={t} />
      {/* <Filters {...handleSearch} /> */}
      <DatabaseTable
        handleGetAllProducts={handleGetAllProducts}
        data={products}
        loading={loading}
        total={total}
      />
    </div>
  );
};
export default DatabaseContainer;
