import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Filters } from "../../../components/Drawer/Users/Filters";
import { CreateUsers } from "../../../components/Drawer/Users/CreateUsers";
import { message, Button } from "antd";
import { toastNotification } from "../../../utils/toastNotification";
import { EditUser } from "../../../components/Drawer/Users/EditUser";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import UsersTable from "../../../components/Tables/Users";
import Api from "../../../api";
import "../../../public/sass/pages/_users.scss";

const UsersContainer = () => {
  const { t, i18n } = useTranslation();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [editData, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userData, setUserData] = useState("");
  const [search, setSearch] = useState(false);

  useEffect(() => {
    getUserData();
    handleGetAllCompanies();
  }, []);

  const getUserData = () => {
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
      Api("users/me", "GET", null).then((data) => {
        setUserData(data);
        handleGetUsersByCompany(data.company_id);
      });
    } else {
      Api("users/by-company/" + company_id, "GET", null).then((data) => {
        setUserData(data);
        handleGetUsersByCompany(data.company_id);
      });
    }
  };
  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetUsersByCompany = (id) => {
    Api("users", "GET", null)
      .then((data) => {
        setUsers(data?.users);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleGetAllUsers = () => {
    Api("users", "GET", null)
      .then((data) => {
        setUsers(data.users);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getUserById = (id) => {
    setLoading(true);
    Api("users/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteUser = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("users/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("user_successfully_deleted")}`);
        handleGetAllUsers();
      })
      .catch(() => toastNotification("error", `${t("error_delete_user")}`));
  };

  const handleGetAllCompanies = () => {
    Api("companies", "GET", null)
      .then((data) => {
        setCompanies(data.results);
      })
      .catch((err) => console.log(err));
  };

  const handleFilter = (filterValues) => {
    if (filterValues.company == undefined && filterValues.status == undefined) {
      setSearch(false);
      return;
    }
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
      Api(
        `users/filter/users?company=${filterValues.company}&status=${
          filterValues.status
        }&pageSize=${100}&page=${0}`,
        "GET",
        null
      )
        .then((data) => {
          setSearch(true);
          setUsers(data?.results);
        })
        .catch(() => toastNotification("error", `Error while filtering`));
    } else {
      Api(
        `users/filter/users/by-company/${company_id}?company=${
          filterValues.company
        }&status=${filterValues.status}&pageSize=${100}&page=${0}`,
        "GET",
        null
      )
        .then((data) => {
          setSearch(true);
          setUsers(data?.results);
        })
        .catch(() => toastNotification("error", `Error while filtering`));
    }
  };

  return (
    <div className="users-container">
      <div className="header">
        <Filters
          companies={companies}
          handleFilter={handleFilter}
          handleGetAllUsers={handleGetAllUsers}
          search={search}
          setSearch={setSearch}
        />

        {permissionsFilter("Users", "UserCreate") && (
          <Button
            className="button"
            onClick={() => handleModal("create", true)}
          >
            {t("add_user")}
          </Button>
        )}

        <CreateUsers
          onClose={handleModal}
          userData={userData}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllUsers={handleGetAllUsers}
          companies={companies}
        />

        <EditUser
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllUsers={handleGetAllUsers}
          onClose={handleModal}
        />
      </div>
      <UsersTable
        users={users}
        getUserById={getUserById}
        deleteUser={deleteUser}
        loading={loading}
      />
    </div>
  );
};
export default UsersContainer;
