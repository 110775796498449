import React, { useState, useEffect } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import { AddressContainer } from "../../../containers/Dashboard/Addresses/AddressContainer";
import Api from "../../../api";
import Edit from "../../../public/images/dashboard/tables/edit.svg";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import "../../../public/sass/components/drawer/_tabs.scss";

const { Option } = Select;

export const EditCustomer = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllCustomers,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };

      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("clients/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", `${t("customer_edited_successfully")}`);
        handleGetAllCustomers();
        onClose();
        setEdit(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("error_edit_customer")}`);
      });
    // onClose();
  };

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("edit_customer")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      destroyOnClose={true}
      className="body-style"
      footer={
        <div className="footer-style">
          <Button disabled={!isEdit} onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            disabled={!isEdit}
            type="primary"
            form="customer-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Tabs
        defaultActiveKey="1"
        className="tab-style"
        forceRender={true}
      >
        <TabPane tab={t("address")} key="1" className="tab-pane-style">
          <Form
            form={form}
            validateMessages={validateMessages}
            id="customer-form"
            layout="vertical"
            scrollToFirstError
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={22}></Col>
              <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
                <a onClick={() => setEdit(true)}>
                  <img src={Edit} alt="Edit" />
                </a>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="client_id"
                  label={t("customer_number")}
                  rules={[{ required: true }]}
                >
                  <Input type="number" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={t("name")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="owner_firstname"
                  label={t("owner_firstname")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="owner_lastname"
                  label={t("owner_lastname")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label={t("street")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="house_number"
                  label={t("house_number")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="zip"
                  label={t("zip")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label={t("city_name")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label={t("country")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="Deutschland">{t("germany")}</Option>
                    <Option value="Österreich">{t("austria")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={t("phone")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label={t("email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="hrb_number"
                  label={t("hrb_number")}
                  rules={[{ required: false }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="ustid_number"
                  label={t("ustid_number")}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="tax_number"
                  label={t("tax_number")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value={1}>{t("active")}</Option>
                    <Option value={0}>{t("not_active")}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={t("addresses")} key="2" className="tab-pane-style">
          <AddressContainer onClose={onClose} id={id} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
