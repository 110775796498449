import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './rootReducer';

const persistConfig = {
  key: 'auctioline-app',
  storage: storage,
  blacklist: ['nav', 'loadingReducer'],
  debug: true,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const getMiddleware = () => {
  if (process.env.NODE_ENV === "development") {
    return applyMiddleware(thunk, reduxImmutableStateInvariant());
  }
  return applyMiddleware(thunk);
};

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(getMiddleware())
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);
export { store, persistor };