import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

// Import antd css/scss
import "antd/dist/antd.css";
import "./public/sass/main.scss";

// Import main routes
import ROUTES, { RenderRoutes } from "./navigation/routes";

// Import store redux/redux-thunk
import { store } from './store/configureStore';

// Import i18n (localization)
import './i18n';

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <RenderRoutes routes={ROUTES} />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);