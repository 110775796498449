import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import moment from 'moment';

import "../../../../public/sass/components/drawer/_createDrawers.scss";
import { EditRequestTabs } from "./EditRequestTabs";

import { PdfDocument } from "../pdf/PdfDocument";

export const EditRequest = ({
  isVisible,
  onClose,
  data,
  getOpenRequests,
  getFinishedRequests,
  type,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [isEdit, setEdit] = useState(false);

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const renderPdf = async (documentData) => {
    generatePdfDocument(documentData);
  };

  const generatePdfDocument = async (documentData) => {
    const blob = await pdf(
      <PdfDocument title="My PDF" data={documentData} />
    ).toBlob();

    saveAs(blob,  `${documentData?.owner_firstname}_${documentData?.owner_lastname}_${moment().format('DD.MM.YYYY')}.pdf`);
  };

  return (
    <Drawer
      title={t("new_request")}
      width={750}
      onClose={onReset}
      visible={isVisible}
      destroyOnClose
      className="body-style"
      footer={
        <Row gutter={16}>
          <Col span={12}>
            <Button
              disabled={false}
              onClick={() => renderPdf(data)}
              className="button"
            >
              {t("download_pdf")}
            </Button>

            <Button 
              disabled={false} 
              className="button">
              {t("send_email")}
            </Button>
          </Col>

          <Col span={12} className="footer-style">
            <Button
              disabled={!isEdit}
              onClick={onClose}
              className="button-style"
            >
              {t("cancel")}
            </Button>
            <Button
              disabled={!isEdit}
              type="primary"
              form="requests-form"
              key="submit"
              htmlType="submit"
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      }
    >
      <EditRequestTabs
        onClose={onClose}
        isVisible={isVisible}
        data={data}
        getOpenRequests={getOpenRequests}
        getFinishedRequests={getFinishedRequests}
        type={type}
        setEdit={setEdit}
        isEdit={isEdit}
      />
    </Drawer>
  );
};
