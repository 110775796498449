import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";

import "../../../public/sass/components/drawer/positions/_positions.scss";
import "../../../public/sass/components/drawer/_createDrawers.scss";

import Edit from "../../../public/images/dashboard/tables/edit.svg";

export const EditWord = ({
  isVisible,
  editData,
  id,
  onClose,
  handleGetAllWords,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    const response = { ...editData };
    return form.setFieldsValue(response);
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("words/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", `${t("word_successfully_edited")}`);
        handleGetAllWords();
        onClose();
        setEdit(false);
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_edit_word")}`)
      );
  };

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      width={720}
      onClose={onReset}
      visible={isVisible}
      title={t("edit_word")}
      className="body-style"
      footer={
        <div className="footer-style">
          <Button disabled={!isEdit} onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            disabled={!isEdit}
            type="primary"
            form="words-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="words-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
        className="form-style"
      >
        <Row gutter={16}>
          <Col span={22}></Col>
          <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
            <a onClick={() => setEdit(true)}>
              <img src={Edit} alt="Edit" />
            </a>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("word")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
