import React from "react";
import { Form, Card, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../utils/toastNotification";
import { useHistory } from "react-router";
import Api from "../../api";

import Logo from "../../public/images/login/login_logo.svg";
import "../../public/sass/components/_change-password.scss";

export const ChangePassword = ({ ...props }) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const onFinish = (formValues) => {
    Api("auth/change-password", "POST", formValues)
      .then((response) => {
        toastNotification(
          "success",
          `${t("password_successfully_changed")}! ${t(
            "login_with_new_password"
          )}!`
        );
        history.push("/login");
        localStorage.clear();
      })
      .catch((err) =>
        toastNotification(
          "error",
          err.message || `${t("error_change_password")}`
        )
      );
  };

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return (
    <div className="container">
      <div className="content">
        <Card className="card" bordered={false}>
          <div className="logo">
            <img src={Logo} alt="Logo" className="logo-style" />
          </div>
          <Form
            name="inline"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: `${t("input_password")}`,
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t("old_password")} />
            </Form.Item>

            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message: `${t("input_password")}`,
                },
                () => ({
                  validator(_, value) {
                    if (!strongRegex.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Min. 8 Zeichen groß, klein, Zahl & Sonderzeichen (!@#$%^&*)"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t("new_password")} />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t("input_confirm_password")}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        t('error_change_password_repeat')
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("confirm_password")} />
            </Form.Item>

            <Form.Item>
              <Button
                className="submit-button"
                type="primary"
                key="submit"
                htmlType="submit"
              >
                {t("change_password")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
