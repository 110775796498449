import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";
import Edit from "../../../public/images/dashboard/tables/edit.svg";

import "../../../public/sass/components/drawer/_createDrawers.scss";

const { Option } = Select;
const { TextArea } = Input;

export const EditCategory = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllCategories,
  categories,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [isEdit, setEdit] = useState(false);
  const [category, setCategory] = useState();

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      setCategory(response.type);
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    if (formValues.type.includes("Category")) {
      formValues.sub_category_id = null;
    }
    Api("categories/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", `${t("category_edited_successfully")}`);
        handleGetAllCategories();
        setEdit(false);
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("error_edit_category")}`);
      });
    // onClose();
  };

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("edit_category")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      destroyOnClose
      footer={
        <div className="footer-style">
          <Button disabled={!isEdit} onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            disabled={!isEdit}
            type="primary"
            form="company-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="company-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={22}></Col>
          <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
            <a onClick={() => setEdit(true)}>
              <img src={Edit} alt="Edit" />
            </a>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="category_id"
              label={t("category_number")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t("category_name")}
              rules={[{ required: true, transform: (value) => value.trim() }]}
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="type"
              label={t("category_type")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                disabled={!isEdit}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
                onChange={(e) => {
                  setCategory(e);
                }}
              >
                <Option value="Category">{t("category")}</Option>
                <Option value="Subcategory">{t("subcategory")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label={t("category_description")}
              rules={[
                {
                  required: true,
                  transform: (value) => value.trim(),
                },
              ]}
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="sub_category_id"
              label={t("subcategory_from")}
              rules={[
                {
                  required: category === "Subcategory"
                },
              ]}
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                disabled={!isEdit || category != "Subcategory"}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                {categories?.map((item, idx) => (
                  <Option key={idx} value={item?.id}>
                    {category == "Subcategory" && item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                disabled={!isEdit}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value={1}>{t("active")}</Option>
                <Option value={0}>{t("not_active")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
