import React from "react";
import { Empty, Button } from 'antd';

export const EmptyDataCreate = ({ btnTitle, message, handleOnClick }) => {
  return (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 60,
      }}
      description={
        <span>
          {message} 
        </span>
      }
    >
      <Button type="primary" onClick={handleOnClick}>{btnTitle || "Create Now"}</Button>
    </Empty>
  );
};
