import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { styles } from "./style";
import Logo from "../../../../public/images/pdf/auclogo.png";
import { PdfHeader } from "../../Clients/pdf/pdf_fortuhrung/PdfHeader";

export const SecondPage = ({ data }) => {
  if (!data) return null;
  return (
    <Page size="A4" style={{ backgroundColor: "#fff",  fontFamily: "Poppins", }}>
        <View style={{ padding: "30px" }}>
        <PdfHeader imgUrl={Logo} />
      </View>
      <View style={styles.firstView}>
        <Text style={styles.title}>INHALTSVERZEICHNIS</Text>
        <View style={styles.subtitles}>
          <Text style={styles.subtitle}>
            1. Aufstellung der bewerteten Objekte
          </Text>
          <Text style={styles.subtitle}>2. Bemerkung zur Desktopbewertung</Text>
          <Text style={styles.subtitle}>3. Unterlagen</Text>
          <Text style={styles.subtitle}>4. Fotodokumentation</Text>
        </View>
      </View>
    </Page>
  );
};
