import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './public/locales/en';
import translationDE from './public/locales/de';

const defaultLanguage = localStorage.getItem('default-language');

// the translations
const resources = {
  de: { translation: translationDE },
  en: { translation: translationEN },
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: !defaultLanguage ? "de" : defaultLanguage,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        wait: true,
    },
  });
  export default i18n;