import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Upload,
  message,
  Select,
  Collapse,
  Modal,
  AutoComplete,
  Checkbox,
} from "antd";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import Api from "../../../api";

import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";

import "../../../public/sass/components/drawer/positions/_positions.scss";
import "../../../public/sass/components/drawer/_createDrawers.scss";

import ProductsModal from "../../Modals/ProductsModal";

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

export const CreatePositions = ({
  id,
  products,
  isVisible,
  onClose,
  handleGetAllProducts,
  companies,
  third_parties,
  categories,
  getCompanyById,
  request_id,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [checkValueSelect, setValueSelect] = useState("Freie Masse");
  const [checkData, setCheckedData] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [is_breakdown_value, setBreakDown] = useState(false);
  const [is_going_concern_value, setGoingConcern] = useState(false);
  const [is_market_value, setMarkDown] = useState(false);
  const company_id = localStorage.getItem("company_id");
  const [words, setWords] = useState([]);
  const [checkoboxValue, setCheckoboxValue] = useState(false);

  useEffect(() => {
    handleGetAllWords();
  }, []);

  const changedCheckbox = (e) => {
    setCheckoboxValue(e.target.checked);
  };

  const aussonderung = [
    { key: "Fremdeigentum", value: "Fremdeigentum" },
    {
      key: "Einfacher Eigentumsvorbehalt",
      value: "Einfacher Eigentumsvorbehalt",
    },
    { key: "Leasing", value: "Leasing" },
    { key: "Miete", value: "Miete" },
    { key: "Mietkauf", value: "Mietkauf" },
    {
      key: "Sonstiges (mit Möglichkeit händischer Eingabe)",
      value: "Sonstiges (mit Möglichkeit händischer Eingabe)",
    },
  ];

  const absonderung = [
    {
      key: "Finanzierung/Darlehenssicherung",
      value: "Finanzierung/Darlehenssicherung",
    },
    { key: "Sicherungsübereignung", value: "Sicherungsübereignung" },
    { key: "Raumsicherungsübereignung", value: "Raumsicherungsübereignung" },
    { key: "Mietkauf", value: "Mietkauf" },
    { key: "Vermieterpfandrecht", value: "Vermieterpfandrecht" },
    {
      key: "erweiterter/verlängerter Eigentumsvorbehalt",
      value: "erweiterter/verlängerter Eigentumsvorbehalt",
    },
    {
      key: "Sonstiges (mit Möglichkeit händischer Eingabe)",
      value: "Sonstiges (mit Möglichkeit händischer Eingabe)",
    },
  ];

  const handleSubmit = (formValues) => {
    formValues.request_id = request_id;
    formValues.company_id = JSON.parse(company_id);

    let data = formValues;
    let list_images = [];
    if (data?.images?.fileList?.length) {
      data?.images?.fileList.forEach((item) => {
        if (item) {
          list_images.push(item?.originFileObj);
        }
      });
    }

    if (data?.files?.fileList?.length) {
      data?.files?.fileList.forEach((item) => {
        if (item) {
          list_images.push(item?.originFileObj);
        }
      });
    }

    delete data.images;

    const formData = new FormData();

    Object.entries(formValues).forEach(([key, value = ""]) => {
      if (key !== "files") {
        formData.append(key, value);
      }
    });

    if (list_images.length) {
      list_images.forEach((file) => {
        formData.append("files", file);
      });
    }

    Api("products", "POST", formData)
      .then(() => {
        toastNotification("success", `${t("product_created_successfully")}`);
        handleGetAllProducts();
        setValueSelect("Freie Masse");
        form.resetFields();
        onClose();
      })
      .catch((err) => {
        toastNotification(
          "error",
          err.error || `${t("error_create_products")}`
        );
      });
  };


  const onReset = () => {
    setValueSelect("Freie Masse");
    onClose();
    form.resetFields();
  };

  const handleCalculations = (_, values) => {
    const breakdown_value = values?.breakdown_value || 0;
    const going_concern_value = values?.going_concern_value || 0;
    const market_value = values?.market_value || 0;

    const bv_quantity = values?.bv_quantity || 0;
    const gcv_quantity = values?.gcv_quantity || 0;
    const mv_quantity = values?.mv_quantity || 0;

    const bv_tax_type = values?.bv_tax_type || 19;
    const gcv_tax_type = values?.gcv_tax_type || 19;
    const mv_tax_type = values?.mv_tax_type || 19;

    const calculatePercentMinus = Math.round(
      (bv_tax_type / 100) * breakdown_value * bv_quantity
    );

    const calculatePercentPlus = Math.round(
      breakdown_value * bv_quantity + calculatePercentMinus
    );

    const calculatePercentMinusGC = Math.round(
      (gcv_tax_type / 100) * going_concern_value * gcv_quantity
    );

    const calculatePercentPlusGC = Math.round(
      going_concern_value * gcv_quantity + calculatePercentMinusGC
    );

    const calculatePercentMinusMV = Math.round(
      (mv_tax_type / 100) * market_value * mv_quantity
    );

    const calculatePercentPlusMV = Math.round(
      market_value * mv_quantity + calculatePercentMinusMV
    );

    form.setFieldsValue({
      ...values,
      bv_tax_type: values?.bv_tax_type,
      gcv_tax_type: values?.gcv_tax_type,
      mv_tax_type: values?.mv_tax_type,
      bv_subtotal: breakdown_value * bv_quantity,
      gcv_subtotal: going_concern_value * gcv_quantity,
      mv_subtotal: market_value * mv_quantity,
      bv_tax_costs: calculatePercentMinus,
      gcv_tax_costs: calculatePercentMinusGC,
      mv_tax_costs: calculatePercentMinusMV,
      bv_tax_total: calculatePercentPlus,
      gcv_tax_total: calculatePercentPlusGC,
      mv_tax_total: calculatePercentPlusMV,
    });
  };

  const onPressSearchItem = (item, type) => {
    if (type === "breakdown_value") {
      form.setFieldsValue({
        breakdown_value: item?.breakdown_value,
        bv_quantity: item?.bv_quantity || 1,
        bv_subtotal: item?.bv_subtotal || 1,
        bv_tax_type: item?.bv_tax_type || 19,
        bv_tax_costs: item?.bv_tax_costs,
        bv_tax_total: item?.bv_tax_total,
      });

      setBreakDown(!is_breakdown_value);
    } else if (type === "going_concern_value") {
      form.setFieldsValue({
        going_concern_value: item?.going_concern_value,
        gcv_quantity: item?.gcv_quantity || 1,
        gcv_subtotal: item?.gcv_subtotal || 1,
        gcv_tax_type: item?.gcv_tax_type || 19,
        gcv_tax_costs: item?.gcv_tax_costs,
        gcv_tax_total: item?.gcv_tax_total,
      });
      setGoingConcern(!is_going_concern_value);
    } else if (type === "market_value") {
      form.setFieldsValue({
        market_value: item?.market_value,
        mv_quantity: item?.mv_quantity || 1,
        mv_subtotal: item?.mv_subtotal || 1,
        mv_tax_type: item?.mv_tax_type || 19,
        mv_tax_costs: item?.mv_tax_costs,
        mv_tax_total: item?.mv_tax_total,
      });
      setMarkDown(!is_market_value);
    } else {
      message.info("Something went wrong, please try again");
    }

    message.info("Gerade hinzugefügt " + item?.name);
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  const handleGetAllWords = () => {
    Api(`words`, "GET", null)
      .then((data) => {
        setWords(data?.results);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Drawer
      width={720}
      onClose={onReset}
      visible={isVisible}
      title={t("create_new_position")}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="positions-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="positions-form"
        layout="vertical"
        scrollToFirstError
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        initialValues={{
          bv_quantity: 1,
          gcv_quantity: 1,
          mv_quantity: 1,
        }}
        onValuesChange={handleCalculations}
        className="form-style"
        initialValues={{
          bv_tax_type: "19",
          mv_tax_type: "19",
          gcv_tax_type: "19",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t("product_name")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <AutoComplete
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) === 0
                }
              >
                {words.map((item) => (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="inventar_id"
              label={t("inventory_number")}
              rules={[{ required: false }]}
              labelAlign="left"
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="short_description"
              label={t("short_description")}
              rules={[
                {
                  required: false,
                },
              ]}
              labelAlign="left"
            >
              <TextArea
                rows={3}
                placeholder=""
                showCount
                maxLength={250}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="long_description"
              label={t("description")}
              rules={[{ required: false }]}
              labelAlign="left"
            >
              <TextArea rows={3} placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="category_id"
              label={t("category")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Select
                placeholder={t("")}
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                {categories?.map((item, idx) => (
                  <Option key={idx} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="packaging_unit"
              label={t("packaging_unit")}
              rules={[{ required: false }]}
              labelAlign="left"
            >
              <Select
                placeholder="Stück"
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value="Piece">{t("piece")}</Option>
                <Option value="Litre">{t("litre")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="packaging_unit_qty"
              label={t("qty_packaging_unit")}
              rules={[{ required: false }]}
              labelAlign="left"
            >
              <Input placeholder="" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="mileage"
              label={t("mileage")}
              // rules={[{ required: true }]}
              labelAlign="left"
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mileage_type"
              label={t("type_of_mileage")}
              // rules={[{ required: true }]}
              labelAlign="left"
            >
              <Select
                placeholder="km"
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value="km">km</Option>
                <Option value="h">h</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="assessment_type"
              label={t("assessment_type")}
              rules={[{ required: false }]}
              labelAlign="left"
            >
              <Select
                placeholder={t("assessment")}
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value="assessment">{t("assessment")}</Option>
                <Option value="desktop_assessment">
                  {t("desktop_assessment")}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Collapse bordered={false} className="collapse-style">
          <Panel
            header={
              <>
                <div>
                  <Row
                    gutter={16}
                    className="row-style-collapse"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Col span={10}>
                      <div className="panel__row__style">
                        <p className="header-text">{t("breakdown_value")}</p>
                        <Button
                          onClick={() => setBreakDown(!is_breakdown_value)}
                          className="panel__row__style--icon"
                          shape="square"
                          icon={<SearchOutlined />}
                        />
                      </div>
                    </Col>

                    <Col span={14}>
                      <Form.Item
                        name="breakdown_value"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input placeholder="" type="number" />
                      </Form.Item>
                      <Modal
                        visible={is_breakdown_value}
                        onOk={() => setBreakDown(!is_breakdown_value)}
                        onCancel={() => setBreakDown(!is_breakdown_value)}
                      >
                        <ProductsModal
                          onPressSearchItem={onPressSearchItem}
                          type="breakdown_value"
                        />
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </>
            }
            key="1"
            className="panel-style"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="bv_quantity"
                  label={t("quantity")}
                  rules={[{ required: false }]}
                  labelAlign="left"
                >
                  <Input placeholder="" min={0} type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="bv_subtotal"
                  label={t("subtotal")}
                  labelAlign="left"
                >
                  <Input placeholder="" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="bv_tax_type"
                  label={t("tax_type")}
                  rules={[{ required: true }]}
                  labelAlign="left"
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="19">19%</Option>
                    <Option value="7">7%</Option>
                    <Option value="0">0%</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="bv_tax_costs"
                  label={t("tax_costs")}
                  labelAlign="left"
                >
                  <Input placeholder="" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="bv_tax_total"
                  label={t("total_incl_tax")}
                  labelAlign="left"
                >
                  <Input placeholder="" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel
            header={
              <>
                <div>
                  <Row
                    gutter={16}
                    className="row-style-collapse"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Col span={10}>
                      <div className="panel__row__style">
                        <p className="header-text">
                          {t("going_concern_value")}
                        </p>
                        <Button
                          onClick={() =>
                            setGoingConcern(!is_going_concern_value)
                          }
                          className="panel__row__style--icon"
                          shape="square"
                          icon={<SearchOutlined />}
                        />
                      </div>
                    </Col>

                    <Col span={14}>
                      <Form.Item
                        name="going_concern_value"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input placeholder="" type="number" />
                      </Form.Item>
                      <Modal
                        visible={is_going_concern_value}
                        onOk={() => setGoingConcern(!is_going_concern_value)}
                        onCancel={() =>
                          setGoingConcern(!is_going_concern_value)
                        }
                      >
                        <ProductsModal
                          onPressSearchItem={onPressSearchItem}
                          type="going_concern_value"
                        />
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </>
            }
            key="2"
            className="panel-style"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="gcv_quantity"
                  label={t("quantity")}
                  labelAlign="left"
                >
                  <Input placeholder="" min={0} type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gcv_subtotal"
                  label={t("subtotal")}
                  labelAlign="left"
                >
                  <Input placeholder="" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="gcv_tax_type"
                  label={t("tax_type")}
                  labelAlign="left"
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="19">19%</Option>
                    <Option value="7">7%</Option>
                    <Option value="0">0%</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="gcv_tax_costs"
                  label={t("tax_costs")}
                  labelAlign="left"
                >
                  <Input placeholder="" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gcv_tax_total"
                  label={t("total_incl_tax")}
                  labelAlign="left"
                >
                  <Input placeholder="" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Select
                placeholder={t("choose_user_status")}
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value={0}>{t("accepted")}</Option>
                <Option value={1}>{t("not_accepted")}</Option>
                <Option value={2}>{t("in_progress")}</Option>
                <Option value={3}>{t("done")}</Option>
                <Option value={4}>{t("not_active")}</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              name="comment"
              label={t("comment")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Select
                onChange={(e) => setCheckedData(e)}
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value="Keine Bemerkung">{t("no_comment")}</Option>
                <Option value="Entsorgung">{t("disposal_e")}</Option>
                <Option value="Festeinbau">{t("fixed_installation")}</Option>
                <Option value="Immobilienangepasst">
                  {t("real_estate_adapted")}
                </Option>
                <Option value="Ohne Bewertung">{t("without_rating")}</Option>
                <Option value="Nicht Besichtigt">{t("not_visited")}</Option>
                <Option value="Zubehör zu Position „XYZ“">
                  Zubehör zu Position „XYZ“
                </Option>
                <Option value="Bewertung unter Position „XYZ“">
                  Bewertung unter Position „XYZ“
                </Option>
                <Option value="Sonstiges (mit Möglichkeit händischer Eingabe)">
                  Sonstiges (mit Möglichkeit händischer Eingabe)
                </Option>
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
        {/* {checkData == "Zubehör zu Position „XYZ“" && (
          <>
            <Form.Item
              name="product_parent_id"
              label={t("position")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Select
                placeholder=""
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                {products?.map((product, idx) => (
                  <Option key={idx} value={product?.id}>
                    {product?.name || "nothing"}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        {checkData == "Sonstiges (mit Möglichkeit händischer Eingabe)" && (
          <Form.Item
            name="other"
            label={t("other")}
            rules={[{ required: true }]}
            labelAlign="left"
          >
            <TextArea showCount maxLength={200} onChange={onChange} />
          </Form.Item>
        )}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="asset_type"
              label={t("asset_type")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
              >
                <Option value={12}>12 Grundstücke und Bauten</Option>
                <Option value={15}>15 Maschinen- u. techn. Anlagen </Option>
                <Option value={16}>
                  16 Betriebs- und Geschäftsausstattung{" "}
                </Option>
                <Option value={39}>39 Fuhrpark </Option>
                <Option value={23}>23 Roh-, Hilf- u. Betriebsstoffe</Option>
                <Option value={24}>24 Unfertige/Halbfertige Waren </Option>
                <Option value={25}>25 Fertige Erzeugnisse u. Waren</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row> */}

        {/* <div className="rights-section">
          <Row>
            <Col span={24}>
              <Form.Item
                name="rights"
                label={t("rights")}
                rules={[{ required: isRequired }]}
                labelAlign="left"
              >
                <Select
                  onChange={(e) => setValueSelect(e)}
                  showSearch={true}
                  searchBy="label"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) === 0
                  }
                >
                  <Option value="Freie Masse">Freie Masse</Option>
                  <Option value="Aussonderung">{t("disposal")}</Option>
                  <Option value="Absonderung">{t("segregation")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {checkValueSelect !== "Freie Masse" && (
            <>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name={
                      checkValueSelect == "Aussonderung"
                        ? "disposal_rights"
                        : "segregation_rights"
                    }
                    label={
                      checkValueSelect == "Aussonderung"
                        ? t("disposal_rights")
                        : t("segregation_rights")
                    }
                    rules={[
                      {
                        required:
                          checkValueSelect !== "Freie Masse" ? true : false,
                      },
                    ]}
                    labelAlign="left"
                  >
                    <Select
                      showSearch={true}
                      searchBy="label"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) === 0
                      }
                    >
                      {checkValueSelect == "Aussonderung"
                        ? aussonderung.map((item, idx) => (
                            <Option key={idx} value={item?.key}>
                              {item?.value}
                            </Option>
                          ))
                        : absonderung.map((item, idx) => (
                            <Option key={idx} value={item?.key}>
                              {item?.value}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="third_party_id"
                    label={t("third_party")}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    labelAlign="left"
                  >
                    <Select
                      placeholder={t("")}
                      showSearch={true}
                      searchBy="label"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) === 0
                      }
                    >
                      {third_parties?.map((item, idx) => (
                        <Option key={idx} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    name="files"
                    label={t("Upload files")}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    labelAlign="left"
                  >
                    <Upload
                      customRequest={({ file, onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok");
                        }, 0);
                      }}
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div> */}
        {/* <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="product_address"
              label={t("address_from_product")}
              rules={[
                {
                  // required: true,
                },
              ]}
              labelAlign="left"
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) === 0
                }
              >
                {addresses?.map((item, idx) => (
                  <Option key={idx} value={item?.id}>
                    {item?.street}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="product_location"
              label={t("location_from_product")}
              rules={[
                {
                  required: false,
                },
              ]}
              labelAlign="left"
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="images" label={t("image")}>
              <Upload
                className="upload-style"
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok");
                  }, 0);
                }}
                maxCount={10}
              >
                <Button icon={<UploadOutlined />}>
                  {t("click_to_upload")}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
