import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { PdfHeader } from "./PdfHeader";

export const SecondPage = ({ data }) => {
  const getTotalFreie = data?.products.filter((i) => i.rights === "Freie Masse");
  const getTotalAussonderung = data?.products.filter((i) => i.rights === "Aussonderung");
  const getTotalAbsonderung = data?.products.filter((i) => i.rights === "Absonderung");
  const getTotalAbsonderungSich = data?.products.filter((i) => i.rights === "Absonderung" && i.segregation_rights === "Sicherungsübereignung");
  const getTotalAbsonderungRaum = data?.products.filter((i) => i.rights === "Absonderung" && i.segregation_rights === "Raumsicherungsübereignung");
  const getTotalAussonderungDisposal = data?.products.filter((i) => i.rights === "Aussonderung" && i.disposal_rights === "Miete");

  const calculateAbsonderung = () => {
    let totalAbs = 0;
    if (getTotalAbsonderung.length) {
      getTotalAbsonderung.forEach((i) => {
        totalAbs += parseInt(i.breakdown_value);
      });
      return totalAbs;
    }

    return 0;
  };

  const calculateAbsonderungRaum = () => {
    let totalAbsRaum = 0;
    if (getTotalAbsonderungRaum.length) {
      getTotalAbsonderungRaum.forEach((i) => {
        totalAbsRaum += parseInt(i.breakdown_value);
      });
      return totalAbsRaum;
    }

    return 0;
  };

  const calculateAbsonderungSegragation = () => {
    let totalAbsSeg = 0;
    if (getTotalAbsonderungSich.length) {
      getTotalAbsonderungSich.forEach((i) => {
        totalAbsSeg += parseInt(i.breakdown_value);
      });
      return totalAbsSeg;
    }

    return 0;
  };

  const calculateAussonderungDisposal = () => {
    let totalAusDisposal = 0;
    if (getTotalAussonderungDisposal.length) {
      getTotalAussonderungDisposal.forEach((i) => {
        totalAusDisposal += parseInt(i.breakdown_value);
      });
      return totalAusDisposal;
    }

    return 0;
  };

  const calculateAussonderung = () => {
    let totalAus = 0;
    if (getTotalAussonderung.length) {
      getTotalAussonderung.forEach((i) => {
        totalAus += parseInt(i.breakdown_value);
      });
      return totalAus;
    }

    return 0;
  };

  const calculateFreieMasseTotal = () => {
    let totalFreie = 0;
    if (getTotalFreie.length) {
      getTotalFreie.forEach((i) => {
        totalFreie += parseInt(i.breakdown_value);
      });
      return totalFreie;
    }

    return 0;
  };

  const totalEnstro = (value) => {
    let isNumber = /^\d*(\.\d+)?$/;
    if(isNumber.test(value)) return parseInt(value);
    return parseInt("0");
  }

  return (
    <Page
      size="A4"
      style={{
        backgroundColor: "#fff",
        padding: "40px",
        fontFamily: "Poppins",
      }}
    >
      <PdfHeader imgUrl="https://i.imgur.com/BUQFkU3.jpg" />
      <View
        style={{
          margin: "10px 0",
          // backgroundColor: "#404040",
          color: "#364059",
          padding: "15px 0px",
          fontSize: "14px",
          letterSpacing: "1px",
          marginTop: "20px",
        }}
      >
        <Text style={{ color: '#364059' }}>WERTERMITTLUNG - ZERSCHLAGUNGSWERT</Text>
      </View>
      <Text
        style={{
          color: "#364059",
          fontWeight: 700,
          paddingBottom: "5px",
          fontSize: "20px",
        }}
      >
        Präambel
      </Text>
      <Text style={{ fontSize: "12px", lineHeight: 1.5, marginRight: "25px", color: '#8F8F8F' }}>
        Gemäß Auftrag von {`${data?.client?.name}, ${data?.client?.street} ${data?.client?.house_number}, ${data?.client?.zip} ${data?.client?.city}, ${data?.client?.country}`}
        wurde eine Schätzung des mobilen Anlagevermögens des
      </Text>
      <View
        style={{
          marginTop: "20px",
          textAlign: "center",
          alignItems: "center",
          marginBottom: "25px",
          color: '#364059',
        }}
      >
        <Text
          style={{
            lineHeight: 1.3,
            fontSize: "14px",
            fontWeight: 500,
            color: '#364059'
          }}
        >
          {`${data?.name}`}
        </Text>
        <Text  style={{
            lineHeight: 1.3,
            fontSize: "14px",
            fontWeight: 500,
          }}>{`${data?.addresses[0].street} ${data?.addresses[0].house_number}`}</Text>
        <Text  style={{
            lineHeight: 1.3,
            fontSize: "14px",
            fontWeight: 500,
          }}>{`${data?.addresses[0].zip} ${data?.addresses[0].city} `}</Text>
        <Text  style={{
            lineHeight: 1.3,
            fontSize: "14px",
            fontWeight: 500,
          }}>{`${data?.addresses[0].country}`}</Text>
      </View>

      <Text style={{ fontSize: "12px" }}>
        Der Bewertung liegt eine Sachaufnahme vom{" "}
        {moment(data?.date_one || new Date()).format("DD.MM.YYYY")} zugrunde.
      </Text>
      <View style={{ margin: "15px 0" }}>
        <Text
          style={{
            color: "#364059",
            fontWeight: 700,
            paddingBottom: "10px",
            fontSize: "20px",
          }}
        >
          Wertermittlung:
        </Text>
        <Text
          style={{ fontSize: "12px", lineHeight: 1.5, marginRight: "25px", color: '#8F8F8F' }}
        >
          Den Wert der veräußerbaren Betriebs- und Geschäftsausstattung schätzen
          wir unter folgenden Zerschlagungsgesichtpunkten auf
        </Text>
      </View>

      <View style={{ lineHeight: 1.7, fontSize: "12px", fontWeight: 500 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingBottom: "5px",
            fontWeight: 600,
          }}
        >
          <Text style={{ fontSize: "14px", color: "#364059" }}>Zerschlagungswert</Text>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Freie Masse:</Text>
          <Text>{calculateFreieMasseTotal().toFixed(2).replace('.', ',')} €</Text>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Fremdeigentum:</Text>
          <Text>{calculateAussonderung().toFixed(2).replace('.', ',')} €</Text>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Miete:</Text>
          <Text>{calculateAussonderungDisposal().toFixed(2).replace('.', ',')} €</Text>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Sicherungsübereignung:</Text>
          <Text>{calculateAbsonderungSegragation().toFixed(2).replace('.', ',')} €</Text>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>Raumsicherungsübereignung:</Text>
          <Text>{calculateAbsonderungRaum().toFixed(2).replace('.', ',')} €</Text>
        </View>
      </View>

      <View style={{ marginTop: "10px", fontSize: "12px", fontWeight: 500 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <View style={{ fontSize: "16px", lineHeight: 1.5 }}>
            <Text style={{ fontWeight: 700, fontSize: "14px", color: "#364059" }}>
              Gesamt-Zerschlagungswert
            </Text>
            <Text style={{ fontSize: "12px", color: "#8F8F8F" }}>
              zzgl. gesetzlicher Mehrwertsteuer
            </Text>
          </View>
          <View>
            <Text style={{ fontWeight: 600, fontSize: "16px" }}>
              {(
                calculateFreieMasseTotal() +
                calculateAussonderung() +
                calculateAussonderungDisposal() +
                calculateAbsonderungSegragation() +
                calculateAbsonderungRaum()
              ).toFixed(2).replace('.', ',')} €
            </Text>
          </View>
        </View>

        <View style={{ lineHeight: 2.5, marginTop: "10px", fontSize: "12px" }}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={{ fontWeight: 600, color: "#364059" }}>
              darin enthalten verm. Absonderungsrechte:
            </Text>
            <Text>{calculateAbsonderung().toFixed(2).replace('.', ',')} €</Text>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={{ fontWeight: 600, color: "#364059" }}>
              darin enthalten verm. Aussonderungsrechte:
            </Text>
            <Text>{calculateAussonderung().toFixed(2).replace('.', ',')} €</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              lineHeight: 1.4,
            }}
          >
            <Text style={{ width: "60%" }}>
              Der Gutachter geht von Entsorgungskosten in Höhe von aus
            </Text>
            <Text>{totalEnstro(data?.disposal_costs).toFixed(2).replace('.', ',')} €</Text>
          </View>
        </View>
      </View>
      <Text
        style={{
          position: "absolute",
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: "center",
          color: "grey",
        }}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
};
