import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Tabs,
  Modal,
  InputNumber,
  Collapse,
  DatePicker,
  Checkbox,
  Popover,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import Api from "../../../api";
import { saveAs } from "file-saver";
import moment from "moment";
import { CSVLink } from "react-csv";

import "../../../public/sass/components/drawer/_tabs.scss";
import "../../../public/sass/components/drawer/_createDrawers.scss";
import Edit from "../../../public/images/dashboard/tables/edit.svg";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";

import { PositionsContainer } from "./PositionsContainer";
import { Contracts } from "./Contracts";
import { AddressContainer } from "../../../containers/Dashboard/Addresses/AddressContainer";
import { SentEmailModal } from "./SentEmailModal";
import { PdfDocument } from "./pdf/pdf_zerschlagung";
import { PdfDocument_fortunhrung } from "./pdf/pdf_fortuhrung";
import { PdfDocument_sachaufnahme_zersch } from "./pdf/pdf_sachaufnahme_zersch";
import { PdfDocument_sachaufnahme_fortuhrung } from "./pdf/pdf_sachaufnahme_fortuhrung";
import { PdfDocument_sachaufnahme_zersch_fortuhrung } from "./pdf/pdf_sachaufnahme_zersch_fortuhrung";
import loadingPdfGif from "../../../public/images/pdf/lp.gif";

import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Panel } = Collapse;

export const EditClient = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllClients,
  getCompanyById,
  companies,
  lockEditById,
  lock,
}) => {
  const CheckboxGroup = Checkbox.Group;

  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [isGeneratePDF, generatePDF] = useState(false);
  const [doc_type, setDocType] = useState(0);
  const [multipleDoc, setMultipleDoc] = useState([]);
  const [loadingPDF, setLoadingPdf] = useState(false);
  const dateFormat = "DD.MM.YYYY";
  const [isSentEmail, setSentEmail] = useState(false);
  const [emailPdfOne, setEmailPdfOne] = useState("");
  const [emailPdfTwo, setEmailPdfTwo] = useState("");
  const [emailPdfThree, setEmailPdfThree] = useState("");
  const [emailPdfFour, setEmailPdfFour] = useState("");
  const [emailPdfFive, setEmailPdfFive] = useState("");
  const [statusCheck, setStatus] = useState(false);
  const [third_parties, setThirdPartiesCompanies] = useState([]);

  const csvHeader = [
    { label: "Bezeichnung", key: "name" },
    { label: "Inventar-Nr", key: "inventar_id" },
    { label: "Bemint", key: "" },
    { label: "Menge", key: "packaging_unit_qty" },
    { label: "Einheit", key: "packaging_unit" },
    { label: "BemExt", key: "" },
    { label: "Schätzwert Liq Netto", key: "breakdown_value" },
    { label: "Schätzwert Fort Netto", key: "going_concern_value" },
    { label: "Ust-Satz Schätzwert Liq Netto", key: "bv_tax_type_list" },
    { label: "Ust-Satz Schätzwert Fort", key: "gcv_tax_type" },
    { label: "Verzeichnisposition", key: "asset_type" },
  ];

  useEffect(() => {
    lockEditById();
  }, [isVisible]);

  const handleOnClickTab = (key) => {
    if(key == 3){
      handleThirdPartiesCompanies();
    }
  };

  const disableSelect = (products) => {
    setStatus(false);
    products?.products.map(item => {
      if(item.status !== 0) {
        setStatus(true);
      }
    })
  } 


  useEffect(() => {
    if (isVisible) {
      disableSelect(data)

      let response = { ...data };
      response.date_one = moment(response?.date_one || new Date());
      response.date_two = moment(response?.date_two || new Date());
      return form.setFieldsValue(response);
    }

    return () => {
      generatePDF(false);
      setLoadingPdf(false);
      setMultipleDoc([]);
      setDocType(0);
    };
  }, [isVisible]);

  const handleThirdPartiesCompanies = () => {
    Api("third-party/contracts/" + id, "GET", null)
      .then((data) => {
        setThirdPartiesCompanies(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = (formValues) => {
    Api("actions/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", `${t("successfully_edited_client")}`);
        handleGetAllClients();
        setEdit(false);
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("error_edit_client")}`);
      });
    // onClose();
  };

  const getAllCustomers = () => {
    setLoadingCustomers(true);
    Api("clients", "GET", null)
      .then((data) => {
        setAllCustomers(data.results);
        setLoadingCustomers(false);
      })
      .catch(() => setLoadingCustomers(false));
  };

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const modalLoading = () => {
    return Modal.success({
      content: "some messages...some messages...",
    });
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  const handleDownloadDocuments = async (sent_email) => {
    if (!multipleDoc.length)
      return alert("Bitte wählen Sie eines der Dokumente");
    if (!sent_email) {
      setLoadingPdf(true);
    }
    const docOne = multipleDoc.includes(0);
    const docTwo = multipleDoc.includes(1);
    const docThree = multipleDoc.includes(2);
    const docFour = multipleDoc.includes(3);
    const docFive = multipleDoc.includes(4);

    if (docOne) {
      const one = <PdfDocument data={data} />;
      const asPdf_one = pdf([]);
      asPdf_one.updateContainer(one);
      const blob_one = await asPdf_one.toBlob();

      if (sent_email) {
        setEmailPdfOne(blob_one);
      }

      if (!sent_email) {
        saveAs(
          blob_one,
          `${"Gutachten (Zerschlagungswert)"}-${moment().format(
            "DD.MM.YYYY"
          )}.pdf`
        );
      }
    }

    if (docTwo) {
      const two = <PdfDocument_fortunhrung data={data} />;
      const asPdf_two = pdf([]);
      asPdf_two.updateContainer(two);
      const blob_two = await asPdf_two.toBlob();

      if (sent_email) {
        setEmailPdfTwo(blob_two);
      }

      if (!sent_email) {
        saveAs(
          blob_two,
          `${"Gutachten (Fortführungswert)"}-${moment().format(
            "DD.MM.YYYY"
          )}.pdf`
        );
      }
    }

    if (docThree) {
      const three = <PdfDocument_sachaufnahme_zersch data={data} />;
      const asPdf_three = pdf([]);
      asPdf_three.updateContainer(three);
      const blob_three = await asPdf_three.toBlob();

      if (sent_email) {
        setEmailPdfThree(blob_three);
      }

      if (!sent_email) {
        saveAs(
          blob_three,
          `${"Sachaufnahme (Zerschlagungswert)"}-${moment().format(
            "DD.MM.YYYY"
          )}.pdf`
        );
      }
    }

    if (docFour) {
      const four = <PdfDocument_sachaufnahme_fortuhrung data={data} />;
      const asPdf_four = pdf([]);
      asPdf_four.updateContainer(four);
      const blob_four = await asPdf_four.toBlob();

      if (sent_email) {
        setEmailPdfFour(blob_four);
      }

      if (!sent_email) {
        saveAs(
          blob_four,
          `${"Sachaufnahme (Fortführungswert)"}-${moment().format(
            "DD.MM.YYYY"
          )}.pdf`
        );
      }
    }

    if (docFive) {
      const five = <PdfDocument_sachaufnahme_zersch_fortuhrung data={data} />;
      const asPdf_five = pdf([]);
      asPdf_five.updateContainer(five);
      const blob_five = await asPdf_five.toBlob();

      if (sent_email) {
        setEmailPdfFive(blob_five);
      }

      if (!sent_email) {
        saveAs(
          blob_five,
          `${"Sachaufnahme (Zerschlagungs- & Fortführungswert)"}-${moment().format(
            "DD.MM.YYYY"
          )}.pdf`
        );
      }
    }

    setLoadingPdf(false);
  };

  const handleCloseEmailModal = () => {
    setEmailPdfOne("");
    setEmailPdfTwo("");
    setEmailPdfThree("");
    setEmailPdfFour("");
    setEmailPdfFive("");
    setSentEmail(!isSentEmail);
  };

  const typePDF = (data) => {
    switch (doc_type) {
      case 0:
        return <PdfDocument data={data} />;
      case 1:
        return <PdfDocument_fortunhrung data={data} />;
      case 2:
        return <PdfDocument_sachaufnahme_zersch data={data} />;
      case 3:
        return <PdfDocument_sachaufnahme_fortuhrung data={data} />;
      case 4:
        return <PdfDocument_sachaufnahme_zersch_fortuhrung data={data} />;
      default:
        return <PdfDocument />;
    }
  };

  const menu = (
    <>
      <div className="container-popover">
        <div className="headerPop">
          Dokumente herunterladen oder per Mail senden ({multipleDoc.length})
        </div>

        <CheckboxGroup
          className="container-checkbox-popup"
          onChange={(v) => setMultipleDoc(v)}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {loadingPDF && (
              <div style={{ paddingBottom: "15px" }}>
                {multipleDoc.map((i) => {
                  return (
                    <img
                      key={i}
                      src={loadingPdfGif}
                      style={{ width: "100px" }}
                    />
                  );
                })}
              </div>
            )}
            <Checkbox
              disabled={loadingPDF}
              style={{ margin: 0, marginBottom: "5px" }}
              value={0}
            >
              Gutachten (Zerschlagungswert)
            </Checkbox>
            <Checkbox
              disabled={loadingPDF}
              style={{ margin: 0, marginBottom: "5px" }}
              value={1}
            >
              Gutachten (Fortführungswert)
            </Checkbox>
            <Checkbox
              disabled={loadingPDF}
              style={{ margin: 0, marginBottom: "5px" }}
              value={2}
            >
              Sachaufnahme (Zerschlagungswert)
            </Checkbox>
            <Checkbox
              disabled={loadingPDF}
              style={{ margin: 0, marginBottom: "5px" }}
              value={3}
            >
              Sachaufnahme (Fortführungswert)
            </Checkbox>
            <Checkbox
              disabled={loadingPDF}
              style={{ margin: 0, marginBottom: "5px" }}
              value={4}
            >
              Sachaufnahme (Zerschlagungs- & Fortführungswert)
            </Checkbox>
          </div>
        </CheckboxGroup>

        <div className="bottom-popover">
          <Button
            type="primary"
            disabled={!multipleDoc.length ? true : false}
            onClick={() => {
              handleDownloadDocuments("sent_email"), setSentEmail(!isSentEmail);
            }}
            key="submit"
            style={{ marginRight: "25px" }}
          >
            {t("send_email")}
          </Button>
          <Button
            type="primary"
            disabled={loadingPDF}
            ghost
            onClick={() => handleDownloadDocuments()}
          >
            Runterladen
          </Button>
        </div>
      </div>
    </>
  );

  //Edit action
  return (
    <Drawer
      title={t("edit_client")}
      width={1300}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <Row gutter={16}>
          <Col span={1}>
            {" "}
            <Popover
              content={menu}
              trigger="click"
              className={!lock && "disabled"}
            >
              <a>
                <FilePdfOutlined
                  style={{
                    fontSize: "26px",
                    marginRight: "5px",
                    marginTop: "2px",
                  }}
                />
              </a>
            </Popover>
          </Col>
          <Col span={11}>
            <SentEmailModal
              t={t}
              handleCloseEmailModal={handleCloseEmailModal}
              isSentEmail={isSentEmail}
              setSentEmail={setSentEmail}
              email={data?.email}
              files={multipleDoc}
              emailPdfOne={emailPdfOne}
              emailPdfTwo={emailPdfTwo}
              emailPdfThree={emailPdfThree}
              emailPdfFour={emailPdfFour}
              emailPdfFive={emailPdfFive}
            />
            {(data?.products?.length && (
              <CSVLink
                filename={`${data?.name}-${moment().format(
                  "DD.MM.YYYY"
                )}${".csv"}`}
                data={data?.products}
                separator={";"}
                style={{ marginLeft: 10 }}
                headers={csvHeader}
              >
                <Button style={{ marginLeft: 3 }} disabled={!lock}>
                  CSV({data?.products?.length})
                </Button>
              </CSVLink>
            )) ||
              ""}
            {/* {isGeneratePDF && <SentEmailModal t={t} email={data?.email} />} */}
          </Col>
          <Col span={12} className="footer-style">
            <Button
              disabled={!isEdit}
              onClick={onClose}
              className="button-style"
            >
              {t("cancel")}
            </Button>
            <Button
              disabled={!isEdit}
              type="primary"
              form="company-form"
              key="submit"
              htmlType="submit"
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      }
    >
      <Tabs
        defaultActiveKey="1"
        onChange={handleOnClickTab}
        className="tab-style"
      >
        <TabPane tab={t("procedure")} key="1" className="tab-pane-style">
          <Form
            form={form}
            validateMessages={validateMessages}
            id="company-form"
            layout="vertical"
            scrollToFirstError
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={22}>
                {/* {!isGeneratePDF && (
                  <a onClick={() => generatePDF(!isGeneratePDF)}>
                    Generate PDF
                  </a>
                )} */}
                {/* <a onClick={async () => {
                    const doc = <PdfDocument data={data} />
                    const asPdf = pdf();

                    asPdf.updateContainer(doc)

                    let appendixBlob = await asPdf.toBlob();

                    let merger = new PDFMerger()
                    await merger.add(appendixBlob)
                    // await Promise.all([initialBlob, appendixBlob].map(async (file) => await merger.add(file, null)))

                    const mergedPdf = await merger.saveAsBlob()
                    saveAs(mergedPdf, `Appendix.pdf`)

                }}>Download document</a> */}
                {/* <BlobProvider document={<PdfDocument data={data} />}>
                  {({ url }) => (
                    <a href={url} target="_blank">
                      {t("open_new_tab")}
                    </a>
                  )}
                </BlobProvider> */}
              </Col>
              <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
                {/* <a onClick={() => generatePDF(!isGeneratePDF)}>
                  <FilePdfOutlined
                    style={{ fontSize: "26px", marginRight: "5px" }}
                  />
                </a> */}
                <a onClick={() => setEdit(true)}>
                  <img src={Edit} alt="Edit" />
                </a>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={t("name")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="action_id"
                  label={t("client_number")}
                  rules={[{ required: true }]}
                >
                  <Input type="number" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="type_of_procedure"
                  label={t("type_of_procedure")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="Regelverfahren">Regelverfahren</Option>
                    <Option value="Eigenverwaltung">Eigenverwaltung</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  onClick={getAllCustomers}
                  name="client_id"
                  label={t("customer")}
                  rules={[{ required: true }]}
                >
                  <Select
                    loading={loadingCustomers}
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                  >
                    {allCustomers?.map((customer, idx) => (
                      <Option key={idx} value={customer?.id}>
                        {customer?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="owner_firstname"
                  label={t("owner_firstname")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="owner_lastname"
                  label={t("owner_lastname")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label={t("street")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="house_number"
                  label={t("house_number")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="zip"
                  label={t("zip")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label={t("city_name")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label={t("country")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="Deutschland">{t("germany")}</Option>
                    <Option value="Österreich">{t("austria")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={t("phone")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label={t("email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="hrb_number"
                  label={t("hrb_number")}
                  rules={[{ required: false }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="ustid_number"
                  label={t("ustid_number")}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="tax_number"
                  label={t("tax_number")}
                  rules={[{ required: false }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="disposal_costs"
                  label={t("disposal_costs")}
                  rules={[{ required: false }]}
                >
                  <InputNumber
                    type="number"
                    disabled={!isEdit}
                    min={0}
                    controls={false}
                    style={{ width: "100%" }}
                  />
                  {/* <Input
                    placeholder={t("enter_disposal_costs")}
                    disabled={!isEdit}
                  /> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit || statusCheck}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value={0}>{t("accepted")}</Option>
                    <Option value={1}>{t("not_accepted")}</Option>
                    <Option value={2}>{t("in_progress")}</Option>
                    <Option value={3}>{t("done")}</Option>
                    <Option value={4}>{t("not_active")}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="contract"
                  label={t("contract")}
                  rules={[{ required: false }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <Form.Item
                  name="files"
                  label={t("upload_files_actions")}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  labelAlign="left"
                >
                  <Upload
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 0);
                    }}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />} disabled={!isEdit}>
                      {t("upload")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>{" "}
            </Row>
            <Collapse
              bordered={false}
              className="collapse-style"
              disabled={!isEdit}
            >
              <Panel
                header={
                  <>
                    <div>
                      <Row
                        gutter={16}
                        className="row-style-collapse"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Col span={10}>{t("specifications")}</Col>
                      </Row>
                    </div>
                  </>
                }
                key="1"
                className="panel-style"
              >
                <Row gutter={16}>
                  <Col span={5} style={{ width: "100%" }}>
                    <Form.Item
                      name="date_one"
                      label={t("date_one")}
                      style={{ width: "100%" }}
                    >
                      <DatePicker disabled={!isEdit} format={dateFormat} />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="date_two" label={t("date_two")}>
                      <DatePicker disabled={!isEdit} format={dateFormat} />
                    </Form.Item>
                  </Col>{" "}
                </Row>
                <Col>
                  <Form.Item name="document_info" label={t("document_info")}>
                    <Input.TextArea
                      disabled={!isEdit}
                      rows={5}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="material_records_info"
                    label={t("material_records_info")}
                  >
                    <Input.TextArea
                      disabled={!isEdit}
                      rows={5}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>{" "}
              </Panel>
            </Collapse>
          </Form>
        </TabPane>
        <TabPane tab={t("position")} key="2" className="tab-pane-style">
          <PositionsContainer
            onClose={onClose}
            data={data}
            id={id}
            getCompanyById={getCompanyById}
            companies={companies}
            lockEditById={lockEditById}
            lock={lock}
            handleThirdPartiesCompanies={handleThirdPartiesCompanies}
            setStatus={setStatus}
            statusCheck={statusCheck}
          />
        </TabPane>
        <TabPane tab={t("third_party")} key='3' className='tab-pane-style'>
          <Contracts onClose={onClose} id={id} handleThirdPartiesCompanies={handleThirdPartiesCompanies} third_parties={third_parties}/>
        </TabPane>
        <TabPane tab={t("addresses")} key="4" className="tab-pane-style">
          <AddressContainer onClose={onClose} id={id} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
