import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Upload, message } from "antd";
import { useTranslation } from "react-i18next";
import { PlusCircleTwoTone, UploadOutlined } from "@ant-design/icons";

// import ReactExport from "react-data-export";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import "../../../public/sass/components/drawer/_positionsContainer.scss";
import { EmptyDataCreate } from "../../EmptyDataCreate";
import { LoadingSpinner } from "../../LoadingSpinner";
import Api from "../../../api";
import PositionsActionTable from "../../Tables/Positions-Action";
import { Filters } from "../../../components/Drawer/Positions-Action/Filter";

import { CreatePositions } from "../../../components/Drawer/Clients/CreatePositionRequests";
import { EditPosition } from "../../../components/Drawer/Positions/EditPosition";

import { toastNotification } from "../../../utils/toastNotification";
import { ExportToExcel } from "../../ExportToExcel";

export const PositionsContainer = ({
  onClose,
  id,
  data,
  getCompanyById,
  lockEditById,
  lock,
  setStatus,
  statusCheck,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [loadingPositions, setLoadingPositions] = useState(true);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [third_parties, setThirdPartiesCompanies] = useState([]);
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [editData, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [role, setRoles] = useState();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });

  const toggleDrawer = () => setVisibleCreate(!isVisibleCreate);

  const dataFormatExcel = [
    {
      Positionsname: "",
      Positionsnummer: "",
      Beschreibung: "",
      Kurzbeschreibung: "",
      Verpackungseinheit: "",
      "Menge je VE": "",
      Laufleistung: "",
      "Typ der Laufleistung": "",
      "Art des Gutachtens": "",
      "Zerschlagungswert menge": "",
      Zwischensumme: "",
      "MwSt. - Art": "",
      "MwSt.": "",
      "Gesamtwert inkl. MwSt.": "",
      "Fortführungswert menge": "",
      Zwischensumme: "",
      "MwSt. - Art	MwSt.": "",
      "Gesamtwert inkl. MwSt": "",
      "Marktwert menge": "",
      Zwischensumme: "",
      "MwSt. - Art": "",
      MwSt: "",
      "Gesamtwert inkl. MwSt.": "",
      Vermögensart: "",
      "Aus- und Absonderungsrechte": "",
      Aussonderungsrechte: "",
      Absonderungsrechte: "",
      "Platz des Artikels": "",
    },
  ];

  const handleSubmit = () => {
    onClose();
  };

  useEffect(() => {
    setStatus(false);
    products.map((item) => {
      if (item.status !== 0) {
        setStatus(true);
      }
    });
  }, [statusCheck, products, third_parties, companies, editData]);

  useEffect(() => {
    getAllCompanies();
    handleThirdPartiesCompanies();
    handleGetAllCategories();
    setLoadingPositions(false);
    setProducts(data?.products);
    handleGetAllRoles();
  }, []);

  const handleGetAllCategories = () => {
    Api("categories", "GET", null)
      .then((data) => {
        setCategories(data.results);
      })
      .catch(() => setLoadingCategories(false));
  };

  const getAllCompanies = () => {
    Api("actions", "GET").then((data) => {
      setCompanies(data.results);
    });
  };

  const handleThirdPartiesCompanies = () => {
    Api("third-party", "GET", null).then((data) => {
      setThirdPartiesCompanies(data.results);
    });
  };

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const getProductById = (id) => {
    setLoading(true);
    Api("products/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteProduct = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("products/removeProductFromAction/" + id, "GET", null)
      .then(() => {
        getProductsByActionId();
        toastNotification("success", `${t("product_successfully_deleted")}`);
      })
      .catch(() => toastNotification("error", `${t("error_delete_product")}`));
  };

  const handleGetAllRoles = () => {
    Api("users/roleId", "GET", null)
      .then((data) => {
        setRoles(data.role.id);
      })
      .catch((err) => toastNotification("error", err.error || "Error"));
  };

  const getProductsByActionId = () => {
    Api("products/getProductsByActionId/" + data?.id, "GET", null).then(
      (data) => {
        setProducts(data.results);
      }
    );
  };

  const handleGetAllProducts = () => {
    Api("actions/" + data?.id, "GET", null)
      .then((data) => {
        setProducts(data.products);
      })
      .catch(() => setLoading(false));
  };

  const handleUploadExcel = (file) => {
    let company_id = localStorage.getItem("company_id");
    let formData = new FormData();

    formData.append("file", file);
    formData.append("company_id", company_id);
    formData.append("action_id", data?.id);

    Api("import/products", "POST", formData)
      .then((data) => {
        message.success("Successfully uploaded file, please wait!");
        getProductsByActionId();
      })
      .catch(() => toastNotification("error", `${t("error_delete_product")}`));
  };

  const csvData = [
    [
      "Positionsname",
      "Positionsnummer",
      "Beschreibung",
      "Kurzbeschreibung",
      "Verpackungseinheit",
      "Menge je VE",
      "Laufleistung",
      "Typ der Laufleistung",
      "Art des Gutachtens",
      "Zerschlagungswert menge",
      "Zwischensumme",
      "MwSt. - Art",
      "MwSt.",
      "Gesamtwert inkl. MwSt.",
      "Fortführungswert menge",
      "Zwischensumme",
      "MwSt. - Art	MwSt.",
      "Gesamtwert inkl. MwSt",
      "Marktwert menge",
      "Zwischensumme",
      "MwSt. - Art",
      "MwSt",
      "Gesamtwert inkl. MwSt.",
      "Vermögensart",
      "Aus- und Absonderungsrechte",
      "Aussonderungsrechte",
      "Absonderungsrechte",
      "Platz des Artikels",
    ],
  ];
  if (loadingPositions) return <LoadingSpinner />;
  if (!data?.products?.length)
    return (
      <>
        <EmptyDataCreate
          btnTitle={t("add_position")}
          message={t("no_positions_found")}
          handleOnClick={toggleDrawer}
        />
        <CreatePositions
          id={id}
          isVisible={isVisibleCreate}
          onClose={toggleDrawer}
          handleGetAllProducts={getProductsByActionId}
          getCompanyById={getCompanyById}
          companies={companies}
          categories={categories}
          third_parties={third_parties}
          products={products}
          action_id={id}
          role={role}
        />
      </>
    );

  return (
    <Form
      form={form}
      id="customers-form"
      layout="horizontal"
      scrollToFirstError
      onFinish={handleSubmit}
      className="form-style"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="more_products">
            <Button className="button-style" onClick={toggleDrawer}>
              <PlusCircleTwoTone className="icon-style" /> {t("more_products")}
            </Button>
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={7}>
          <div className="flex-buttons">
            <Form.Item name="files">
              <Upload
                accept=".xlsm, .xltm, .xlt, .xls, .xlsx, .xltx, .xlsb, .xml, .xlam, .xla, .xlw, .xlr"
                beforeUpload={(file) => {
                  handleUploadExcel(file);
                  // const reader = new FileReader();
                  // reader.onload = e => {
                  // };
                  // reader.readAsText(file);

                  // Prevent upload
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>{t("upload_excel")}</Button>
              </Upload>
            </Form.Item>

            <ExportToExcel
              t={t}
              apiData={dataFormatExcel}
              fileName="PositionenImport"
            />
          </div>
        </Col>
        <Filters />
        <PositionsActionTable
          products={products}
          setProducts={setProducts}
          getProductById={getProductById}
          deleteProduct={deleteProduct}
          // loading={loading}
          companies={companies}
          lockEditById={lockEditById}
          lock={lock}
          id={id}
        />
      </Row>
      <EditPosition
        editData={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetAllProducts={handleGetAllProducts}
        onClose={handleModal}
        companies={companies}
        third_parties={third_parties}
        categories={categories}
        products={products}
      />
      <CreatePositions
        id={id}
        data={data}
        isVisible={isVisibleCreate}
        onClose={toggleDrawer}
        handleGetAllProducts={getProductsByActionId}
        getCompanyById={getCompanyById}
        companies={companies}
        categories={categories}
        third_parties={third_parties}
        products={products}
        action_id={id}
        role={role}
      />
    </Form>
  );
};
