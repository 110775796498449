import React, { useState } from "react";
import { Table, Space, Divider, Input, Button, Modal } from "antd";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";

import QRCode from "react-qr-code";

import ApiConstants from "../../../api/ApiConstants";

import "../../../public/sass/layout/_index.scss";
import "../../../public/sass/components/tables/_database.scss";
import { SearchOutlined, QrcodeOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { MyDocument } from "./MyDocument";
import { formatPrice } from "../../../utils/formatPrice";

const DatabaseTable = ({
  data,
  loading,
  headers,
  total,
  handleGetAllProducts,
}) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedData, setSelectedData] = useState([]);
  const [searchedColumn, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isQRCode, setQRCode] = useState(false);
  const [name, setName] = useState("");
  const [t, i18n] = useTranslation();

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="container">
        <Input
          placeholder={`Suche nach ${t(dataIndex)}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="container__input"
        />
        <Space className="container__space">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="container__space--button"
          >
            {t("search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            className="container__space--button"
          >
            {t("reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          // textToHighlight= {t(text)}
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearch(dataIndex);
  };

  const handleReset = (clearFilters) => {
    setSearchText();
    clearFilters();
  };

  const columns = [
    {
      title: "Pos-Nr",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Bezeichnung",
      dataIndex: "short_description",
      key: "short_description",
      sorter: (a, b) =>
        a?.short_description?.length - b?.short_description?.length,
      ...getColumnSearchProps("short_description"),
    },
    {
      title: "Sachanlage",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a?.category?.length - b?.category?.length,
      ...getColumnSearchProps("category"),
      render: (category) => <>{category?.name}</>,
    },
    {
      title: "Zerschlagungswert",
      dataIndex: "breakdown_value",
      key: "breakdown_value",
      sorter: (a, b) => a?.breakdown_value - b?.breakdown_value,
      ...getColumnSearchProps("breakdown_value"),
      render: (e) => (
        <>
          <p>{!e ? "" : formatPrice(e)}</p>
        </>
      ),
    },
    {
      title: "Fortführungswert",
      dataIndex: "going_concern_value",
      key: "going_concern_value",
      sorter: (a, b) => a?.going_concern_value - b?.going_concern_value,
      ...getColumnSearchProps("going_concern_value"),
      render: (e) => (
        <>
          <p>{!e ? "" : formatPrice(e)}</p>
        </>
      ),
    },
    {
      title: "Marktwert",
      dataIndex: "market_value",
      key: "market_value",
      sorter: (a, b) => a?.market_value - b?.market_value,
      ...getColumnSearchProps("market_value"),
      render: (e) => (
        <>
          <p>{!e ? "" : formatPrice(e)}</p>
        </>
      ),
    },
    {
      title: "Erstellungsdatum",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      ...getColumnSearchProps("created_at"),
      render: (created_at) => (
        <>
          <p>{moment(created_at).format("DD.MM.YYYY")}</p>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "confirmed",
      key: "confirmed",
      sorter: (a, b) => a.confirmed - b.confirmed,
      render: (confirmed) => (
        <>
          <p style={{ color: confirmed ? "#09CE87" : "#F5222D" }}>
            {confirmed ? `${t("active")}` : `${t("not_active")}`}
          </p>
        </>
      ),
    },
    {
      title: "QR Code",
      dataIndex: "",
      key: "",
      render: (item) => (
        <>
          <a style={{ paddingLeft: 15 }}>
            <QrcodeOutlined
              onClick={() => {
                setName(JSON.stringify(item?.name + item?.short_description));
                setQRCode(!isQRCode);
              }}
              style={{ fontSize: 20 }}
            />
          </a>
        </>
      ),
    },
  ];

  headers = [
    { label: "Product ID", key: "product_id" },
    { label: "Description", key: "short_description" },
    { label: "Category", key: "category" },
    { label: "Breakdown value", key: "breakdown_value" },
    { label: "Going concern value", key: "going_concern_value" },
    { label: "Market value", key: "market_value" },
  ];

  const finalData = !selectedData.length ? data : selectedData;
  const generatePdfDocument = async (documentData) => {
    const blob = await pdf(
      <MyDocument title="My PDF" data={documentData} />
    ).toBlob();

    saveAs(blob, "datenbank");
  };

  const renderPdf = async (documentData) => {
    // if (documentData.length === 0) {
    //   documentData = data;
    // }
    documentData.map((item) => {
      item.category = item.category?.name;
    });

    generatePdfDocument(documentData);
  };

  const csvData = finalData.map((item) => ({
    product_id: item?.product_id || "No ID",
    short_description: item?.short_description || "No description",
    category: item?.category || "No category",
    breakdown_value: item?.breakdown_value || "No breakdown value",
    going_concern_value: item?.going_concern_value || "No going concern value",
    market_value: item?.market_value || "No market value",
  }));

  const handleTableChange = (pagination) => {
    handleGetAllProducts(pagination?.pageSize, pagination?.current);
  };

  return (
    <div>
      <Divider />
      <Table
        locale={{ emptyText: "Kein Ergebnis" }}
        loading={loading}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        onChange={handleTableChange}
        pagination={{
          total: total, // total count returned from backend
        }}
        rowKey={(i) => i.product_id}
        expandable={{
          expandedRowRender: (record) => (
            <p className="database__table--expandable">
              {record?.long_description}
            </p>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        columns={columns}
        dataSource={data}
        className="table database__table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />

      {data.length ? (
        <div className="download__pdf_wrapper">
          <Button className="button" onClick={() => renderPdf(selectedData)}>
            {t("download_pdf")}
          </Button>

          <Button className="button">
            <CSVLink
              data={csvData}
              headers={headers}
              separator={";"}
              filename={"datenbank.csv"}
            >
              {t("download_csv")}
            </CSVLink>
          </Button>
        </div>
      ) : (
        ""
      )}

      <Modal
        width={350}
        className="database__table--modal"
        title={name?.short_description}
        centered
        visible={isQRCode}
        onOk={() => setQRCode(!isQRCode)}
        onCancel={() => setQRCode(!isQRCode)}
      >
        <div className="database__table--modal-container">
          <QRCode size={150} value={name} />
        </div>
      </Modal>
    </div>
  );
};
export default DatabaseTable;
