import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Form,
  Col,
  Row,
  Input,
  Select,
  InputNumber,
  Collapse,
  DatePicker,
  Upload,
  Button,
} from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";
import { default_text } from "../../../utils/default_text";
import { UploadOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

export const CreateClientForm = ({ onClose, handleGetAllClients }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const company_id = localStorage.getItem("company_id");

  const dateFormat = "DD.MM.YYYY";

  useEffect(() => {
    form.setFieldsValue({ document_info: default_text.document_info });
  }, []);

  const handleSubmit = (formValues) => {
    formValues.company_id = JSON.parse(company_id);

    let data = formValues;
    let list_images = [];
    if (data?.images?.fileList?.length) {
      data?.images?.fileList.forEach((item) => {
        if (item) {
          list_images.push(item?.originFileObj);
        }
      });
    }

    if (data?.files?.fileList?.length) {
      data?.files?.fileList.forEach((item) => {
        if (item) {
          list_images.push(item?.originFileObj);
        }
      });
    }

    delete data.images;

    const formData = new FormData();

    Object.entries(formValues).forEach(([key, value]) => {
      if (key !== "files") {
        formData.append(key, value);
      }
    });

    if (list_images.length) {
      list_images.forEach((file) => {
        formData.append("files", file);
      });
    }

    Api("actions", "POST", formData)
      .then(() => {
        toastNotification("success", `${t("user_created_successfully")}`);
        handleGetAllClients();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_create_user")}`)
      );
  };

  const getAllCustomers = () => {
    setLoadingCustomers(true);
    Api("clients", "GET", null)
      .then((data) => {
        setAllCustomers(data.results);
        setLoadingCustomers(false);
      })
      .catch(() => setLoadingCustomers(false));
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };
  //Create Action
  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      id="users-form"
      layout="vertical"
      scrollToFirstError
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label={t("name")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name="action_id"
            label={t("client_number")}
            rules={[{ required: true, message: `${t("client_number")}` }]}
          >
            <Input type="number" placeholder={t("client_number")} />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="type_of_procedure"
            label={t("type_of_procedure")}
            rules={[{ required: true }]}
          >
            <Select
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value="Regelverfahren">Regelverfahren</Option>
              <Option value="Eigenverwaltung">Eigenverwaltung</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            onClick={getAllCustomers}
            name="client_id"
            label={t("customer")}
            rules={[
              {
                // required: true,
              },
            ]}
          >
            <Select
              loading={loadingCustomers}
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {allCustomers?.map((customer, idx) => (
                <Option key={idx} value={customer?.id}>
                  {customer?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="owner_firstname"
            label={t("owner_firstname")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="owner_lastname"
            label={t("owner_lastname")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="street"
            label={t("street")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="house_number"
            label={t("house_number")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="zip" label={t("zip")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="city"
            label={t("city_name")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="country"
            label={t("country")}
            rules={[{ required: true }]}
          >
            <Select
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value="Deutschland">{t("germany")}</Option>
              <Option value="Österreich">{t("austria")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phone"
            label={t("phone")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="email"
            label={t("email")}
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name="more_addresses"
            label={t("more_addresses")}
            onClick={toggleDrawer}
          >
            <Button className="button-style">
              <PlusCircleTwoTone className="icon-style" /> {t("more_addresses")}
            </Button>
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value={0}>{t("accepted")}</Option>
              <Option value={1}>{t("not_accepted")}</Option>
              <Option value={2}>{t("in_progress")}</Option>
              <Option value={3}>{t("done")}</Option>
              <Option value={4}>{t("not_active")}</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <CreateAddress isVisible={isVisibleCreate} onClose={toggleDrawer} /> */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="hrb_number"
            label={t("hrb_number")}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="ustid_number"
            label={t("ustid_number")}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="tax_number"
            label={t("tax_number")}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="disposal_costs"
            label={t("disposal_costs")}
            rules={[{ required: false }]}
          >
            <InputNumber
              type="number"
              min={0}
              controls={false}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="contract"
            label={t("contract")}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={6}>
          <Form.Item
            name="files"
            label={t("upload_files_actions")}
            rules={[
              {
                required: false,
              },
            ]}
            labelAlign="left"
          >
            <Upload
              customRequest={({ file, onSuccess }) => {
                setTimeout(() => {
                  onSuccess("ok");
                }, 0);
              }}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>{t("upload")}</Button>
            </Upload>
          </Form.Item>
        </Col>{" "}
      </Row>

      <Collapse bordered={false} className="collapse-style">
        <Panel
          header={
            <>
              <div>
                <Row
                  gutter={16}
                  className="row-style-collapse"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Col span={10}>{t("specifications")}</Col>
                </Row>
              </div>
            </>
          }
          key="1"
          className="panel-style"
        >
          <Row gutter={16}>
            <Col span={5} style={{ width: "100%" }}>
              <Form.Item
                name="date_one"
                label={t("date_one")}
                style={{ width: "100%" }}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="date_two" label={t("date_two")}>
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>{" "}
          </Row>
          <Col>
            <Form.Item name="document_info" label={t("document_info")}>
              <Input.TextArea rows={5} placeholder="" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="material_records_info"
              label={t("material_records_info")}
            >
              <Input.TextArea rows={5} placeholder="" />
            </Form.Item>
          </Col>{" "}
        </Panel>
      </Collapse>
    </Form>
  );
};
