import React from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Card, Input, Button, Checkbox } from "antd";

// Import actions
import { login } from "./store/auth-actions";

import "../../public/sass/pages/_login.scss";
import logo from "../../public/images/login/login_logo.svg";
import bg from "../../public/images/login/bg.png";

import { toastNotification } from "../../utils/toastNotification";
import Api from "../../api";

const Login = ({ onAuthLogin, ...props }) => {
  const { t, i18n } = useTranslation();
  const defaultLanguage = localStorage.getItem("default-language");

  const handleSubmit = (formValues) => {
    Api("auth/login", "POST", formValues)
      .then((response) => {
        localStorage.setItem("auc-token", JSON.stringify(response.token));
        localStorage.setItem("user_id", JSON.stringify(response.user_id));

        onAuthLogin(formValues.email, formValues.password);

        if (response.first_login == true) {
          props.history.push("/change-password");
        } else {
          props.history.push("/dashboard");
        }
      })
      .catch((err) =>
        toastNotification("error", err.message || "Error while creating user")
      );
  };

  const switchLanguage = (language) => {
    localStorage.setItem("default-language", language);
    i18n.changeLanguage(language);
  };

  return (
    <div className="login-container" style={{ backgroundImage: `url(${bg})` }}>
      <div className="content">
        <Card bordered={false} className="card">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <p className="title">
            {t("welcome")} {t("back")}!
          </p>
          <Form name="inline" layout="vertical" onFinish={handleSubmit}>
            <Form.Item className="item">
              <Select
                defaultValue={defaultLanguage === "en" ? "English" : "German"}
                placeholder="Please choose language"
                onChange={switchLanguage}
              >
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="de">German</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="item"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: `${t("email_is_required")}`,
                },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>

            <Form.Item
              className="item"
              name="password"
              rules={[
                {
                  required: true,
                  message: `${t("password_is_required")}`,
                },
              ]}
            >
              <Input.Password placeholder={t("password")} />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked" className="item">
              <Checkbox className="checkbox">{t("remember_me")}</Checkbox>
              <Link to="/reset-password" className="link">
                <a className="login-form-forgot">{t("forgot_password")}</a>
              </Link>
            </Form.Item>

            <Form.Item>
              <Button
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                {t("submit_2")}
              </Button>
              <hr className="hr" />
              <div className="register-text">
                {t("not_registered_yet")}?
                <Link to="/register">
                  {" "}
                  <a>{t("register_here")}!</a>
                </Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  onAuthLogin: (email, password) => dispatch(login(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
