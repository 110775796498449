import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import { AddressContainer } from "../../../containers/Dashboard/Addresses/AddressContainer";

import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";

const { Option } = Select;

export const CreateUsers = ({
  isVisible,
  onClose,
  handleGetAllUsers,
  userData,
  id,
  companies,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const [usersRoles, setUsersRoles] = useState();
  const company_id = localStorage.getItem("company_id");



  useEffect(() => {
    getAllRolesForUsers()
  }, []);

  const getAllRolesForUsers = () => {
    Api('roles/get/user-roles', 'GET', null)
      .then((data) => {
        setUsersRoles(data.data);
      })
      .catch((err) => {
        toastNotification('error', err.error.message || 'error');
      });
  };

  const handleSubmit = (formValues) => {
    // formValues.company_id = JSON.parse(company_id);
    Api("users", "POST", formValues)
      .then(() => {
        toastNotification("success", `${t("user_created_successfully")}`);
        handleGetAllUsers();
        form.resetFields();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_create_user")}`)
      );
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("create_new_user")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="users-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Tabs
        defaultActiveKey="1"
        className="tab-style"
      >
        <TabPane tab={t("user")} key="1" className="tab-pane-style">
          <Form
            form={form}
            validateMessages={validateMessages}
            id="users-form"
            layout="vertical"
            scrollToFirstError
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  // onClick={getAllCompanies}
                  name="company_id"
                  label={t("company")}
                  rules={[{ required: true }]}
                >
                  <Select
                    // loading={loadingCompanies}
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    {companies?.map((item, idx) => {
                      return (
                        <Option key={idx} value={item?.id}>
                          {item?.name}
                        </Option>
                      )
                    })}
                    {/* {companies?.map((item, idx) => {
                      if(userData?.role === 'admin' && company_id) {
                        if(item?.id == JSON.parse(company_id)) {
                            return (
                              <Option key={idx} value={item?.id}>
                              {item?.name}
                            </Option>
                            )
                        }
                      } else {
                        return (
                          <Option key={idx} value={item?.id}>
                            {item?.name}
                          </Option>
                        )
                      }
                    })} */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="role"
                  label={t("role")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                  >
                    {usersRoles?.map((item, idx) => {
                      return (
                        <Option key={idx} value={item.id}>{item.name}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  name="user_id"
                  label={t("user_number")}
                  rules={[
                    { required: true, message: `${t("user_id_required")}` },
                  ]}
                >
                  <Input type="number" placeholder={t("user_number")} />
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstname"
                  label={t("firstname")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastname"
                  label={t("lastname")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={t("phone")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                {" "}
                <Form.Item
                  name="email"
                  label={t("email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label={t("street")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="house_number"
                  label={t("house_number")}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      max: 5,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="zip"
                  label={t("zip")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label={t("city")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label={t("country")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="Deutschland">{t("germany")}</Option>
                    <Option value="Österreich">{t("austria")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value={1}>{t("active")}</Option>
                    <Option value={0}>{t("not_active")}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={t("addresses")} key="2" className="tab-pane-style">
          <AddressContainer onClose={onClose} id={id} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
