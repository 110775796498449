import React, { useState } from "react";
import { Table, Divider, Space, Popconfirm, Modal } from "antd";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import ApiConstants from "../../../api/ApiConstants";

import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";

import "../../../public/sass/layout/_index.scss";

const PositionsRequestTable = ({
  products,
  loading,
  deleteProduct,
  getProductById,
  data,
}) => {
  const { t, i18n } = useTranslation();
  const [isQRCode, setQRCode] = useState(false);
  const [name, setName] = useState("");

  const columns = [
    {
      title: "Nr",
      dataIndex: "product_id",
      key: "product_id",
      render: (product_id) => <>P{product_id}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Zerschlagungswert",
      dataIndex: "breakdown_value",
      key: "breakdown_value",
      sorter: (a, b) => a.breakdown_value.length - b.breakdown_value.length,
    },
    {
      title: "Aktion",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) =>
        data.status !== 3 && (
          <Space size="middle">
            <a onClick={() => getProductById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteProduct(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          </Space>
        ),
    },
  ];
  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={products}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
        rowClassName={(record) =>
          record.is_incomplete == "1" ? "row-table-true" : ""
        }
      />
      <Modal
        width={350}
        style={{ textAlign: "center" }}
        title={name}
        centered
        visible={isQRCode}
        onOk={() => setQRCode(!isQRCode)}
        onCancel={() => setQRCode(!isQRCode)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <p
            style={{
              margin: "10px 25px",
              textAlign: "center",
              paddingBottom: "20px",
              color: "#9c9c9c",
            }}
          >
            Scan this QR code to get this product and import into our database.
          </p> */}
          <QRCode size={150} value={Math.floor(Math.random() * 100000 + 1)} />
          {/* <p
            style={{
              margin: "10px 25px",
              textAlign: "center",
              fontSize: "12px",
              paddingTop: "25px",
              color: "#9c9c9c",
            }}
          >
            This is a single use code for use only. Get a new code each time you
            get a product
          </p> */}
        </div>
      </Modal>
    </div>
  );
};
export default PositionsRequestTable;
