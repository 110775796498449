import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import Api from "../../../api";
import { toastNotification } from "../../../utils/toastNotification";

import "../../../public/sass/components/drawer/_createDrawers.scss";

export const CreateAddress = ({ isVisible, onClose, id, getAddressData }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const handleSubmit = (formValues) => {
    formValues.user_id = id;

    Api("addresses", "POST", formValues)
      .then(() => {
        getAddressData();
        toastNotification("success", `${t("address_created_successfully")}`);
        onClose();
      })
      .catch((err) => {
        onClose();
        toastNotification("error", err.error || `${t("error_create_address")}`);
      });
    form.resetFields();
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("new_address")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="bodystyle"
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="buttonstyle">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="customersform"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="customersform"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="street"
              label={t("street")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="house_number"
              label={t("house_number")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="zip" label={t("zip")} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={t("city_name")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="country"
              label={t("country")}
              rules={[{ required: true }]}
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value="Deutschland">{t("germany")}</Option>
                <Option value="Österreich">{t("austria")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="tel" label={t("tel")} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
