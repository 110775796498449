import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Filters } from "../../../components/Drawer/Companies/Filters";
import { CreateCompanies } from "../../../components/Drawer/Companies/CreateCompanies";
import { EditCompany } from "../../../components/Drawer/Companies/EditCompany";
import { toastNotification } from "../../../utils/toastNotification";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import CompaniesTable from "../../../components/Tables/Companies";
import Api from "../../../api";
import "../../../public/sass/pages/_companies.scss";

const CompaniesContainer = () => {
  const { t, i18n } = useTranslation();
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [editData, setData] = useState([]);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    handleGetAllCompanies();
  }, []);

  const onSearch = (value) => console.log(value);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllCompanies = () => {
    Api("companies", "GET", null)
      .then((data) => {
        setCompanies(data.results);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getCompanyById = (id) => {
    setLoading(true);
    Api("companies/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const handleFilter = (filterValues) => {
    if (
      filterValues.status == undefined
    ) {
      setSearch(false);
      return;
    }
    Api(
      `companies/filter/companies?user_counter=${
        filterValues.user_quantity
      }&status=${filterValues.status}&pageSize=${100}&page=${0}`,
      "GET",
      null
    )
      .then((data) => {
        setSearch(true);
        setCompanies(data.results);
      })
      .catch(() => toastNotification("error", `Error while filtering`));
  };
  const deleteCompany = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("companies/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("company_successfully_deleted")}`);
        handleGetAllCompanies();
      })
      .catch(() => toastNotification("error", `${t("error_delete_company")}`));
  };

  return (
    <div className="customers-container">
      <div className="header">
        <Filters
          {...onSearch}
          handleGetAllCompanies={handleGetAllCompanies}
          handleFilter={handleFilter}
          search={search}
          setSearch={setSearch}
        />

        {permissionsFilter("Companies", "CompanyCreate") && (
          <Button
            className="button"
            onClick={() => handleModal("create", true)}
          >
            {t("add_company")}
          </Button>
        )}

        <CreateCompanies
          onClose={handleModal}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllCompanies={handleGetAllCompanies}
        />

        <EditCompany
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllCompanies={handleGetAllCompanies}
          onClose={handleModal}
        />
      </div>
      <CompaniesTable
        companies={companies}
        getCompanyById={getCompanyById}
        deleteCompany={deleteCompany}
        loading={loading}
      />
    </div>
  );
};
export default CompaniesContainer;
