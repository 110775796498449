import React, { useState } from "react";
import { Drawer, Button, Form, Col, Row, Input, Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { AddressContainer } from "../../../containers/Dashboard/Addresses/AddressContainer";

export const CreateClientRequests = ({
  isVisible,
  onClose,
  handleGetAllClientRequests,
  id,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const company_id = localStorage.getItem("company_id");

  const handleSubmit = (formValues) => {
    formValues.company_id = JSON.parse(company_id);


    Api("clients", "POST", formValues)
      .then(() => {
        toastNotification("success", `${t("customer_created_successfully")}`);
        handleGetAllClientRequests();
        form.resetFields();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_create_customer")}`)
      );
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("create_new_customer_request")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="users-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Tabs defaultActiveKey="1" className="tab-style">
        <TabPane tab={t("customer_kunde")} key="1" className="tab-pane-style">
          <Form
            form={form}
            validateMessages={validateMessages}
            id="users-form"
            layout="vertical"
            visible={isVisible}
            scrollToFirstError
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              {/* <Col span={12}>
                <Form.Item
                  name="company_id"
                  label={t("customer_number")}
                  rules={[
                    { required: true, message: `${t("customer_number")}` },
                  ]}
                >
                  <Input placeholder={t("customer_number")} type="number" />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={t("name")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="owner_firstname"
                  label={t("owner_firstname")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="owner_lastname"
                  label={t("owner_lastname")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label={t("street")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="house_number"
                  label={t("house_number")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="zip"
                  label={t("zip")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label={t("city_name")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label={t("country")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value="Deutschland">{t("germany")}</Option>
                    <Option value="Österreich">{t("austria")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={t("phone")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label={t("email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
            <Form.Item name="more_addresses" label={t("more_addresses")}>
              <Button className="button-style">
                <PlusCircleTwoTone className="icon-style" />{" "}
                {t("more_addresses")}
              </Button>
            </Form.Item>
          </Col> */}
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                  >
                    <Option value={1}>{t("active")}</Option>
                    <Option value={0}>{t("not_active")}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <CreateAddress isVisible={isVisibleCreate} onClose={toggleDrawer} /> */}

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="hrb_number"
                  label={t("hrb_number")}
                  rules={[{ required: false }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ustid_number"
                  label={t("ustid_number")}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="tax_number"
                  label={t("tax_number")}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={t("addresses")} key="2" className="tab-pane-style">
          <AddressContainer onClose={onClose} id={id} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
