import { StyleSheet, Font } from "@react-pdf/renderer";

const HEADER_BACKGROUND = "#364059";
const WHTIE_COLOR = "#FFFFFF";
const COLN_WIDTH = 100 / 5;
const MIN_WIDTH = 100 / 10;
const ROW_HEIGHT = 35;

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf",
      fontWeight: 400,
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-500.ttf",
      fontWeight: 500,
    },
  ],
});

export const styles = StyleSheet.create({
  body: { padding: 10, backgroundColor: "white", position: "relative" },
  title: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    display: "flex",
    textAlign: "center",
    margin: "20px 0",
    color: HEADER_BACKGROUND,
  },
  table: {
    display: "table",
    width: "auto",
    border: "none",
    textAlign: "center",
    fontSize: "11px",
    fontFamily: "Open Sans",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    height: ROW_HEIGHT,
    fontWeight: "bold",
    backgroundColor: HEADER_BACKGROUND,
  },
  tableCellHeader: {
    margin: 5,
    fontWeight: 500,
    color: WHTIE_COLOR,
    paddingTop: "5px",
    fontWeight: 600,
  },
  tableCol1Header: {
    width: MIN_WIDTH + "%",
    height: ROW_HEIGHT,
    fontWeight: "bold",
    backgroundColor: HEADER_BACKGROUND,
  },
  tableRows: {
    margin: "auto",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: ROW_HEIGHT,
  },
  tableCol1: {
    width: MIN_WIDTH + "%",
  },
  tableCol: {
    width: COLN_WIDTH + "%",
  },
  tableCell: {
    margin: 5,
    fontWeight: 400,
    color: "#262626",
  },
});
