import React from "react";
import { AutoComplete } from "antd";

export const SearchBy = ({ onSearch, t }) => {
  return (
    <AutoComplete
      onSearch={onSearch}
      placeholder={t("search_by_name_role")}
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    />
  );
};
