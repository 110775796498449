import * as actions from './auth-actionTypes';

const initialState = {
    isAuthenticated: false
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case actions.AUTH_LOGIN:
            return { ...state, isAuthenticated: true };
        default:
            return { ...state };
    }
} 