import ApiConstants from "./ApiConstants";
import axios from "axios";
import { toastNotification } from "../utils/toastNotification";

export default async function api(path, method, params) {
  let options;
  let token = JSON.parse(localStorage.getItem('auc-token'));
  options = {
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    method: method,
    url: ApiConstants.BASE_URL + path,
    ...(params && { data: params }),
  };

  return axios(options)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        toastNotification('info', 'Session expired, please login again!');
        localStorage.clear();
      } else {
        return Promise.reject(error?.response?.data);
      }
    });
}