import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
} from "antd";
import Api from "../../../api";

import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";

import "../../../public/sass/components/drawer/positions/_positions.scss";
import "../../../public/sass/components/drawer/_createDrawers.scss";

export const CreateWord = ({
  isVisible,
  onClose,
  handleGetAllWords,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  
  useEffect(() => { }, []);

  const handleSubmit = (formValues) => {
    Api("words", "POST", formValues)
      .then(() => {
        toastNotification("success", `${t("word_created_successfully")}`);
        handleGetAllWords();
        form.resetFields();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("error_create_words")}`);
      });
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      width={720}
      onClose={onReset}
      visible={isVisible}
      title={t("create_new_word")}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="words-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="words-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
        className="form-style"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("word")}
              rules={[{ required: true }]}
              labelAlign="left"
            >
              <Input placeholder={t("word")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer >
  );
};





