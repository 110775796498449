import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { IntroPage } from "./IntroPage";
import { SecondPage } from "./SecondPage";
import { Table } from "./Table";

export function PdfDocument({ data }) {
  return (
    <Document>
      <IntroPage data={data} />
      <SecondPage data={data} />
      <Table data={data} />
    </Document>
  );
}
