import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

export const PdfHeader = ({ imgUrl }) => (
  <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
    <Image style={{ width: "25%" }} source={imgUrl} />
  </View>
);
