import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, message, Row, Col } from "antd";
import { toastNotification } from "../../../utils/toastNotification";
import { EditWord } from "../../../components/Drawer/Worter/EditWord";
import { CreateWord } from "../../../components/Drawer/Worter/CreateWord";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import WorterTable from "../../../components/Tables/Worter";
import Api from "../../../api";
import "../../../public/sass/pages/_positions.scss";

const WordsContainer = () => {
  const { t, i18n } = useTranslation();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [words, setWords] = useState([]);
  const [editData, setData] = useState([]);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    handleGetAllWords();
  }, []);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllWords = () => {
    Api(`words`, "GET", null)
      .then((data) => {
        setWords(data?.results);
        setTotal(data?.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getWordById = (id) => {
    setLoading(true);
    Api("words/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteWord = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("words/" + id, "DELETE", null)
      .then(() => {
        handleGetAllWords();
        toastNotification("success", `${t("word_successfully_deleted")}`);
      })
      .catch(() => toastNotification("error", `${t("error_delete_word")}`));
  };

  return (
    <div className="words-container">
      <Row>
        <Col flex="0 1 100%">
          {permissionsFilter('Words', 'WordCreate') &&
            <Button
              style={{ float: "right" }}
              className="button"
              onClick={() => handleModal("create", true)}
            >
              {t("add_word")}
            </Button>
          }
        </Col>
      </Row>

      <div className="header">
        <CreateWord
          isVisible={modalState.mode == "create" && modalState.isOpen}
          onClose={handleModal}
          handleGetAllWords={handleGetAllWords}
          words={words}
        />

        <EditWord
          editData={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllWords={handleGetAllWords}
          onClose={handleModal}
        />
      </div>

      <WorterTable
        words={words}
        getWordById={getWordById}
        deleteWord={deleteWord}
        handleGetAllWords={handleGetAllWords}
        loading={loading}
        total={total}
      />
    </div>
  );
};
export default WordsContainer;
