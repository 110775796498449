import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "../../Clients/pdf/pdf_fortuhrung/PdfHeader";

export const IntroPage = ({ data }) => {
  if (!data) return null;
  return (
    <Page size="A4" size="LETTER" style={{ backgroundColor: "#fff",  fontFamily: "Poppins", }}>
      <Image
        style={{
          position: "absolute",
          height: "100%",
          width: "150%",
        }}
        src={"https://i.imgur.com/sXL0x9m.png"}
        alt="images"
      />
      <View style={{ padding: "30px" }}>
        <PdfHeader imgUrl="https://i.imgur.com/BUQFkU3.jpg" />
      </View>
      <View
        style={{
          flexDirection: "column",
          marginTop: "100px",
          alignItems: "left",
          lineHeight: 1.5,
          padding: "50px",
        }}
      >
        <Text
          style={{ fontSize: "42px", color: "#364059", fontWeight: "bold" }}
        >
          DESKTOPBEWERTUNG
        </Text>
        <Text style={{ color: "#8F8F8F" }}>Wertermittlung</Text>
        <Text style={{ color: "#8F8F8F" }}>ZERSCHLAGUNGSWERT</Text>

        <View style={{ marginTop: 50, fontSize: "12px" }}>
          <Text style={{ color: "#364059" }}>
            {`${data?.name || ""}`}
          </Text>
          <Text style={{ color: "#364059" }}>
            {`${data?.street || ""} ${data?.house_number || ""}`}
          </Text>
          <Text style={{ color: "#364059" }}>
            {`${data?.zip || ""} ${data?.city || ""}`}
          </Text>
          <Text style={{ color: "#364059" }}>{data?.country || ""}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "auto",
          textAlign: "right",
        }}
      >
        <View
          style={{
            // width: "45%",
            // textAlign: "center",
            lineHeight: 1.4,
            fontSize: "12px",
            color: "#364059",
            padding: "30px",
          }}
        >
          <Text style={{ fontWeight: '600' }}>{data?.owner_firstname || ""}</Text>
          <Text>{`${data?.company?.street || ""} ${data?.house_number || ""}`}</Text>
          <Text>{`${data?.company?.zip || ""} ${data?.city || ""}`}</Text>
          <Text>{`${data?.country || ""}`}</Text>
          <View style={{ paddingTop: "15px" }}>
            <Text>Tel: {data?.phone || ""}</Text>
            <Text>{data?.email || ""}</Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

// export const IntroPage = ({ data }) => {
//   if (!data) return null;
//   return (
//     <Page size="A4" style={styles.page}>
//       <Image style={styles.image} src={Logo} />
//       <Text style={styles.uppercaseText}>DESKTOPBEWERTUNG</Text>
//       <View style={styles.firstSection}>
//         <Text>Wertermittlung</Text>
//         <Text style={styles.firstParagraph}>ZERSCHLAGUNGSWERT</Text>
//       </View>
//       <View style={styles.secondSection}>
//         <Text style={styles.name}>{data?.name || ""}</Text>
//         <View style={styles.right}>
//           <Text>{data?.owner_firstname || ""}</Text>
//           <Text>{data?.owner_lastname || ""}</Text>
//           <Text>{data?.street || ""}</Text>
//         </View>
//       </View>
//       <View style={styles.thirdSection}>
//         <Text style={styles.bottomName}>{data?.name || ""}</Text>
//         <Text style={styles.bottomData}>{data?.street || ""}</Text>
//         <Text style={styles.bottomData}>{data?.city || ""}</Text>
//       </View>
//     </Page>
//   );
// };
