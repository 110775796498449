import React from "react";
import { Table, Space, Divider, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ApiConstants from "../../../api/ApiConstants";
import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/layout/_index.scss";

const UsersTable = ({ users, loading, getUserById, deleteUser }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: "Nr",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Firma",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => a.company_id.length - b.company_id.length,
      render: (company) => <>{company?.name}</>,
    },
    {
      title: "Vorname",
      dataIndex: "firstname",
      key: "firstname",
      sorter: (a, b) => a.firstname.length - b.firstname.length,
    },
    {
      title: "Nachname",
      dataIndex: "lastname",
      key: "lastname",
      sorter: (a, b) => a.lastname.length - b.lastname.length,
    },
    {
      title: "Addresse",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Mail",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Tel",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (status) => (
        <>
          <a style={{ color: status == 1 ? "#09CE87" : "#F5222D" }}>
            {status == 1 ? `${t("active")}` : `${t("not_active")}`}
          </a>
        </>
      ),
    },
    {
      title: "Aktion",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          {permissionsFilter('Users', 'UserUpdate') &&
            <a onClick={() => getUserById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
          }
          {permissionsFilter('Users', 'UserDelete') &&
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteUser(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={users}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
    </div>
  );
};
export default UsersTable;
