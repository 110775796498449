import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";
import { toastNotification } from "../../../utils/toastNotification";
import { SearchBy } from "../../../components/Tables/Database/SearchBy";
import { CreateClientRequests } from "../../../components/Drawer/Clients-Requests/CreateClientRequests";
import { EditClientRequest } from "../../../components/Drawer/Clients-Requests/EditClientRequest";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ClientsRequestsTable from "../../../components/Tables/Clients-Requests";
import Api from "../../../api";
import "../../../public/sass/pages/_customers.scss";

const ClientsRequestsContainer = () => {
  const { t, i18n } = useTranslation();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [editData, setData] = useState([]);

  useEffect(() => {
    handleGetAllClientRequests();
  }, []);

  const handleSearch = (e) => {
    let value = e.toLowerCase();

    if (value.length > 2) {
      setLoading(true);

      let company_id = localStorage.getItem("company_id");

      company_id = company_id.replace(/"/g, "");

      if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
        Api(`clients/search/` + value, "GET", null)
          .then((data) => {
            if (data) {
              setClients(data?.results);

              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        Api(
          `clients/search/by-company/${company_id}/` + value,
          "GET",
          null
        )
          .then((data) => {
            if (data) {
              setClients(data?.results);

              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }

    if (value.length === 0) {
      handleGetAllClientRequests();
    }
  };

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllClientRequests = () => {
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
      Api("clients", "GET", null)
        .then((data) => {
          setClients(data.results);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      Api("clients/by-company/" + company_id, "GET", null)
        .then((data) => {
          setClients(data.results);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const getClientRequestById = (id) => {
    setLoading(true);
    Api("clients/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteClientRequest = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("clients/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("customer_successfully_deleted")}`);
        handleGetAllClientRequests();
      })
      .catch(() => toastNotification("error", `${t("error_delete_customer")}`));
  };

  return (
    <div className="customers-container">
      <Row>
        <Col flex="1 1 200px">
          <SearchBy onSearch={handleSearch} data={clients} t={t} />
        </Col>
        <Col flex="0 1 300px">
          {permissionsFilter('Request Clients', 'RequestClientCreate') &&
            <Button
              style={{ float: "right" }}
              className="button"
              onClick={() => handleModal("create", true)}
            >
              {t("add_customer_client")}
            </Button>
          }
        </Col>
      </Row>
      <div className="header">
        <CreateClientRequests
          onClose={handleModal}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllClientRequests={handleGetAllClientRequests}
        />

        <EditClientRequest
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllClientRequests={handleGetAllClientRequests}
          onClose={handleModal}
        />
      </div>
      <ClientsRequestsTable
        clients={clients}
        getClientRequestById={getClientRequestById}
        deleteClientRequest={deleteClientRequest}
        loading={loading}
      />
    </div>
  );
};
export default ClientsRequestsContainer;
