import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Filters } from "../../../components/Drawer/Clients/Filters";
import { CreateClients } from "../../../components/Drawer/Clients/CreateClients";
import { EditClient } from "../../../components/Drawer/Clients/EditClient";
import { toastNotification } from "../../../utils/toastNotification";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ClientsTable from "../../../components/Tables/Clients";
import Api from "../../../api";
import "../../../public/sass/pages/_clients.scss";

const ClientsContainer = () => {
  const { t, i18n } = useTranslation();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [editData, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [lock, setLock] = useState(false);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    handleGetAllClients();
    handleGetAllCompanies();
  }, []);

  const lockEditById = () => {
    Api("actions/" + modalState.id, "GET").then((data) => {
      setLock(data?.is_set == 1 ? true : false);
    });
  };

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllClients = () => {
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
      Api("actions", "GET", null)
        .then((data) => {
          setClients(data.results);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      Api("actions/by-company/" + company_id, "GET", null)
        .then((data) => {
          setClients(data.results);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const handleGetAllCompanies = () => {
    Api("companies", "GET", null)
      .then((data) => {
        setCompanies(data.results);
      })
      .catch((err) => console.log(err));
  };

  const getCompanyById = (id) => {
    setLoading(true);
    Api("actions/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteCompany = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("actions/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("client_successfully_deleted")}`);
        handleGetAllClients();
      })
      .catch(() => toastNotification("error", `${t("error_delete_client")}`));
  };

  const handleFilter = (filterValues) => {
    if (filterValues.company == undefined && filterValues.status == undefined) {
      setSearch(false);
      return;
    }
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
      Api(
        `actions/filter/filter-actions?company=${filterValues.company}&status=${
          filterValues.status
        }&pageSize=${100}&page=${0}`,
        "GET",
        null
      )
        .then((data) => {
          setSearch(true);
          setClients(data?.results);
        })
        .catch(() => toastNotification("error", `Error while filtering`));
    } else {
      Api(
        `actions/filter/filter-actions?company=${filterValues.company}&status=${
          filterValues.status
        }&pageSize=${100}&page=${0}`,
        "GET",
        null
      )
        .then((data) => {
          setSearch(true);
          setClients(data?.results);
        })
        .catch(() => toastNotification("error", `Error while filtering`));
    }
  };

  return (
    <div className="customers-container">
      <div className="header">
        <Filters
          companies={companies}
          handleFilter={handleFilter}
          handleGetAllClients={handleGetAllClients}
          search={search}
          setSearch={setSearch}
        />

        {permissionsFilter("Proceedings", "ProceedingCreate") && (
          <Button
            className="button"
            onClick={() => handleModal("create", true)}
          >
            {t("add_client")}
          </Button>
        )}

        <CreateClients
          onClose={handleModal}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllClients={handleGetAllClients}
        />

        <EditClient
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllClients={handleGetAllClients}
          getCompanyById={getCompanyById}
          onClose={handleModal}
          companies={clients}
          lockEditById={lockEditById}
          lock={lock}
        />
      </div>
      <ClientsTable
        clients={clients}
        getCompanyById={getCompanyById}
        deleteCompany={deleteCompany}
        loading={loading}
      />
    </div>
  );
};
export default ClientsContainer;
