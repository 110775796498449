import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Collapse,
  Input,
  Select,
  Popconfirm,
} from "antd";
import { toastNotification } from "../../../utils/toastNotification";

import { useTranslation } from "react-i18next";
import Api from "../../../api";

import Edit from "../../../public/images/dashboard/tables/edit.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/pages/_address-container.scss";

export const AddressForm = ({
  id,
  onClose,
  isVisible,
  data,
  addressData,
  getAddressData,
}) => {
  const { Panel } = Collapse;

  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [button, setButton] = useState(false);
  const [addressId, setId] = useState();

  // useEffect(() => {
  //   form.setFieldsValue(addressData[0]);
  // }, []);

  const editAddress = (formValues) => {
    Api("addresses/" + addressId, "PATCH", formValues)
      .then(() => {
        form.setFieldsValue(formValues);
        toastNotification("success", `${t("address_edited_successfully")}`);
        getAddressData();
        handleClick();
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("error_edit_address")}`);
      });
  };

  const deleteAddress = (id) => {
    Api("addresses/" + id, "DELETE")
      .then(() => {
        toastNotification("success", `${t("address_deleted_successfully")}`);
        getAddressData();
        handleClickDelete();
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("error_delete_address")}`);
      });
  };

  // useEffect(() => {
  //   getAddressData();
  //   // if (isVisible) {
  //   //   let response = { ...data };
  //   //   return form.setFieldsValue(response);
  //   // }
  // }, [isVisible]);

  const handleClick = (id) => {
    setId(id);
    setDisabled(!disabled);
    setButton(true);
  };

  const handleClickDelete = () => {
    setDisabled(true);
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  return (
    <div className="form-style" visible={isVisible}>
      <Collapse
        accordion
        className="site-collapse-custom-collapse collapse-style"
        bordered={false}
        onChange={(e) => {
          if (e) {
            form.setFieldsValue(addressData[e[0]]);
          }
        }}
        // defaultActiveKey={["0"]}
      >
        {addressData?.map((item, idx) => (
          <Panel
            header={`${item?.street}, ${item?.house_number}, ${item?.zip}, ${item?.city}, ${item?.country} `}
            key="1"
            className="panel-style-1"
            bordered
            key={idx}
          >
            <div className="fields-section" key={idx}>
              <Form
                form={form}
                id="address-form"
                layout="vertical"
                scrollToFirstError
                onFinish={editAddress}
                onClose={onReset}
              >
                <Row key={idx} className="edit-delete-style">
                  <Col span={12} className="edit-delete-style">
                    <Popconfirm
                      title={t("are_you_sure")}
                      className="delete-style"
                      onConfirm={() => deleteAddress(item?.id)}
                    >
                      <a>
                        <img src={Delete} alt="Delete" />
                      </a>{" "}
                    </Popconfirm>
                    <a onClick={() => handleClick(item?.id)}>
                      <img src={Edit} alt="Edit" />
                    </a>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="street"
                      label={t("street")}
                      rules={[
                        {
                          required: true,
                          message: `${t("enter_user_street")}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={item?.street}
                        key={idx}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="house_number"
                      label={t("house_number")}
                      rules={[
                        {
                          required: true,
                          message: `${t("enter_user_house_number")}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={item?.house_number}
                        key={idx}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="zip"
                      label={t("zip")}
                      rules={[
                        { required: true, message: `${t("enter_user_zip")}` },
                      ]}
                    >
                      <Input
                        placeholder={item?.zip}
                        key={idx}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="city"
                      label={t("city_name")}
                      rules={[
                        {
                          required: true,
                          message: `${t("enter_user_city")}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={item?.city}
                        key={idx}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="country"
                      label={t("country")}
                      rules={[
                        {
                          required: true,
                          message: `${t("enter_user_country")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder={item?.country}
                        key={idx}
                        disabled={disabled}
                        showSearch={true}
                        searchBy="label"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) === 0
                        }
                      >
                        <Option value="Deutschland">{t("germany")}</Option>
                        <Option value="Österreich">{t("austria")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {button ? (
                  <div className="button-style">
                    <Button
                      form="address-form"
                      className="button-1"
                      key="submit"
                      htmlType="submit"
                    >
                      {t("save")}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Form>
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
