import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.woff",
      fontStyle: "italic",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf",
      fontWeight: 400,
    },
  ],
});

export const styles = StyleSheet.create({
  page: { backgroundColor: "white", position: "relative" },
  image: {
    width: "35%",
    marginTop: "50px",
    marginLeft: "50%",
  },
  uppercaseText: {
    textTransform: "uppercase",
    letterSpacing: "6px",
    paddingTop: "110px",
    fontSize: "35px",
    color: "#364059",
    marginLeft: "20px",
    fontFamily: "Open Sans",
    fontWeight: 700,
  },
  firstSection: {
    paddingTop: "12px",
    marginLeft: "20px",
    fontSize: "20px",
    color: "#364059",
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  firstParagraph: {
    textTransform: "uppercase",
  },
  secondSection: {
    marginTop: "140px",
    marginLeft: "20px",
    backgroundColor: "#364059",
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "25px 10px 65px 10px",
    fontFamily: "Open Sans",
  },
  name: {
    fontSize: "22px",
    fontWeight: 700,
  },
  right: {
    fontSize: "17px",
    fontWeight: 400,
  },
  thirdSection: {
    marginLeft: "40px",
    marginTop: "50px",
    fontFamily: "Open Sans",
    fontSize: "17px",
  },
  bottomName: {
    fontWeight: 600,
  },
  bottomData: {
    fontWeight: 400,
  },
  firstView: {
    marginTop: "100px",
    marginLeft: "40px",
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: "5px",
    fontSize: "35px",
    color: "#364059",
    fontFamily: "Open Sans",
    fontWeight: 700,
  },
  subtitles: {
    paddingTop: "60px",
    fontSize: "22px",
    color: "#364059",
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  subtitle: {
    paddingBottom: "10px",
  },
  secondTitle: {
    fontSize: "22px",
    color: "#364059",
    paddingBottom: "30px",
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  tableView: {
    marginTop: "80px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  paragraph: {
    paddingBottom: "20px",
    fontSize: "14px",
    marginRight: "20px",
    color: "#000000",
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  paragraphName: {
    paddingBottom: "20px",
    fontSize: "14px",
    marginRight: "20px",
    color: "#000000",
    fontFamily: "Open Sans",
    fontWeight: 700,
  },
  paragraphBottom: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "12px",
    fontStyle: "italic",
  },
  footer: {
    paddingTop: "30px",
  },
  lastSection: {
    marginBottom: 0,
    top: "400px",
    right: "40px",
    textAlign: "right",
    fontFamily: "Open Sans",
    fontSize: "16px",
    color: "#000000",
  },
  lastSectionCompany: {
    fontWeight: 600,
  },
  lastSectionChild: {
    display: "flex",
    paddingBottom: "30px",
    fontWeight: 400,
  },
});
