import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Filters } from "../../../components/Drawer/Categories/Filters";
import { CreateCategories } from "../../../components/Drawer/Categories/CreateCategories";
import { EditCategory } from "../../../components/Drawer/Categories/EditCategory";
import { toastNotification } from "../../../utils/toastNotification";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import CategoriesTable from "../../../components/Tables/Categories";
import Api from "../../../api";
import "../../../public/sass/pages/_categories.scss";

const CategoriesContainer = () => {
  const { t, i18n } = useTranslation();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [editData, setData] = useState([]);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  const onSearch = (value) => console.log(value);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllCategories = () => {
    Api("categories", "GET", null)
      .then((data) => {
        setCategories(data.results);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getCategoryById = (id) => {
    setLoading(true);
    Api("categories/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const editCategory = (item) => {
    if (!id) return message.error("Id is missing!");
  };

  const deleteCategory = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("categories/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("category_successfully_deleted")}`);
        handleGetAllCategories();
      })
      .catch(() => toastNotification("error", `${"error_delete_category"}`));
  };

  const handleFilter = (filterValues) => {
    if (
      filterValues.category_type == undefined &&
      filterValues.status == undefined
    ) {
      setSearch(false);
      return;
    }
    Api(
      `categories/filter/categories?type=${filterValues.category_type}&status=${
        filterValues.status
      }&pageSize=${100}&page=${0}`,
      "GET",
      null
    )
      .then((data) => {
        setSearch(true);
        setCategories(data.results);
      })
      .catch(() => {
        toastNotification("error", `Error while filtering`);
      });
  };

  return (
    <div className="customers-container">
      <div className="header">
        <Filters
          {...onSearch}
          handleFilter={handleFilter}
          handleGetAllCategories={handleGetAllCategories}
          search={search}
          setSearch={setSearch}
        />

        {permissionsFilter("Categories", "CategoryCreate") && (
          <Button
            className="button"
            onClick={() => handleModal("create", true)}
          >
            {t("add_category")}
          </Button>
        )}

        <CreateCategories
          onClose={handleModal}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllCategories={handleGetAllCategories}
          categories={categories}
        />

        <EditCategory
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllCategories={handleGetAllCategories}
          onClose={handleModal}
          categories={categories}
        />
      </div>
      <CategoriesTable
        categories={categories}
        getCategoryById={getCategoryById}
        deleteCategory={deleteCategory}
        loading={loading}
      />
    </div>
  );
};
export default CategoriesContainer;
