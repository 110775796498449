import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from 'moment';
import { PdfFooterCalculation } from "./PdfFooterCalculation";

const BORDER_COLOR = "#F8F8F8";

const styles = StyleSheet.create({
  body: {
    padding: "15px 20px",
  },
  table: {
    fontSize: "10px",
    fontWeight: 600,
    paddingTop: "8.5px",
  },
  theader: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${BORDER_COLOR}`,
  },
  cellHeader: {
    fontSize: "8px",
    padding: "5px 5px",
    paddingBottom: "10px",
    color: '#3E5078',
    borderRight: `1px solid ${BORDER_COLOR}`,
  },
  t15: {
    width: "10%",
    textAlign: "left",
  },
  t10: {
    width: "4%",
    textAlign: "left",
  },
  t12: {
    width: "12%",
    textAlign: "left",
  },
  tbody: {
    marginBottom: "30px",
  },
  headerLine: {
    backgroundColor: "#F8F8F8",
    padding: "5px",
    fontSize: "10px",
    marginTop: "3px",
    border: `1px solid ${BORDER_COLOR}`,
    fontWeight: 600,
    color: '#1F2C4B',
  },
  tbodyCell: {
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${BORDER_COLOR}`,
    borderTop: 0,
  },
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
    // word-wrap:break-word;
  },
  textRight: {
    textAlign: "right",
  },
  footer: {
    fontSize: 12,
    color: "grey",
    margin: "15px 5px",
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 15,
  },
  headerLabel: {
    fontSize: "8px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  bgDarkBlue: {
    backgroundColor: '#3E5078',
    color: '#fff',
    borderColor: '#2B3C65'
  }
});
export const TablePage = ({ data }) => {
  return (
    <Page style={styles.body} orientation="landscape" wrap>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          lineHeight: 1.7,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: "12px",
          }}
        >
          <View style={{ color: '#364059' }}>
            <Text style={{ fontWeight: 700, marginRight: "30px" }}>
              Auftraggeber:{" "}
            </Text>
            <Text style={{ fontWeight: 700, marginRight: "30px" }}>
              Verfahren:{" "}
            </Text>
          </View>
          <View style={{ color: '#364059' }}>
            <Text>
              {`${data?.client?.name}, ${data?.client?.street} ${data?.client?.house_number}, ${data?.client?.zip} ${data?.client?.city}, ${data?.client?.country}`}
            </Text>
            <Text>
              {data?.addresses?.length &&
                `${data?.name}, ${data?.addresses[0].street} ${data?.addresses[0].house_number}, ${data?.addresses[0].zip} ${data?.addresses[0].city}, ${data?.addresses[0].country}`}
            </Text>
          </View>
        </View>
        <View>
          <Text style={{ fontSize: "10px", color: "gray" }}>Seite 2/66</Text>
        </View>
      </View>

      <View>
        {/* <Text style={{ fontSize: "10px", textAlign: "right", padding: "3px" }}>
          Sachaufnahme vom: {moment(data?.date_one || new Date())} bis {moment(data?.date_two || new Date())}
        </Text> */}
      </View>
      <View style={styles.table}>
        <View fixed style={styles.theader}>
          <View style={[styles.cellHeader, styles.t15, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Standort</Text>
          </View>
          <View style={[styles.cellHeader, styles.t10, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Kt. lt.AS</Text>
          </View>
          <View style={[styles.cellHeader, styles.t10, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Verm.</Text>
          </View>
          <View style={[styles.cellHeader, styles.t10, { width: '8%' }, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Pos</Text>
          </View>
          <View style={[styles.cellHeader, styles.t10, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Stck</Text>
          </View>
          <View style={[styles.cellHeader, styles.t15, { width: "12%" }, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Bezeichnung</Text>
          </View>
          <View style={[styles.cellHeader, styles.t15, { width: "17%" }, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Beschreibung</Text>
          </View>
          <View style={[styles.cellHeader, styles.t12, { width: "10%" }, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>Zerschlagungs- {" "} wert in €</Text>
          </View>
          <View style={[styles.cellHeader, styles.t12, styles.bgDarkBlue]}>
            <Text style={styles.headerLabel}>
              Verm. Aus- und Absonderungsrechte
            </Text>
          </View>
          <View style={[styles.cellHeader, styles.t15, { borderRight: 0 }, styles.bgDarkBlue, { width: "20%" }]}>
            <Text style={styles.headerLabel}>Bemerkung</Text>
          </View>
        </View>
        <View style={styles.headerLine}>
          <Text>
            {data?.company?.street} {data?.company?.house_number}{" "}
            {data?.company?.zip} {data?.company?.city} {data?.company?.country}
          </Text>
        </View>
        <View style={styles.tbody}>
          {data?.products?.map((item, idx) => (
            <View key={idx} style={styles.tbodyCell} wrap={false}>
              <View style={[styles.cellHeader, styles.t15]}>
                <Text>
                  {item?.product_location || ""}
                  {/* {item?.address?.street} {item?.address?.house_number}{" "}
                  {item?.address?.zip} {item?.address?.city}{" "}
                  {item?.address?.country} */}
                </Text>
              </View>
              <View style={[styles.cellHeader, styles.t10, styles.textLeft]}>
                <Text></Text>
              </View>
              <View style={[styles.cellHeader, styles.t10, styles.textLeft]}>
                <Text>{item?.asset_type || ""}</Text>
              </View>
              <View style={[styles.cellHeader, styles.t10, styles.textCenter, , { width: '8%' }]}>
                <Text>{item?.product_id.replace("0000000", "00")}</Text>
              </View>
              <View style={[styles.cellHeader, styles.t10, styles.textCenter]}>
                <Text>{item?.quantity || 0}</Text>
              </View>
              <View style={[styles.cellHeader, styles.t15, styles.textLeft , { width: "12%" }]}>
                <Text>{item?.name || ""}</Text>
              </View>
              <View style={[styles.cellHeader, styles.t15, styles.textLeft, { width: "17%" }]}>
                <Text>{item?.short_description || ""}</Text>
              </View>
              <View style={[styles.cellHeader, styles.t12, styles.textRight, { width: "10%" }]}>
                <Text>{parseInt(item?.breakdown_value)}</Text>
              </View>
              <View style={[styles.cellHeader, styles.t12, styles.textLeft]}>
                <Text>{item?.rights}</Text>
              </View>
              <View
                style={[
                  styles.cellHeader,
                  styles.t15,
                  { borderRight: 0 },
                  styles.textLeft,
                  { width: "20%" }
                ]}
              >
                <Text>{item?.comment}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <PdfFooterCalculation />
    </Page>
  );
};
