import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import { AddressContainer } from "../../../containers/Dashboard/Addresses/AddressContainer";
import Api from "../../../api";
import Edit from "../../../public/images/dashboard/tables/edit.svg";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import "../../../public/sass/components/drawer/_tabs.scss";

const { Option } = Select;

export const EditUser = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllUsers,
}) => {
  const [form] = Form.useForm();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [allCompanies, setCompanies] = useState([]);
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const [isEdit, setEdit] = useState(false);
  const [usersRoles, setUsersRoles] = useState();


  useEffect(() => {
    getAllRolesForUsers()
    getAllCompanies()
  }, []);

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      response.company_id = data?.company?.id;
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("users/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", `${t("successfully_edited_user")}`);
        handleGetAllUsers();
        onClose();
        setEdit(false);
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_edit_user")}`)
      );
  };

  const getAllRolesForUsers = () => {
    Api('roles/get/user-roles', 'GET', null)
      .then((data) => {
        setUsersRoles(data.data);
      })
      .catch((err) => {
        toastNotification('error', err.error.message || 'error');
      });
  };

  const getAllCompanies = () => {
    setLoadingCompanies(true);
    Api("companies", "GET", null)
      .then((data) => {
        setCompanies(data.results);
        setLoadingCompanies(false);
      })
      .catch(() => setLoadingCompanies(false));
  };

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("edit_user")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button disabled={!isEdit} onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            disabled={!isEdit}
            type="primary"
            form="users-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Tabs
        defaultActiveKey="1"
        className="tab-style"
      >
        <TabPane tab={t("address")} key="1" className="tab-pane-style">
          <Form
            form={form}
            validateMessages={validateMessages}
            id="users-form"
            layout="vertical"
            scrollToFirstError
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={22}></Col>
              <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
                <a onClick={() => setEdit(true)}>
                  <img src={Edit} alt="Edit" />
                </a>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="role"
                  label={t("role")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                  >
                    {usersRoles?.map((item, idx) => {
                      return (
                        <Option key={idx} value={item.id}>{item.name}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="user_id"
                  label={t("user_number")}
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstname"
                  label={t("firstname")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastname"
                  label={t("lastname")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={t("phone")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label={t("email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label={t("street")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="house_number"
                  label={t("house_number")}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      max: 5,
                    },
                  ]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="zip"
                  label={t("zip")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="city"
                  label={t("city")}
                  rules={[{ required: true }]}
                >
                  <Input disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label={t("country")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                  >
                    <Option value="Deutschland">{t("germany")}</Option>
                    <Option value="Österreich">{t("austria")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                  >
                    <Option value={1}>{t("active")}</Option>
                    <Option value={0}>{t("not_active")}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="company_id"
                  label={t("company")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch={true}
                    searchBy="label"
                    optionFilterProp="children"
                    disabled={!isEdit}
                  >
                    {allCompanies.map((item, idx) => (
                      <Option key={idx} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={t("addresses")} key="2" className="tab-pane-style">
          <AddressContainer onClose={onClose} id={id} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
