import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import moment from "moment";
import ApiConstants from "../../../../../api/ApiConstants";

const styles = StyleSheet.create({
  page: { padding: "20px" },
  image: { width: 170, height: 170 },
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
  },
  imageBox: { margin: 10, width: 170, height: 170, textAlign: 'center' },
  labelName: { paddingTop: 10, fontSize: '12px' }
});
export const ListImages = ({ data }) => {
  if(!data?.products.length) return null;
  return (
    <Page
      wrap
      size={"A4"}
      orientation="landscape"
      style={{
        backgroundColor: "#fff",
        padding: "40px",
        fontFamily: "Poppins",
      }}
    >
      <PdfHeader imgUrl="https://i.imgur.com/BUQFkU3.jpg" />
      <View style={styles.imageWrapper}>
        {data?.products?.map((i) => {
          const imgParse = JSON.parse(i?.images);
          return (
            <View key={i?.inventar_id} style={styles.imageBox}>
              <Image
                style={styles.image}
                src={imgParse.length ? ApiConstants.IMAGE_URL + imgParse[0] : "https://i.imgur.com/FHlMHWx.png"}
              />
               <Text style={styles.labelName}>{i?.inventar_id}</Text>
            </View>
          );
        })}
      </View>
    </Page>
  );
};
