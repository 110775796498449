import React, { useState } from "react";
import { Tabs } from "antd";
import { CreateForm } from "./CreateForm";
import { PositionsContainer } from "./PositionsContainer";

import { useTranslation } from "react-i18next";
import "../../../public/sass/components/drawer/_tabs.scss";
import ShowInvoice from "./ShowInvoice";

export const RequestTabs = ({
  onClose,
  id,
  data,
  isVisible,
  getOpenRequests,
}) => {
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;



  return (
    <Tabs
      defaultActiveKey="1"
      className="tab-style"
    >
      <TabPane tab={t("details")} key="1" className="tab-pane-style">
        <CreateForm
          onClose={onClose}
          id={id}
          visible={isVisible}
          data={data}
          getOpenRequests={getOpenRequests}
        />
      </TabPane>
      {/* <TabPane tab={t("positions")} key="3" className="tab-pane-style">
        <PositionsContainer onClose={onClose} />
      </TabPane> */}
    </Tabs>
  );
};
