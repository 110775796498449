import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";

const { Option } = Select;
const { TextArea } = Input;

export const CreateCategories = ({
  isVisible,
  onClose,
  handleGetAllCategories,
  categories,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [category, setCategory] = useState(null);

  const handleSubmit = (formValues) => {
    if(formValues.type.includes('Subcategory')){
      formValues.sub_category_id = null;
    }
    Api("categories", "POST", formValues)
      .then(() => {
        toastNotification("success", `${t("category_successfully_created")}`);
        handleGetAllCategories();
        form.resetFields();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_create_category")}`)
      );
    // onClose();
  };

  const onReset = () => {
    onClose();
    form.resetFields();
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Drawer
      title={t("create_new_category")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="users-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="users-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          {/* <Col span={12}>
            <Form.Item
              name="category_id"
              label={t("category_number")}
              rules={[{ required: true, message: `${t("category_number")}` }]}
            >
              <Input placeholder={t("category_number")} type="number" />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item
              name="name"
              label={t("category_name")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              name="position"
              label={"Category Position"}
              rules={[
                { required: true, message: `${t("Kategorieposition eingeben")}` },
              ]}
            >
              <Input placeholder={t("Kategorieposition eingeben")} />

            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="type"
              label={t("category_type")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                }
                onChange={(e) => {
                  setCategory(e);
                }}
              >
                <Option value="Category">{t("category")}</Option>
                <Option value="Subcategory">{t("subcategory")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label={t("category_description")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="sub_category_id" label={t("subcategory_from")}>
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                disabled={category != 'Subcategory'}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                }
              >
                {categories?.map((item, idx) => (
                  <Option key={idx} value={item?.id}>
                    {category == 'Subcategory' && item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={true}
                searchBy="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                }
              >
                <Option value={1}>{t("active")}</Option>
                <Option value={0}>{t("not_active")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
