const URL =
  !process.env.NODE_ENV || process.env.NODE_ENV == "development"
    ? "http://localhost:5000/api/"
    : "https://c-1963.maxcluster.net/backend/api/";

const HEIGHT = 500;

const ApiConstants = {
  TOKEN: localStorage.getItem("kgen-token"),
  BASE_URL: URL,
  IMAGE_URL: "https://c-1963.maxcluster.net/backend/images/",
  FILE_URL: "https://c-1963.maxcluster.net/backend/files/",
  TABLE_HEIGHT: "auto",
};

export default ApiConstants;
