const deLanguage = {
  login: "Anmeldung",
  email_is_required: "E-Mail ist erforderlich",
  password_is_required: "Passwort ist erforderlich",
  invalid_email: "Die Eingabe ist nicht gültig E-Mail!",
  input_email: "Bitte geben Sie Ihre E-Mail!",
  input_password: "Bitte geben Sie Ihr Passwort!",
  input_confirm_password: "Bitte bestätigen Sie Ihr Passwort!",
  welcome: "Willkommen",
  back: "zurück",
  password: "Kenwort",
  submit: "Speichern",
  register: "Registrieren",
  confirm_password: "Kennwort bestätigen",
  remember_me: "Kennwort speichern",
  forgot_password: "Kennwort vergessen",
  not_registered_yet: "Noch nicht registriert",
  register_here: "Hier entlang",
  reset_password: "Passwort zurücksetzen",
  old_password: "Altes Passwort",
  new_password: "Neues Passwort",
  change_password: "Passwort ändern",
  back_to_login: "Zurück zur Anmeldung",
  procedure: "Verfahren",
  client: "Verfahren",
  property: "Sachanlagen",
  add_deals: "Deals hinzufügen",
  add_client: "Verfahren hinzufügen",
  add_category: "Sachanlage hinzufügen",
  logout: "Abmelden",
  home: "Startseite",
  report: "Gutachten",
  company: "Lizenzinhaber",
  admin: "Verwalter",
  search: "Suchen",
  cancel: "Abbrechen",
  name: "Name",
  enter_user_name: "Bitte geben sie einen Benutzernamen ein",
  enter_url: "Bitte geben Sie die URL ein",
  owner: "Eigentümer",
  select_owner: "Bitte wählen Sie einen Eigentümer",
  type: "Art",
  choose_type: "Bitte wählen Sie den Typ",
  approver: "Genehmiger",
  choose_approver: "Bitte wählen Sie den Genehmigenden",
  start_date: "Anfangsdatum",
  description: "Beschreibung",
  enter_description: "Bitte geben Sie die URL-Beschreibung ein",
  tel: "Telefon",
  enter_tel: "Bitte geben Sie die Telefonnummer ein",
  customer: "Auftraggeber",
  customer_kunde:"Kunde",
  choose_customer: "Auftraggeber wählen",
  action: "Aktion",
  add_property: "Sachanlage hinzufügen",
  add_user: "Benutzer hinzufügen",
  view_results: "Ergebnisse anzeigen",
  new_property: "Neue Sachanlagen",
  property_type: "Güterart",
  value: "Wert",
  designation: "Bezeichnung",
  position: "Positionen",
  enter_number: "Bitte geben Sie die Nummer ein",
  enter_property: "Bitte geben Sie die Sachanlage ein",
  enter_property_type: "Bitte geben Sie den Eigenschaftstyp ein",
  enter_value: "Bitte geben Sie den Wert ein",
  enter_status: "Bitte geben Sie den Status ein",
  enter_designation: "Bitte geben Sie die Bezeichnung ein",
  enter_position: "Bitte geben Sie die Position ein",
  upload_image: "Bild hochladen",
  upload_files: "Dokumente hochladen",
  upload_files_actions: "Dokument bzgl. Verfahren",
  upload: "Dokument auswählen",

  add_company: "Lizenz hinzufügen",
  email: "E-Mail",
  phone: "Telefonnummer",
  enter_company_name: "Geben Sie den Firmennamen ein",
  company_name:"ANSCHRIFT",
  company_type: "Firmenart",
  choose_company_type: "Bitte wählen Sie den Firmentyp",
  owner_firstname: "Ansprechpartner-Vorname",
  enter_owner_firstname: "Geben Sie Ansprechpartner Vorname",
  owner_lastname: "Ansprechpartner-Nachname",
  enter_owner_lastname: "Geben Sie Ansprechpartner Nachname",
  enter_user_email: "Bitte Benutzer-E-Mail eingeben",
  enter_user_phone: "Bitte geben Sie die Telefonnummer",
  country: "Land",
  enter_user_country: "Bitte geben Sie Land",
  city: "Stadt",
  enter_user_city: "Bitte geben Sie Stadt",
  street: "Straße",
  enter_user_street: "Bitte geben Sie Straße",
  house_number: "Hausnummer",
  enter_user_house_number: "Hausnummer eingeben",
  zip: "PLZ",
  enter_user_zip: "Bitte geben Sie die Postleitzahl ein",
  hrb_number: "HRB/HRA-Nummer",
  enter_company_hrb_number: "Bitte geben Sie die HRB/HRA-Nummer ein",
  ustid_number: "USTID-Nummer",
  enter_company_ustid_number: "Bitte geben Sie die USTID-Nummer ein",
  tax_number: "Steuernummer",
  select:"Bitte wählen",
  enter_company_tax_number: "Bitte geben Sie die Steuernummer",
  role: "Begriff",
  add_role: "Rolle hinzufügen",
  create_new_role: "Rolle erstellen",
  edit_role: "Edit Role",
  choose_user_role: "Bitte wählen Benutzerrolle",
  firstname: "Vorname",
  lastname: "Nachname",
  enter_user_firstname: "Bitte geben Sie Vorname",
  enter_user_lastname: "Bitte geben Sie Nachname",
  choose_user_company: "Bitte wählen Sie Firma",
  user_id_required: "Benutzer-ID ist erforderlich",

  my_profile: "Mein Profil",
  create_new_company: "Neue Lizenz erstellen",
  create_new_client: "Neues Verfahren erstellen",
  are_you_sure: "Sind Sie sicher?",
  active: "Aktiv",
  not_active: "Nicht aktiv",
  submit_2: "Anmelden",
  choose_user_status: "Benutzerstatus wählen",
  not_accepted: "Nicht akzeptiert",
  nicht_akzeptiert: "Nicht akzeptiert",
  in_bearbeitung: "In Bearbeitung",

  image: "Bilder hochladen",
  inventory_number: "Positionsnummer",
  enter_inventory_number: "Bitte geben Sie Inventarnummer",
  product_name: "Positionsname",
  enter_product_name: "Bitte Postionname eingeben",
  short_description: "Kurzbeschreibung",
  enter_short_description: "Bitte kurze Beschreibung eingeben",
  category: "Kategorie",
  enter_category: "Bitte Kategorie eingeben",
  quantity: "Menge",
  enter_quantity: "Bitte Menge eingeben",
  packaging_unit: "Verpackungseinheit",
  enter_packaging_unit: "Bitte Verpackungseinheit eingeben",
  qty_packaging_unit: "Menge je VE",
  enter_qty_packaging_unit: "Bitte Menge der VE eingeben",
  assessment_type: "Art des Gutachtens",
  enter_assessment_type: "Bitte Bewertungsart eingeben",
  breakdown_value: "Zerschlagungswert",
  enter_breakdown_value: "Bitte geben Sie den Zerschlagungswert ein",
  premium_percent: "Aufgeldhöhe",
  enter_premium_percent: "Bitte geben Sie den Aufgeldhöhe ein",
  premium: "Aufgeld",
  enter_premium: "Bitte Aufgeld eingeben",
  breakdown_premium: "Zerschlagungswert + Aufgeld",
  enter_breakdown_premium: "Bitte Zerschlagungswert & Aufgeld eingeben",
  subtotal: "Zwischensumme",
  enter_subtotal: "Bitte Zwischensumme eingeben",
  tax_type: "MwSt. - Art",
  enter_tax_type: "Bitte geben Sie MwSt. - Art",
  tax_costs: "MwSt.",
  enter_tax_costs: "Bitte geben Sie MwSt.",
  total_incl_tax: "Gesamtwert inkl. MwSt.",
  enter_total_incl_tax: "Bitte geben Sie Gesamtwert inkl. MwSt.",
  going_concern_value: "Fortführungswert",
  enter_going_concern_value: "Bitte geben Sie Fortführungswert",
  market_value: "Marktwert",
  enter_market_value: "Bitte geben Sie Marktwert",
  comment: "Bemerkung",
  enter_comment: "Bitte geben Sie Bemerkung",
  asset_type: "Vermögensart",
  enter_asset_type: "Bitte geben Sie Vermögensart",
  address_from_product: "Standort des Artikels",
  enter_address_from_product: "Bitte geben Sie Standort des Artikels",
  location_from_product: "Platz des Artikels",
  enter_location_from_product: "Bitte geben Sie Platz des Artikels",
  created_at: "Erstellungsdatum",

  new_deal: "Neuer Deal",
  enter_admin: "Verwalter-Name eingeben",
  edit_company: "Lizenz bearbeiten",
  city_name: "Ort",
  enter_city_name: "Bitte geben Sie Name der Ort",
  company_number: "Drittrechtenummer",
  enter_company_number: "Bitte Firmennummer eingeben",
  more_addresses: "Adresse hinzufügen",
  more_products: "Position hinzufügen",
  edit_user: "Benutzer bearbeiten",
  user_number: "Benutzernummer",
  edit_client: "Verfahren bearbeiten",
  product_number: "Interne Nummer",
  mileage: "Laufleistung",
  type_of_mileage: "Typ der Laufleistung",
  approved: "Bestätigung",
  accepted: "Akzeptiert",
  other: "Sonstige",
  rights: "Aus- und Absonderungsrechte",
  segregation_rights: "Absonderungsrechte",
  client_number: "Verfahrennummer",
  from_customer: "Vom Kunden",
  enter_customer: "Bitte Kunde eingeben",
  type_of_procedure: "Verfahrensart",
  enter_type_of_procedure: "Bitte geben Sie die Art des Verfahrens ein",
  disposal_costs: "Entsorgungskosten",
  enter_disposal_costs: "Bitte Entsorgungskosten eintragen",
  category_type: "Sachanlage-Typ",
  enter_category_type: "Bitte Kategorietyp eingeben",
  category_number: "Sachanlage-Nummer",
  enter_category_name: "Kategorienamen eingeben",
  product_order_successful: "Produktbestellung erfolgreich aktualisiert.",
  category_description: "Sachanlage-Beschreibung",
  enter_category_description: "Bitte Sachanlage schreibung eingeben",
  subcategory_from: "Sub-Sachanlage von",
  choose_subcategory: "Subkategorie wählen",
  choose_client: "Mandant wählen",
  enter_mileage: "Laufleistung eingeben",
  enter_type_of_mileage: "Bitte geben Sie den Laufleistung ein",
  enter_other: "Bitte geben sie sonstiges",
  select_rights: "Aus- und Absonderungsrechte wählen",
  select_rights_1: "Absonderungsrechte wählen",
  save: "Speichern",
  edit_category: "Kategorie bearbeiten",
  create_new_category: "Neue Sachanlage erstellen",
  edit_customer: "Auftraggeber bearbeiten",
  edit_customer_request:"Kunde bearbeiten",
  customer_number: "Auftraggebernummer",
  enter_customer_name: "Bitte geben Sie den Kundennamen ein",
  add_customer: "Auftraggeber hinzufügen",
  add_customer_client:"Kunde hinzufügen",
  third_party: "Drittrechte",
  choose_third_party: "Vertragspartner wählen",
  subcategory: "Sub-Kategorie",
  category_name: "Sachanlage-Name",
  piece: "Stück",
  litre: "Liter",
  assessment: "Gutachten",
  desktop_assessment: "Desktopbewertung",
  create_new_user: "Neuen Benutzer erstellen",
  create_new_customer: "Neuen Auftraggeber erstellen",
  create_new_customer_request:"Neuen Kunden hinzufügen",
  create_new_position: "Neue Position erstellen",
  edit_position: "Position bearbeiten",
  add_position: "Position hinzufügen",
  no_positions_found: "Keine Positionen gefunden!",
  create_new_word: "Neues begriff erstellen",
  edit_word: "Wort bearbeiten",
  add_word: "Begriff hinzufügen",
  no_words_found: "Keine Worter gefunden",
  product_status: "Produktstatus",
  word: "Begriff",

  add_third_party: "Drittrechte hinzufügen",
  create_new_third_party: "Drittrechte neu erstellen",
  edit_third_party: "Drittrechte bearbeiten",
  add_open_request: "Anfrage hinzufügen",
  address: "Anschrift",
  new_address: "Neue Adresse",
  addresses: "Weitere Standorte",
  add_new_address: "Weiteren Standort hinzufügen",
  user_profile: "Benutzerprofil",
  password_2: "Passwort",
  search_product: "Position suchen",
  search_by_name: "Nach Namen suchen",
  search_by_name_role:"Nach Rolle suchen",
  edit_role_form:"Begriff",
  password_successfully_changed: "Passwort erfolgreich geändert",
  please_login_with_the_new_password:
    "Bitte melden Sie sich mit dem neuen Passwort an",
  success: "Akzeptiert",

  Database: "Auswertungen",
  Database2:"Interne Datenbank",
  databasetwo: "Datenbank",

  Positions: "Positionen",
  Users: "Benutzer",
  Companies: "Lizenzen",
  Assets: "Sachanlagen",
  Open: "Offen",
  Done: "Erledigt",
  Proceedings: "Verfahren",
  Client: "Auftraggeber",
  Words: "Begriffe",
  ClientAnfragen: "Kunde",
  Requests: "Anfragen",
  Administration: "Administration",
  Report: "Gutachten",
  database: "Datenbank",
  Roles: "Rollenverwaltung",
  Worter: "Begriffe",
  create_role:"Begriff",
  Evaluations:"Auswertungen",

  //toastNotification
  address_edited_successfully: "Adresse erfolgreich bearbeitet!",
  error_edit_address: "Fehler während der Bearbeitung!",
  address_deleted_successfully: "Adresse wurde erfolgreich gelöscht!",
  error_delete_address: "Fehler beim Löschen!",
  user_created_successfully: "Benutzer wurde erfolgreich erstellt!",
  error_create_user: "Fehler beim Erstellen!",
  category_edited_successfully: "Kategorie erfolgreich bearbeitet!",
  error_edit_category: "Fehler beim Bearbeiten!",
  address_created_successfully: "Adresse erfolgreich erstellt!",
  error_create_address: "Fehler beim Erstellen!",
  product_created_successfully: "Postion erfolgreich erstellt!",
  error_create_products: "Fehler beim Erstellen!",
  word_created_successfully: "Wort erfolgreich erstellt!",
  error_create_words: "Fehler beim Erstellen!",
  successfully_edited_client: "Kunde erfolgreich bearbeitet!",
  error_edit_client: "Fehler beim Bearbeiten!",
  successfully_created_company: "Firma erfolgreich erstellt!",
  error_create_company: "Fehler beim Erstellen!",
  successfully_edited_company: "Firma erfolgreich bearbeitet!",
  error_edit_company: "Fehler beim Bearbeiten!",
  customer_created_successfully: "Kunde erfolgreich erstellt!",
  error_create_customer: "Fehler beim Anlegen!",
  customer_edited_successfully: "Kunde erfolgreich bearbeitet!",
  error_edit_customer: "Fehler beim Bearbeiten!",
  product_successfully_edited: "Postion erfolgreich bearbeitet!",
  error_edit_product: "Fehler beim Bearbeiten!",
  word_successfully_edited: "Wort erfolgreich bearbeitet!",
  word_successfully_deleted: "Wort erfolgreich gelöscht",
  error_edit_word: "Fehler beim Bearbeiten!",
  successfully_created_third_party: "Drittrecht erfolgreich erstellt!",
  error_create_third_party: "Fehler beim Erstellen!",
  successfully_edited_third_party: "Erfolgreich bearbeitet von Drittrechte!",
  error_edit_third_party: "Fehler beim Bearbeiten!",
  successfully_edited_user: "Benutzer erfolgreich bearbeitet!",
  error_edit_user: "Fehler beim Bearbeiten!",
  password_successfully_changed: "Passwort erfolgreich geändert!",
  error_change_password: "Fehler beim Ändern!",
  error_change_password_repeat: "Passwörter stimmen nicht überein!",
  error_send_mail: "Fehler beim Senden von E-Mail!",
  email_sent: "E-Mail gesendet!",
  category_successfully_created: "Kategorie erfolgreich erstellt!",
  error_create_category: "Fehler beim Erstellen der Kategorie!",
  login_with_new_password: "Bitte mit neuem Passwort einloggen!",
  something_went_wrong: "Etwas ist schief gelaufen!",
  category_successfully_deleted: "Kategorie wurde erfolgreich gelöscht!",
  error_delete_category: "Fehler beim Löschen!",
  client_successfully_deleted: "Kunde erfolgreich gelöscht!",
  error_delete_client: "Fehler beim Löschen!",
  company_successfully_deleted: "Firma wurde erfolgreich gelöscht!",
  error_delete_company: "Fehler beim Löschen!",
  customer_successfully_deleted: "Kunde wurde erfolgreich gelöscht!",
  error_delete_customer: "Fehler beim Löschen!",
  product_successfully_deleted: "Postion erfolgreich gelöscht!",
  error_delete_product: "Fehler beim Löschen!",
  third_party_successfully_deleted: "Drittrechte erfolgreich gelöscht!",
  error_delete_third_party: "Fehler beim Löschen Drittrechte!",
  user_successfully_deleted: "Benutzer erfolgreich gelöscht!",
  error_delete_user: "Fehler beim Löschen von Benutzern",
  role_successfully_created: "Role successfully created!",
  role_successfully_deleted: "Role successfully deleted!",
  error_delete_role: "Error while deleting role!",
  error_create_role: "Error while creating role!",

  austria: "Österreich",
  germany: "Deutschland",

  disposal: "Aussonderung",
  disposal_rights: "Aussonderungsrechte",
  disposal_e: "Entsorgung",
  no_comment: "Keine Bemerkung",
  fixed_installation: "Festeinbau",
  real_estate_adapted: "Immobilienangepasst",
  without_rating: "Ohne Bewertung",
  not_visited: "Nicht Besichtigt",
  free_mass: "Freie Masse",
  segregation: "Absonderung",
  foreign_property: "Fremdeigentum",
  upload_excel: "Excel-Datei hochladen",
  simple_retention_of_title: "Einfacher Eigentumsvorbehalt",

  new_request: "Neue Anfrage",
  details: "Details",
  positions: "Positionen",
  rental_fee: "Miete",
  hire_purchase: "Mietkauf",
  other_option: "Sonstiges (mit Möglichkeit händischer Eingabe)",
  financing_loan_protection: "Finanzierung/Darlehenssicherung",
  transfer_by_security_way: "Sicherungsübereignung",
  area_security_transfer: "Raumsicherungsübereignung",
  landlords_lien: "Vermieterpfandrecht",
  extended_title_reservation: "Erweiterter/verlängerter Eigentumsvorbehalt",
  add_procedure: "Verfahren hinzufügen",

  in_progress: "In Bearbeitung",
  done: "Abgeschlossen",
  download_csv: "CSV-Datei herunterladen",
  choose_type_of_procedure: "Verfahrensart wählen",
  download_pdf: "PDF Herunterladen",
  download: "Beispieldatei",
  nicht_aktiv: "Nicht Aktiv",

  user_limit: "Benutzerlimit",
  enter_user_limit: "Benutzerlimit eingeben",
  user: "Benutzer",
  invoice: "Rechnung",
  send_email: "Mail senden",

  permissions: "Möglichkeiten",
  assets: "Sachaufnahme",
  field_required: "Dies ist ein Pflichtfeld!",

  status_accepted: "Akzeptiert",
  status_not_accepted: "Nicht akzeptiert",
  status_in_progress: "In Bearbeitung",
  status_done: "Abgeschlossen",
  status_not_active: "Nicht aktiv",
  click_to_upload: "Bild auswählen",
  click_sort: "Sortierung ändern",

  specifications: "Gutachter - Spezifikationen",
  document_info: "Bemerkungen zum Gutachten",
  material_records_info: "Bemerkung zur Sachaufnahme",
  date_one: "Start",
  date_two: "Abschluss",

  open_new_tab: "In neuem Tab öffnen",
  share_docs: "Dokument teilen",
  enter_message: "Nachricht",
  close: "Schließen",
  clear_filter: "Filter löschen",
  user_qty: "Usermenge",
  words: "Begriffe",
  permissions_role: "Berechtigungen",

  contract: "Vertrag",
  appriauser_type: "Gutachter typ",
  uploadfiles: "Dokumente hochladen",
  uploadImage: "Dokument auswählen",
  role: "Begriff",
  reset: "Zurücksetzen",
  product_order_message: "Produktreihenfolge erfolgreich festgelegt",
  set: "Festlegen",
  Products: "Produkte",
  Categories: "Kategorien",
  "Request Clients": "Kunden anfragen",
  "Open Requests": "Offene Anfragen",
  "Finished Requests": "Erledigte Anfragen",
  "Report Clients": "Bericht Kunden",
  "Third Party Rights": "Drittrechte",
  "Database 1": "Datenbank 1",
  "Database 2": "Datenbank 2",
  "Data Creator": "Datenersteller",
};
export default deLanguage;
