import React from "react";
import { Form, Card, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

import logo from "../../public/images/login/login_logo.svg";
import bg from "../../public/images/login/bg.png";
import "../../public/sass/pages/_change-password.scss";
import { Link } from "react-router-dom";

import { toastNotification } from "../../utils/toastNotification";
import Api from "../../api";

const ChangePassword = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const onFinish = (formValues) => {
    Api("auth/change-password", "POST", formValues)
      .then((response) => {
        toastNotification("success", " Passwort erfolgreich geändert!");
        props.history.push("/dashboard");
      })
      .catch((err) =>
        toastNotification("error", err.message || "Error while creating user")
      );
  };

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return (
    <div
      className="change-password-container"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="content">
        <Card className="card">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <p className="title">{t("reset_password")}</p>
          <Form
            name="inline"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: `${t("input_password")}`,
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t("old_password")} />
            </Form.Item>

            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message: `${t("input_password")}`,
                },
                () => ({
                  validator(_, value) {
                    if (!strongRegex.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Min. 8 Zeichen groß, klein, Zahl & Sonderzeichen (!@#$%^&*)"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t("new_password")} />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t("input_confirm_password")}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        t('error_change_password_repeat')
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("confirm_password")} />
            </Form.Item>

            <Form.Item>
              <Button
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                {t("change_password")}
              </Button>
              <div className="login-text">
                <Link to="/login">
                  {" "}
                  <a>{t("back_to_login")}</a>
                </Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ChangePassword;
