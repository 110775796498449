import React from "react";
import { Drawer, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { RequestTabs } from "./Tabs";

import "../../../public/sass/components/drawer/_createDrawers.scss";

export const CreateRequest = ({ isVisible, onClose, getOpenRequests }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Drawer
      title={t("new_request")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      destroyOnClose
      className="body-style"
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="requests-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <RequestTabs
        onClose={onClose}
        visible={isVisible}
        getOpenRequests={getOpenRequests}
      />
    </Drawer>
  );
};
