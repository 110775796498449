import React from "react";
import { Table, Divider, Space, Popconfirm, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ApiConstants from "../../../api/ApiConstants";
import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/layout/_index.scss";

const WorterTable = ({
  words,
  loading,
  getWordById,
  deleteWord,
}) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: `${t("words")}`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Aktion",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          {permissionsFilter('Words', 'WordUpdate') &&
            <a onClick={() => getWordById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
          }
          {permissionsFilter('Words', 'WordDelete') &&
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteWord(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        showSorterTooltip={{ title: t("click_sort") }}
        loading={loading}
        columns={columns}
        dataSource={words}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
      <Modal
        width={350}
        style={{ textAlign: "center" }}
        title=""
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        </div>
      </Modal>
    </div>
  );
};
export default WorterTable;
