import React from "react";
import { Table, Space, Divider, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ApiConstants from "../../../api/ApiConstants";
import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/layout/_index.scss";

const CompaniesTable = ({
  companies,
  loading,
  getCompanyById,
  deleteCompany,
}) => {
  const [t, i18n] = useTranslation();

  const columns = [
    {
      title: "Nr",
      dataIndex: "company_id",
      key: "company_id",
      render: (company_id) => <>G{company_id}</>,
    },
    {
      title: "Firma",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Vorname",
      dataIndex: "owner_firstname",
      key: "owner_firstname",
      sorter: (a, b) => a.owner_firstname.length - b.owner_firstname.length,
    },
    {
      title: "Nachname",
      dataIndex: "owner_lastname",
      key: "owner_lastname",
      sorter: (a, b) => a.owner_lastname.length - b.owner_lastname.length,
    },
    {
      title: "Addresse",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Mail",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Tel",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (status) => (
        <>
          <a style={{ color: status == 1 ? "#09CE87" : "#F5222D" }}>
            {status == 1 ? `${t("active")}` : `${t("not_active")}`}
          </a>
        </>
      ),
    },
    {
      title: "Aktion",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          {permissionsFilter('Companies', 'CompanyUpdate') &&
            <a onClick={() => getCompanyById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
          }
          {permissionsFilter('Companies', 'CompanyDelete') &&
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteCompany(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={companies}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
    </div>
  );
};
export default CompaniesTable;
