import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";
import Edit from "../../../public/images/dashboard/tables/edit.svg";
import treeData from "../../../utils/permissions.json";

import "../../../public/sass/components/drawer/_createDrawers.scss";

const { SHOW_PARENT } = TreeSelect;

export const EditRole = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllRoles,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState(["0-0-0"]);

  useEffect(() => {
    let response = { ...data.data };
    return form.setFieldsValue(response);
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("roles/" + id, "PUT", formValues)
      .then(() => {
        toastNotification("success", `${t("successfully_edited_user")}`);
        handleGetAllRoles();
        onClose();
        setEdit(false);
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_edit_user")}`)
      );
  };

  const onReset = () => {
    onClose();
    form.resetFields();
    setEdit(false);
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  const onChange = (value) => {
    setValue(value);
  };

  const tProps = {
    treeData,
    value: value,
    onChange: onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: `${t("select")}`,
    style: {
      width: "100%",
    },
  };

  return (
    <Drawer
      title={t("edit_role")}
      width={720}
      onClose={onReset}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button disabled={!isEdit} onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            disabled={!isEdit}
            type="primary"
            form="roles-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        validateMessages={validateMessages}
        id="roles-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={22}></Col>
          <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
            <a onClick={() => setEdit(true)}>
              <img src={Edit} alt="Edit" />
            </a>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("edit_role_form")}
              rules={[{ required: true }]}
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className="roles-permissions"
              name="permissions"
              label={t("permissions_role")}
              rules={[{ required: true }]}
            >
              <TreeSelect {...tProps} disabled={!isEdit} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
