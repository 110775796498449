import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Button, message, Row, Col} from "antd";
import {Filters} from "../../../components/Drawer/Positions/Filter";
import {CreatePositions} from "../../../components/Drawer/Positions/CreatePositions";
import {EditPosition} from "../../../components/Drawer/Positions/EditPosition";
import {toastNotification} from "../../../utils/toastNotification";
import {SearchBy} from "../../../components/Tables/Database/SearchBy";
import {permissionsFilter} from "../../../utils/permissionsFilter";
import Api from "../../../api";
import PositionsTable from "../../../components/Tables/Positions";
import "../../../public/sass/pages/_positions.scss";

const PositionsContainer = () => {
	const {t, i18n} = useTranslation();

	const [modalState, setModal] = useState({id: "", mode: "", isOpen: false});
	const [loading, setLoading] = useState(true);
	const [handlePagination, setHandlePagination] = useState(true);
	const [products, setProducts] = useState([]);
	const [editData, setData] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [categories, setCategories] = useState([]);
	const [third_parties, setThirdPartiesCompanies] = useState([]);
	const [total, setTotal] = useState([]);
	const [role, setRoles] = useState();
	const [pageSize, setpageSize] = useState();
	const [currentPage, setCurrenPage] = useState();

	useEffect(() => {
		handleGetAllProducts(10, 1);
		handleGetAllActions();
		handleThirdPartiesCompanies();
		handleGetAllCategories();
		handleGetAllRoles();
	}, []);

	const handleTableChange = (pagination) => {
		handleGetAllProducts(pagination?.pageSize, pagination?.current);
		setpageSize(pagination?.pageSize);
		setCurrenPage(pagination?.current);
	};

	const handleSearch = (e) => {
		let value = e.toLowerCase();

		if (value.length > 2) {
			setLoading(true);
			setHandlePagination(false);

			let company_id = localStorage.getItem("company_id");

			company_id = company_id.replace(/"/g, "");

			if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
				Api(`products/search/` + value, "GET", null)
					.then((data) => {
						if (data) {
							setProducts(data?.results);
							setTotal(data?.total);

							setLoading(false);
						}
					})
					.catch(() => {
						setLoading(false);
					});
			} else {
				Api(`products/search/by-company/${company_id}/` + value, "GET", null)
					.then((data) => {
						if (data) {
							setProducts(data?.results);
							setTotal(data?.total);

							setLoading(false);
						}
					})
					.catch(() => {
						setLoading(false);
					});
			}
		}

		if (value.length === 0) {
			handleGetAllProducts(10, 1);
			setHandlePagination(true);
		}
	};

	const handleModal = (mode, isOpen, id) => {
		setModal({
			...modalState,
			["mode"]: mode,
			["isOpen"]: isOpen,
			["id"]: id,
		});
	};

	const handleGetAllProducts = (pageSize = 10, page = 1) => {
		let company_id = localStorage.getItem("company_id");

		company_id = company_id.replace(/"/g, "");

		if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
			Api(`products?pageSize=${pageSize}&page=${page}`, "GET", null)
				.then((data) => {
					setProducts(data?.results);
					setTotal(data?.total);
					setLoading(false);
				})
				.catch(() => setLoading(false));
		} else {
			Api(`products/by-company/${company_id}/?pageSize=${pageSize}&page=${page}`, "GET", null)
				.then((data) => {
					setProducts(data?.results);
					setTotal(data?.total);
					setLoading(false);
				})
				.catch(() => setLoading(false));
		}
	};

	const handleGetAllActions = () => {
		Api("actions", "GET", null)
			.then((data) => {
				setCompanies(data.results);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	const handleGetAllCategories = () => {
		Api("categories", "GET", null)
			.then((data) => {
				setCategories(data.results);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	const handleGetAllRoles = () => {
		Api("users/roleId", "GET", null)
			.then((data) => {
				setRoles(data.role.id);
			})
			.catch((err) => toastNotification("error", err.error || "Error"));
	};

	const handleThirdPartiesCompanies = () => {
		Api("third-party", "GET", null)
			.then((data) => {
				setThirdPartiesCompanies(data.results);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};
	const getProductById = (id) => {
		setLoading(true);
		Api("products/" + id, "GET", null)
			.then((data) => {
				setData(data);
				handleModal("edit", true, id);
				setLoading(false);
			})
			.catch((err) => {
				toastNotification("error", err.error || `${t("something_went_wrong")}`);
				setLoading(false);
			});
	};

	const deleteProduct = (id) => {
		if (!id) return message.error("Id is missing!");
		Api("products/" + id, "DELETE", null)
			.then(() => {
				handleGetAllProducts(pageSize, currentPage);
				toastNotification("success", `${t("product_successfully_deleted")}`);
			})
			.catch(() => toastNotification("error", `${t("error_delete_product")}`));
	};

	const handleFilter = (filterValues) => {
		let company_id = localStorage.getItem("company_id");
		const searchFrom = filterValues?.from ? filterValues.from.toISOString() : "";
		const searchTo = filterValues?.to ? filterValues.to.toISOString() : "";
		company_id = company_id.replace(/"/g, "");

		if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
			Api(
				`products/filter/filter-products?action=${filterValues?.company || ""}&category=${
					filterValues?.category || ""
				}&assessment=${
					filterValues?.assessment || ""
				}&from=${searchFrom}&to=${searchTo}&pageSize=${100}&page=${0}`,
				"GET",
				null
			)
				.then((data) => {
					setProducts(data?.results);
					setTotal(data?.total);
				})
				.catch(() => toastNotification("error", `Error while filtering`));
		} else {
			Api(
				`products/filter/filter-products?action=${filterValues?.company || ""}&category=${
					filterValues?.category || ""
				}&assessment=${
					filterValues?.assessment || ""
				}&from=${searchFrom}&to=${searchTo}&pageSize=${100}&page=${0}`,
				"GET",
				null
			)
				.then((data) => {
					setProducts(data?.results);
					setTotal(data?.total);
				})
				.catch(() => toastNotification("error", `Error while filtering`));
		}
	};
	return (
		<div className='positions-container'>
			<Row>
				<Col flex='1 1 200px'>
					<SearchBy onSearch={handleSearch} data={products} t={t} />
				</Col>
				<Col flex='0 1 300px'>
					{permissionsFilter("Products", "ProductCreate") && (
						<Button style={{float: "right"}} className='button' onClick={() => handleModal("create", true)}>
							{t("add_position")}
						</Button>
					)}
				</Col>
			</Row>

			<div className='header'>
				<Filters
					handleFilter={handleFilter}
					companies={companies}
					categories={categories}
					handleGetAllProducts={handleGetAllProducts}
				/>
				<CreatePositions
					isVisible={modalState.mode == "create" && modalState.isOpen}
					onClose={handleModal}
					handleGetAllProducts={handleGetAllProducts}
					companies={companies}
					third_parties={third_parties}
					categories={categories}
					products={products}
					role={role}
				/>

				<EditPosition
					editData={editData}
					id={modalState.id}
					isVisible={modalState.mode == "edit" && modalState.isOpen}
					handleGetAllProducts={handleGetAllProducts}
					onClose={handleModal}
					companies={companies}
					third_parties={third_parties}
					categories={categories}
					pageSize={pageSize}
					currentPage={currentPage}
				/>
			</div>

			<PositionsTable
				products={products}
				getProductById={getProductById}
				deleteProduct={deleteProduct}
				handleGetAllProducts={handleGetAllProducts}
				loading={loading}
				total={total}
				handlePagination={handlePagination}
				handleTableChange={handleTableChange}
			/>
		</div>
	);
};
export default PositionsContainer;
