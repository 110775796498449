import React from "react";
import { Table, Space, Divider, Popconfirm, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ApiConstants from "../../../api/ApiConstants";
import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/layout/_index.scss";

const RolesTable = ({ roles, loading, getRoleById, deleteRole }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: `${t("role")}`,
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name) => name.split(",").map((item) => t(item)),
    },
    {
      title: `${t("permissions")}`,
      dataIndex: "permissions",
      key: "permissions",
      render: (permissions) => {
        return permissions.split(",").map((item, idx) => (
          <Tag color="purple" key={idx}>
            {t(item)}
          </Tag>
        ));
      },
    },
    {
      title: "",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          {permissionsFilter("Roles", "RoleUpdate") && (
            <a onClick={() => getRoleById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
          )}
          {permissionsFilter("Roles", "RoleDelete") && (
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteRole(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={roles}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
    </div>
  );
};
export default RolesTable;
