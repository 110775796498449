import React from "react";
import { Table, Space, Divider, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ApiConstants from "../../../api/ApiConstants";
import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/layout/_index.scss";

const ClientsTable = ({ clients, loading, getCompanyById, deleteCompany }) => {
  const { t, i18n } = useTranslation();

  const handleStatus = (status) => {
    switch (status) {
      case 0:
        return {
          color: "#13c2c2",
          item: t("status_accepted"),
        };
      case 1:
        return {
          color: "#cf1322",
          item: t("status_not_accepted"),
        };
      case 2:
        return {
          color: "#1890ff",
          item: t("status_in_progress"),
        };
      case 3:
        return {
          color: "#389e0d",
          item: t("status_done"),
        };
      case 4:
        return {
          color: "#820014",
          item: t("status_not_active"),
        };
      default:
        return {
          color: "#820014",
          item: t("status_not_active"),
        };
    }
  };

  const columns = [
    {
      title: "Nr",
      dataIndex: "action_id",
      key: "action_id",
      render: (action_id) => <>V{action_id}</>,
    },
    {
      title: "Firma",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Vorname",
      dataIndex: "owner_firstname",
      key: "owner_firstname",
      sorter: (a, b) => a.owner_firstname.length - b.owner_firstname.length,
    },
    {
      title: "Nachname",
      dataIndex: "owner_lastname",
      key: "owner_lastname",
      sorter: (a, b) => a.owner_lastname.length - b.owner_lastname.length,
    },
    {
      title: "Addresse",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Mail",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Tel",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (status) => (
        <>
          <a style={{ color: `${handleStatus(status).color}` }}>
            {handleStatus(status).item}
          </a>
        </>
      ),
    },
    {
      title: "Aktion",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          {permissionsFilter('Proceedings', 'ProceedingUpdate') &&
            <a onClick={() => getCompanyById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
          }
          {permissionsFilter('Proceedings', 'ProceedingDelete') &&
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteCompany(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={clients}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
    </div>
  );
};
export default ClientsTable;
