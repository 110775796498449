import React, { useState } from "react";
import { Input, AutoComplete } from "antd";
const { Option } = AutoComplete;

export const SearchBy = ({ onSearch, data, t }) => {
  const filterName = data
    ?.filter((a) => a.breakdown_value)
    .map(({ breakdown_value }) => {
      return { value: breakdown_value };
    });

  return (
    <div className="header">
      <AutoComplete
        onSearch={onSearch}
        // options={filterName}
        placeholder={t("search_by_name")}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </div>
  );
};
