import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import moment from "moment";

export const SignPage = ({ date }) => {
  return (
    <Page
      size="A4"
      style={{
        backgroundColor: "#fff",
        padding: "40px",
      }}
    >
      <PdfHeader imgUrl="https://i.imgur.com/BUQFkU3.jpg" />
      <View
        style={{
          margin: "30px 0",
          fontSize: "12px",
          lineHeight: "1.3",
          fontWeight: 500,
          fontFamily: "Poppins",
          color: "#1F2C4B",
        }}
      >
        <Text style={{ paddingBottom: "20px" }}>
          Die genaue Höhe kann selbstverständlich nur durch einen
          Kostenvoranschlag eines Entsorgungsunternehmens ermittelt werden.
        </Text>
        <Text style={{ paddingBottom: "20px" }}>
          Wenngleich diese Bewertung im Lichte einer langjährigen Erfahrung und
          nach bestem Wissen und Gewissen abgegeben wird, so versteht sie sich
          dennoch ohne Übernahme einer Gewähr.
        </Text>
        <Text style={{ paddingBottom: "30px" }}>
          Der Sachverständige weist ausdrücklich darauf hin, dass der
          Zerschlagungswert nur Bestand hat, sollten die Objekte direkt vor Ort
          innerhalb eines angemessenen Zeitraumes verwertet werden. Bei einer
          Einlagerung der Objekte ist davon auszugehen, dass der
          Zerschlagungswert nicht realisiert werden kann.
        </Text>
        <Text>Eching, {moment(date).format("DD.MM.YYYY")}</Text>
      </View>

      <View>
        <Text style={{ fontWeight: 700, fontSize: "14px", color: '#3E5078' }}>
          PETER HÄMMERLE
        </Text>
        <Text
          style={{
            fontSize: "10px",
            lineHeight: "1.8",
            width: "50%",
            padding: "15px 0",
            fontStyle: "italic",
            color: '#3E5078'
          }}
        >
          Sachverständiger für technische Anlagen und Maschinen öffentlich
          bestellter und vereidigter Auktionator für Grundstücke,
          grundstücksgleiche Rechte sowie technische Anlagen und Maschinen
          jeglicher Art
        </Text>
      </View>
      <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
        <Image
          style={{ width: "150" }}
          source={"https://i.imgur.com/fyEGM2R.jpg"}
        />
      </View>
      <Text
        style={{
          position: "absolute",
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: "center",
          color: "grey",
        }}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
};
