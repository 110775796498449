import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";

export const IntroPage = ({ data }) => {
  if (!data) return null;
  return (
    <Page size="A4" size="LETTER" style={{ backgroundColor: "#fff",  fontFamily: "Poppins", }}>
      <Image
        style={{
          position: "absolute",
          height: "100%",
          width: "150%",
        }}
        src={"https://i.imgur.com/sXL0x9m.png"}
        alt="images"
      />
      <View style={{ padding: "30px" }}>
        <PdfHeader imgUrl="https://i.imgur.com/BUQFkU3.jpg" />
      </View>
      <View
        style={{
          flexDirection: "column",
          marginTop: "100px",
          alignItems: "left",
          lineHeight: 1.5,
          padding: "50px",
        }}
      >
        <Text
          style={{ fontSize: "42px", color: "#364059", fontWeight: "bold" }}
        >
          GUTACHTEN
        </Text>
        <Text style={{ color: "#8F8F8F" }}>MOBILIEN - WERTERMITTLUNG</Text>
        <Text style={{ color: "#8F8F8F" }}>Fortführungswert</Text>

        <View style={{ marginTop: 50, fontSize: "12px" }}>
          <Text style={{ color: "#364059" }}>
            {data?.addresses?.length && `${data?.name}`}
          </Text>
          <Text style={{ color: "#364059" }}>
            {`${data?.addresses[0].street} ${data?.addresses[0].house_number}`}
          </Text>
          <Text style={{ color: "#364059" }}>
            {`${data?.addresses[0].zip} ${data?.addresses[0].city}`}
          </Text>
          <Text style={{ color: "#364059" }}>{data?.addresses[0].country}</Text>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "auto",
          textAlign: "right",
        }}
      >
        <View
          style={{
            // width: "45%",
            // textAlign: "center",
            lineHeight: 1.4,
            fontSize: "12px",
            color: "#364059",
            padding: "30px",
          }}
        >
          <Text style={{ fontWeight: '600' }}>{data?.company?.name}</Text>
          <Text>{`${data?.company?.street} ${data?.company?.house_number}`}</Text>
          <Text>{`${data?.company?.zip} ${data?.company?.city}`}</Text>
          <Text>{`${data?.company?.country}`}</Text>
          <View style={{ paddingTop: "15px" }}>
            <Text>Tel: {data?.company?.phone || "xxx-xxx-xxx-xxx"}</Text>
            <Text>{data?.company?.email || ""}</Text>
          </View>
        </View>
      </View>
    </Page>
  );
};
