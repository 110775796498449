import React, { useState, useEffect } from "react";
import { Collapse, Button } from "antd";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Api from "../../../api";
import { EmptyData } from "../../EmptyData";

import "../../../public/sass/components/drawer/clients/_contracts.scss";
import { CreateAddress } from "./CreateAddress";

const { Panel } = Collapse;

export const Contracts = ({ id, handleThirdPartiesCompanies,third_parties }) => {
  const { t, i18n } = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleDrawer = () => setVisibleCreate(!isVisibleCreate);

  const text = `
  Test
`;

  useEffect(() => {
    handleThirdPartiesCompanies();
  }, []);

  if (!third_parties?.length) return <EmptyData />;

  return (
    <div>
      <Collapse
        // defaultActiveKey={["1"]}
        className="collapse-style"
      >
        {third_parties?.map((item, idx) => (
          <Panel
            header={
              item?.name +
              ", " +
              item?.country +
              ", " +
              item?.city +
              ", " +
              item?.zip
            }
            key={idx}
            className="panel-style-1"
          >
            <p>{item?.name}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
