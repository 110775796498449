import React from "react";
import { Table, Space, Divider, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ApiConstants from "../../../api/ApiConstants";
import Eye from "../../../public/images/dashboard/tables/eye.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";
import "../../../public/sass/layout/_index.scss";

const CategoriesTable = ({
  categories,
  loading,
  getCategoryById,
  deleteCategory,
}) => {
  const [t, i18n] = useTranslation();

  const columns = [
    {
      title: "Nr",
      dataIndex: "category_id",
      key: "category_id",
      render: (category_id) => <>C{category_id}</>,
    },
    {
      title: "Bezeichnung",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Art",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.length - b.type.length,
      render: (e) => <>{(e && t(e.toLowerCase())) || ""}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (status) => (
        <>
          <a style={{ color: status == 1 ? "#09CE87" : "#F5222D" }}>
            {status == 1 ? `${t("active")}` : `${t("not_active")}`}
          </a>
        </>
      ),
    },
    {
      title: "Aktion",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          {permissionsFilter('Categories', 'CategoryUpdate') &&
            <a onClick={() => getCategoryById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
          }
          {permissionsFilter('Categories', 'CategoryDelete') &&
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteCategory(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={categories}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
        defaultPageSize={25}
      />
    </div>
  );
};
export default CategoriesTable;
