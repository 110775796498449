import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

export const IntroTable = ({ data }) => {
  return (
    <Page size="LETTER" orientation="landscape" style={{ backgroundColor: "#fff", }}>
      <Image
        style={{
          position: "absolute",
          height: "100%",
          width: "150%",
        }}
        src={"https://i.imgur.com/sXL0x9m.png"}
        alt="images"
      />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          marginRight: "50px",
          paddingTop: "30px",
          paddingRight: "10px",
        }}
      >
        <Image
          style={{ width: "25%" }}
          source={"https://i.imgur.com/BUQFkU3.jpg"}
        />
      </View>

      <View
        style={{ lineHeight: 1.7, marginTop: "50px", marginLeft: "100px" }}
      >
        <Text style={{ fontSize: "42px", color: "#364059" }}>SACHAUFNAHME</Text>
        <Text style={{ fontSize: "26px", color: "#8F8F8F" }}>Zerschlagungs & Fortführungswert</Text>
      </View>

      <View
        style={{
          marginTop: "50px",
          // backgroundColor: "#404040",
          width: "80%",
          color: "#364059",
          paddingTop: "25px",
          paddingBottom: "50px",
        }}
      >

        <View
          style={{
            marginLeft: "100px",
            fontSize: "18px",
            lineHeight: 1.5,
          }}
        >

        <Text style={{ color: "#364059" }}>
          {data?.addresses?.length &&
            `${data?.name}`}
        </Text>
        <Text style={{ color: "#364059" }}>
          {`${data?.addresses[0].street} ${data?.addresses[0].house_number}`}
        </Text>
        <Text style={{ color: "#364059" }}>
          {`${data?.addresses[0].zip} ${data?.addresses[0].city}`}
        </Text>
        <Text style={{ color: "#364059" }}>
          {data?.addresses[0].country}
        </Text>
        </View>
      </View>

    </Page>
  );
};
