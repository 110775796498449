import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { IntroPage } from "./IntroPage";
import { SecondPage } from "./SecondPage";
import { SignPage } from "./SignPage";
import { CountPage } from "./CounPage";
import { IntroTable } from "./IntroTable";
import { TablePage } from "./TablePage";
import { CalculationAufteilung } from "./CalculationAufteilung";
import { CalculationUbersicht } from "./CalculationUbersicht";

import FONT_REGULAR from "../fonts/Poppins/Poppins-Regular.ttf";
import FONT_SEMI_BOLD from "../fonts/Poppins/Poppins-SemiBold.ttf";
import FONT_BOLD from "../fonts/Poppins/Poppins-Bold.ttf";
import { LastPageOne } from "./LastPageOne";
import { LastPageDescription } from "./LastPageDescription";
import { ListImages } from "./ListImages";

Font.register({
  family: "Poppins",
  format: "ttf",
  fonts: [
    {
      src: FONT_REGULAR,
      fontWeight: 'normal'
    },
    {
      src: FONT_SEMI_BOLD,
      fontWeight: 600
    },
    {
      src: FONT_BOLD,
      fontWeight: 700
    }
  ]
});

export function PdfDocument_sachaufnahme_zersch_fortuhrung({ data }) {
  return (
    <Document>
        {/* <IntroPage data={data} /> */}
        {/* <SecondPage data={data} />
        <SignPage date={data?.date_two} /> */}
        {/* <CountPage /> */}
        <IntroTable data={data} />
        <TablePage data={data} />
        <CalculationAufteilung data={data} />
        <CalculationUbersicht data={data} />
        {/* <LastPageOne hideTitle={false} document_info={data?.document_info} />
        <LastPageDescription document_info={data?.material_records_info} />
        <ListImages data={data} /> */}
    </Document>
  );
}
