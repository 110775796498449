import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";
import { CreateThirdParties } from "../../../components/Drawer/ThirdParty/CreateThirdParty";
import { EditThirdParty } from "../../../components/Drawer/ThirdParty/EditThirdParty";
import { toastNotification } from "../../../utils/toastNotification";
import { SearchBy } from "../../../components/Tables/Database/SearchBy";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import ThirdPartiesTable from "../../../components/Tables/ThirdParty/index";
import Api from "../../../api";
import "../../../public/sass/pages/_third_parties.scss";

const ThirdPartyContainer = () => {
  const { t, i18n } = useTranslation();

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [third_parties, setThirdParties] = useState([]);
  const [editData, setData] = useState([]);

  useEffect(() => {
    handleGetAllThirdParties();
  }, []);

  const handleSearch = (e) => {
    let value = e.toLowerCase();

    if (value.length > 2) {
      setLoading(true);

      let company_id = localStorage.getItem("company_id");

      company_id = company_id.replace(/"/g, "");

      if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
        Api(`third-party/search/` + value, "GET", null)
          .then((data) => {
            if (data) {
              setThirdParties(data?.results);

              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        Api(`third-party/search/by-company/${company_id}/` + value, "GET", null)
          .then((data) => {
            if (data) {
              setThirdParties(data?.results);

              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }

    if (value.length === 0) {
      handleGetAllThirdParties();
    }
  };

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllThirdParties = () => {
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    if (company_id == "fdf2c060-20fc-4ab4-804e-eb10945c5297") {
      Api("third-party", "GET", null)
        .then((data) => {
          setThirdParties(data.results);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      Api("third-party/by-company/" + company_id, "GET", null)
        .then((data) => {
          setThirdParties(data.results);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const getCompanyById = (id) => {
    setLoading(true);
    Api("third-party/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteCompany = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("third-party/" + id, "DELETE", null)
      .then(() => {
        toastNotification(
          "success",
          `${t("third_party_successfully_deleted")}`
        );
        handleGetAllThirdParties();
      })
      .catch(() =>
        toastNotification("error", `${t("error_delete_third_party")}`)
      );
  };

  return (
    <div className="customers-container">
      <Row>
        <Col flex="1 1 200px">
          <SearchBy onSearch={handleSearch} data={third_parties} t={t} />
        </Col>
        <Col flex="0 1 300px">
          {permissionsFilter('Third Party Rights', 'ThirdPartyRightCreate') &&
            <Button
              style={{ float: "right" }}
              className="button"
              onClick={() => handleModal("create", true)}
            >
              {t("add_third_party")}
            </Button>
          }
        </Col>
      </Row>
      <div className="header">
        {/* <Filters {...onSearch} /> */}

        {/* <Button className="button" onClick={() => handleModal("create", true)}>
          {t("add_third_party")}
        </Button> */}

        <CreateThirdParties
          onClose={handleModal}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllThirdParties={handleGetAllThirdParties}
        />

        <EditThirdParty
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllThirdParties={handleGetAllThirdParties}
          onClose={handleModal}
        />
      </div>
      <ThirdPartiesTable
        third_parties={third_parties}
        getCompanyById={getCompanyById}
        deleteCompany={deleteCompany}
        loading={loading}
      />
    </div>
  );
};
export default ThirdPartyContainer;
