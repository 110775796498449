import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  footer: {
    fontSize: 12,
    color: "#3E5078",
    // margin: "15px 5px",
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 10,
  },
});
export const PdfFooterCalculation = () => (
  <View style={styles.footer}>
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 5px",
      }}
    >
      <View style={{ lineHeight: 1.5, fontSize: "7px" }}>
        <Text>Legenden: Vermögensart nach winsolvenz</Text>
        <Text style={{ width: "70%" }}>
          12 Grundstücke und Bauten, 15 Maschinen- u. techn. Anlagen, 16
          Betriebs- und Geschäftsausstattung, 39 Fuhrpark, 23 Roh-, Hilf- u.
          Betriebsstoffe, 24 Unfertige/Halbfertige Waren, 25 Fertige Erzeugnisse
          u. Waren Bemerkungen: EV: Eigentumsvorbehalt FE: Fremdeigentum FINA:
          Finanzierung/Darlehen MK: Mietkauf SÜ/RSÜ:
          Sicherungsübereignet/Raumsicherungsübereignet LS: Leasing VPR:
          Vermieterpfandrecht{" "}
        </Text>
      </View>

      <View>
        <Image
          style={{ width: "150px" }}
          source={"https://i.imgur.com/BUQFkU3.jpg"}
        />
      </View>
    </View>
  </View>
);
