import React, { useState, useEffect } from "react";
import { Filters } from "../../../components/Drawer/Requests/Filters";
import { toastNotification } from "../../../utils/toastNotification";
import { EditRequest } from "../../../components/Drawer/Requests/EditRequests/EditRequest";
import { useTranslation } from "react-i18next";
import RequestsTable from "../../../components/Tables/Requests";
import Api from "../../../api";
import "../../../public/sass/pages/_finished.scss";

const FinishedRequestsContainer = () => {
  const { t, i18n } = useTranslation();
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [editData, setData] = useState([]);
  const [requests, setRequests] = useState([]);

  const onSearch = (value) => console.log(value);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  useEffect(() => {
    getFinishedRequests();
  }, []);

  const getFinishedRequests = () => {
    Api("requests/type/finished", "GET")
      .then((data) => {
        setRequests(data.results);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getRequestById = (id) => {
    setLoading(true);
    Api("requests/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteRequest = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("requests/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("user_successfully_deleted")}`);
        getFinishedRequests();
      })
      .catch(() => toastNotification("error", `${t("error_delete_user")}`));
  };

  return (
    <div className="finished-container">
      <div className="header">
        <Filters {...onSearch} />
      </div>
      <EditRequest
        getFinishedRequests={getFinishedRequests}
        data={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        onClose={handleModal}
        type="finished"
      />
      <RequestsTable
        data={editData}
        requests={requests}
        getRequestById={getRequestById}
        deleteRequest={deleteRequest}
        loading={loading}
      />
    </div>
  );
};
export default FinishedRequestsContainer;
