import React from "react";
import { Drawer, Button, Tabs, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { CreateClientForm } from "./CreateClientForm";
import { AddressContainer } from "../../../containers/Dashboard/Addresses/AddressContainer";

import "../../../public/sass/components/drawer/_createDrawers.scss";

export const CreateClients = ({
  isVisible,
  onClose,
  handleGetAllClients,
  id,
}) => {
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;

  return (
    <Drawer
      title={t("create_new_client")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      forceRender={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="users-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Tabs
        defaultActiveKey="1"
        className="tab-style"
      >
        <TabPane tab={t("procedure")} key="1" className="tab-pane-style">
          <CreateClientForm
            onClose={onClose}
            handleGetAllClients={handleGetAllClients}
          />
        </TabPane>
        <TabPane tab={t("addresses")} key="2" className="tab-pane-style">
          <AddressContainer onClose={onClose} id={id} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
