import React from "react";
import { Space, Input, Select, DatePicker, Form, Button } from "antd";
import { useTranslation } from "react-i18next";

import "../../../public/sass/components/_filters.scss";

const { Option } = Select;
const { Search } = Input;

export const Filters = ({
  companies,
  categories,
  handleFilter,
  handleGetAllProducts,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const clearFilters = () => {
    form.resetFields();
    handleGetAllProducts();
  };

  // return null;
  // return (
  //   <div className="wrapper">
  //     <Space direction="horizontal" className="filters">
  //       <Select
  //         defaultValue={t("client")}
  //         style={{ width: 110 }}
  //         onChange={handleChange}
  //         className="filter-style"
  //       >
  //         <Option value="jack">Jack</Option>
  //         <Option value="lucy">Lucy</Option>
  //       </Select>
  //       <Select
  //         defaultValue={t("property")}
  //         style={{ width: 110 }}
  //         onChange={handleChange}
  //         className="filter-style"
  //       >
  //         <Option value="jack">Jack</Option>
  //         <Option value="lucy">Lucy</Option>
  //       </Select>
  //       <Select
  //         defaultValue={t("appriauser_type")}
  //         style={{ width: 110 }}
  //         onChange={handleChange}
  //         className="filter-style"
  //       >
  //         <Option value="jack">Jack</Option>
  //         <Option value="lucy">Lucy</Option>
  //       </Select>
  //       <DatePicker onChange={onChange} />

  //       <DatePicker onChange={onChange} />

  //       <Search
  //         placeholder={t("search")}
  //         onSearch={onSearch}
  //         style={{ width: 110 }}
  //       />
  //     </Space>
  //   </div>
  // );

  return (
    <div className="wrapper">
      <Space direction="horizontal" className="filters">
        <Form
          form={form}
          id="products-filters"
          layout="inline"
          scrollToFirstError
          onFinish={handleFilter}
        >
          <Form.Item name="company">
            <Select
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              placeholder={t("company")}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {companies?.map((item, idx) => (
                <Option key={idx} value={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="category">
            <Select
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              placeholder={t("category")}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {categories?.map((item, idx) => (
                <Option key={idx} value={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="assessment">
            <Select
              placeholder={t("assessment")}
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value="assessment">{t("assessment")}</Option>
              <Option value="desktop_assessment">
                {t("desktop_assessment")}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="from">
            <DatePicker />
          </Form.Item>
          <Form.Item name="to">
            <DatePicker />
          </Form.Item>{" "}
          <Button
            type="primary"
            form="products-filters"
            key="submit"
            htmlType="submit"
          >
            Filter
          </Button>
          <Button onClick={clearFilters} className="button-style">
            {t("clear_filter")}
          </Button>
        </Form>
      </Space>
    </div>
  );
};
