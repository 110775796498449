import { combineReducers } from 'redux';

// Import reducers
import authReducer from "../containers/Auth/store/auth-reducer";

const appReducer = combineReducers({
    auth: authReducer
});

const rootReducer = (state, action) => {
    // return state default
    return appReducer(state, action);
};

export default rootReducer;