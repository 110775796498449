import React, {useState} from "react";
import {Modal, Button, Select, Input, message} from "antd";
import {FilePdfOutlined} from "@ant-design/icons";
import api from "../../../api";

export const SentEmailModal = ({
  files,
  email,
  t,
  handleCloseEmailModal,
  isSentEmail,
  setSentEmail,
  emailPdfOne,
  emailPdfTwo,
  emailPdfThree,
  emailPdfFour,
  emailPdfFive,
}) => {
  // const file_one = emailPdfOne;
  const [emails, setEmails] = useState([email]);
  // const [isSentEmail, setSentEmail] = useState(false);
  const [textAreaValue, setTextArea] = useState(
    "Sehr geehrte Damen und Herren, im Anhang finden Sie Ihre Bewertungen."
  );

  const handleSentModal = () => {
    setSentEmail(!isSentEmail);
  };

  const handleSubmit = () => {
    if (!files.length) return alert("Bitte wählen Sie eines der Dokumente");
    const formData = new FormData();

    if (emailPdfOne) {
      const file_one = new File(
        [emailPdfOne],
        "Gutachten (Zerschlagungswert)",
        {
          lastModified: new Date().getTime(),
          type: "pdf",
        }
      );
      formData.append("files", file_one);
    }

    if (emailPdfTwo) {
      const file_two = new File([emailPdfTwo], "Gutachten (Fortführungswert)", {
        lastModified: new Date().getTime(),
        type: emailPdfTwo.type,
      });
      formData.append("files", file_two);
    }
    if (emailPdfThree) {
      const file_three = new File(
        [emailPdfThree],
        "Sachaufnahme (Zerschlagungswert)",
        {
          lastModified: new Date().getTime(),
          type: emailPdfThree.type,
        }
      );
      formData.append("files", file_three);
    }

    if (emailPdfFour) {
      const file_four = new File(
        [emailPdfFour],
        "Sachaufnahme (Fortführungswert)",
        {
          lastModified: new Date().getTime(),
          type: emailPdfFour.type,
        }
      );
      formData.append("files", file_four);
    }

    if (emailPdfFive) {
      const file_five = new File(
        [emailPdfFive],
        "Sachaufnahme (Zerschlagungs- & Fortführungswert)",
        {
          lastModified: new Date().getTime(),
          type: emailPdfFive.type,
        }
      );

      formData.append("files", file_five);
    }

    formData.append("emails", emails);
    formData.append("message", textAreaValue);

    api("actions/mail/send-pdf", "POST", formData)
      .then(() => {
        message.success("E-Mail wurde erfolgreich gesendet");
      })
      .catch(() => message.error("Something went wrong, please try again!"));
    setSentEmail(!isSentEmail);
  };

  return (
    <>
      <Modal
        zIndex={30000}
        title={null}
        visible={isSentEmail}
        // onOk={handleSubmit}
        onCancel={handleCloseEmailModal}
        footer={null}
        maskClosable={false}
      >
        <div className='sent-email-container'>
          <div className='header-email'>
            <h3>{t("share_docs")}</h3>
            <FilePdfOutlined />
          </div>
          <div className='body-content-mail'>
            <div className='autoselect-emails'>
              <Select
                mode='tags'
                defaultValue={[email]}
                style={{width: "100%"}}
                placeholder='Add emails'
                onChange={(e) => setEmails(e)}
              ></Select>
            </div>
            <div>
              <Input.TextArea
                rows={4}
                placeholder={t("enter_message")}
                value={textAreaValue}
                onChange={(e) => setTextArea(e.target.value)}
              />
            </div>
          </div>
          <div className='footer-sent-email'>
            <Button style={{marginRight: "10px"}}>{t("close")}</Button>
            <Button type='primary' onClick={handleSubmit}>
              {t("send_email")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
