import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PdfFooterCalculation } from "./PdfFooterCalculation";

const BORDER_COLOR = "#a9a9a9";

const styles = StyleSheet.create({
    body: {
      padding: "15px 25px",
    },
    tableHeader: {
      border: "1px solid #a9a9a9",
      fontSize: "14px",
      padding: "5px",
      fontWeight: 700,
    },
    tableCell: {
      flexDirection: "row",
      border: "1px solid #a9a9a9",
      color: '#1F2C4B'
    },
    cellLabel: {
      fontSize: "14px",
      padding: "3px",
      marginTop: "-1px",
      borderRight: "1px solid #a9a9a9",
    },
    t10: {
      width: "10%",
      textAlign: "left",
    },
    t45Left: {
      width: "45%",
      textAlign: "left",
    },
    t45: {
      width: "45%",
      textAlign: "right",
    },
    bottomTotal: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "14px",
      color: '#1F2C4B',
    },
    headerNumber: {
      textAlign: "right",
      fontSize: "10px",
    },
    pageHeader: {
      fontSize: "22px",
      marginTop: "50px",
      color: "#1F2C4B"
    },
    inlineHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "75%",
      fontSize: "12px",
      lineHeight: 1.7,
      marginTop: 15,
      color: "#1F2C4B"
    },
    lineGray: {
      height: "5px",
      backgroundColor: "#808080",
      margin: "20px 0",
    },
    tableContainer: {
      marginTop: '50px',
      color: '#364059',
      // marginLeft: "21.5%",
    },
  });

export const CalculationUbersicht = ({ data }) => {
  let totalAmountValue = 0;
  let allGroupBy = [];
  const getAllFreie = data?.products.filter((i) => i.rights === "Freie Masse");
  let groupByAssetType = _.groupBy(getAllFreie, "asset_type");

  const getAllAbso = data?.products.filter((i) => i.rights === "Absonderung");
  let groupByAssetTypeAbso = _.groupBy(getAllAbso, "asset_type");

  const getAllAuss = data?.products.filter((i) => i.rights === "Aussonderung");
  let groupByAssetTypeAuss = _.groupBy(getAllAbso, "asset_type");

  // console.log(allGroupBy);
  // console.log(res);

  // console.log("allGroupBy", allGroupBy);
  // console.log("group", group);

  const renderOption = (number) => {
    switch (number) {
      case "12":
        return "Grundstücke und Bauten";
      case "15":
        return "Maschinen- u. techn. Anlagen";
      case "16":
        return "Betriebs- und Geschäftsausstattungn";
      case "39":
        return "Fuhrpark";
      case "23":
        return "Roh-, Hilf- u. Betriebsstoffe";
      case "24":
        return "Unfertige/Halbfertige Waren";
      case "25":
        return "Fertige Erzeugnisse u. Waren";
      default:
        return "Unfertige / Halbfertige Waren";
    }
  };

  return (
    <Page size="A4" style={styles.body} orientation="landscape" wrap>
      <View fixed style={styles.headerNumber}>
        <Text>Seite 1/3</Text>
      </View>

      <View style={styles.pageHeader}>
        <Text>ÜBERSICHT AUFTEILUNG DER GESAMTWERTERMITTLUNG</Text>
      </View>

      <View style={styles.inlineHeader}>
        <View>
          <Text style={{ fontWeight: 700, marginRight: "20px" }}>
            Auftraggeber:
          </Text>
          <Text style={{ fontWeight: 700, marginRight: "20px" }}>
            Verfahren:
          </Text>
        </View>
        <View>
          <Text>
            {`${data?.client?.name}, ${data?.client?.street} ${data?.client?.house_number}, ${data?.client?.zip} ${data?.client?.city}, ${data?.client?.country}`}
          </Text>
          <Text>
            {data?.addresses?.length &&
              `${data?.name}, ${data?.addresses[0].street} ${data?.addresses[0].house_number}, ${data?.addresses[0].zip} ${data?.addresses[0].city}, ${data?.addresses[0].country}`}
          </Text>
        </View>
      </View>

      <View style={styles.lineGray}></View>

      <View style={styles.tableContainer} wrap={false}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: 'center',
            alignItems: 'center',
            fontSize: "14px",
            color: "#3E5078",
            backgroundColor: '#ecedf1',
            padding: 10,
          }}
        >
          <Text style={{ fontWeight: 700, }}>
            I Anlagevermögen
          </Text>
          <Text style={{ fontWeight: 700,  }}>
            Zerschlagungswert
          </Text>
        </View>
        

        <View style={styles.tableCell}>
            <View style={[styles.cellLabel, styles.t10]}>
              <Text>15</Text>
            </View>
            <View style={[styles.cellLabel, styles.t45Left]}>
              <Text> Maschinen und Technische Anlagen</Text>
            </View>
            <View style={[styles.cellLabel, styles.t45]}>
              <Text>0,00 €</Text>
            </View>
        </View>

        <View style={[styles.lineGray, { margin: "10px 0" }]}></View>
        <View style={styles.bottomTotal}>
          <Text style={{ fontSize: "16px", fontWeight: 700 }}>Ergebnis</Text>
          <Text style={{ fontSize: "16px", fontWeight: 700 }}>{totalAmountValue.toFixed(2).replace('.', ',')} €</Text>
        </View>
      </View>

      <View style={styles.tableContainer} wrap={false}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: 'center',
            alignItems: 'center',
            fontSize: "14px",
            color: "#3E5078",
            backgroundColor: '#ecedf1',
            padding: 10,
          }}
        >
          <Text style={{ fontWeight: 700 }}>RSÜ, B.O.S.</Text>
          <Text style={{ fontWeight: 700, marginBottom: 10 }}></Text>
        </View>

        <View style={styles.tableCell}>
          <View style={[styles.cellLabel, styles.t10]}>
            <Text>12</Text>
          </View>
          <View style={[styles.cellLabel, styles.t45Left]}>
            <Text> Maschinen und Technische Anlagen </Text>
          </View>
          <View style={[styles.cellLabel, styles.t45]}>
            <Text>{Math.random().toFixed(2).replace('.', ',')} €</Text>
          </View>
        </View>
        <View style={styles.tableCell}>
          <View style={[styles.cellLabel, styles.t10]}>
            <Text>12</Text>
          </View>
          <View style={[styles.cellLabel, styles.t45Left]}>
            <Text> Maschinen und Technische Anlagen </Text>
          </View>
          <View style={[styles.cellLabel, styles.t45]}>
            <Text>{Math.random().toFixed(2).replace('.', ',')} €</Text>
          </View>
        </View>
        <View style={styles.tableCell}>
          <View style={[styles.cellLabel, styles.t10]}>
            <Text>12</Text>
          </View>
          <View style={[styles.cellLabel, styles.t45Left]}>
            <Text> Maschinen und Technische Anlagen </Text>
          </View>
          <View style={[styles.cellLabel, styles.t45]}>
            <Text>{Math.random().toFixed(2).replace('.', ',')} €</Text>
          </View>
        </View>

        <View style={[styles.lineGray, { margin: "10px 0" }]}></View>
        <View style={styles.bottomTotal}>
          <Text style={{ fontSize: "16px", fontWeight: 700 }}>Ergebnis</Text>
          <Text style={{ fontSize: "16px", fontWeight: 700 }}>
          {(totalAmountValue + 3).toFixed(2).replace('.', ',')} €
          </Text>
        </View>
      </View>
      <View style={styles.tableContainer} wrap={false}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: 'center',
            alignItems: 'center',
            fontSize: "14px",
            color: "#3E5078",
            backgroundColor: '#ecedf1',
            padding: 10,
          }}
        >
          <Text style={{ fontWeight: 700,}}>RSÜ, B.O.S.</Text>
          <Text style={{ fontWeight: 700, }}></Text>
        </View>

        <View style={styles.tableCell}>
          <View style={[styles.cellLabel, styles.t10]}>
            <Text>16</Text>
          </View>
          <View style={[styles.cellLabel, styles.t45Left]}>
            <Text> Maschinen und Technische Anlagen </Text>
          </View>
          <View style={[styles.cellLabel, styles.t45]}>
            <Text>{Math.random().toFixed(2).replace('.', ',')} €</Text>
          </View>
        </View>

        <View style={[styles.lineGray, { margin: "10px 0" }]}></View>
        <View style={styles.bottomTotal}>
          <Text style={{ fontSize: "16px", fontWeight: 700 }}>Ergebnis</Text>
          <Text style={{ fontSize: "16px", fontWeight: 700 }}>
          {(totalAmountValue + 5).toFixed(2).replace('.', ',')} €
          </Text>
        </View>
      </View>

      <PdfFooterCalculation />
    </Page>
  );
};
