import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../../utils/toastNotification";
import Edit from "../../../../public/images/dashboard/tables/edit.svg";

import Api from "../../../../api";

import "../../../../public/sass/components/drawer/_createForm.scss";

export const EditForm = ({
  onClose,
  isVisible,
  type,
  data,
  getOpenRequests,
  getFinishedRequests,
  isEdit,
  setEdit,
  statusCheck
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);

  useEffect(() => {
    getAllRequestClients();
    if (isVisible) {
      let response = { ...data };
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("requests/" + data.id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", `${t("user_created_successfully")}`);
        if (type == "open") {
          getOpenRequests();
        } else {
          getFinishedRequests();
        }
        form.resetFields();
        onClose();
        setEdit(false);
      })
      .catch((err) =>
        toastNotification("error", err.error || `${t("error_create_user")}`)
      );
    onClose();
  };

  const getAllRequestClients = () => {
    let company_id = localStorage.getItem("company_id");
    company_id = company_id.replace(/"/g, "");

    setLoadingCustomers(true);
    Api("requests/clients/by-company/" + company_id, "GET", null)
      .then((data) => {
        setAllCustomers(data.results);
        setLoadingCustomers(false);
      })
      .catch(() => setLoadingCustomers(false));
  };

  const validateMessages = {
    required: `${t("field_required")}`,
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      id="requests-form"
      layout="vertical"
      visible={isVisible}
      scrollToFirstError
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={22}></Col>
        <Col span={2} style={{ flexDirection: "row", display: "flex" }}>
          <a
            className={`${data.status == 3 && "hidden"}`}
            onClick={() => setEdit(true)}
          >
            <img src={Edit} alt="Edit" />
          </a>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item name="client_id" label={t("customer")}>
            <Select
              className="select-request"
              loading={loadingCustomers}
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              disabled={!isEdit}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) === 0
              }
            >
              {allCustomers?.map((customer, idx) => (
                <Option key={idx} value={customer?.id}>
                  {customer?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="request_id"
            label={t("customer_number")}
            rules={[{ required: true }]}
          >
            <Input className="input-request" type="number" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="name"
            label={t("name")}
            rules={[{ required: false }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="owner_firstname"
            label={t("owner_firstname")}
            rules={[{ required: true }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="owner_lastname"
            label={t("owner_lastname")}
            rules={[{ required: true }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="street"
            label={t("street")}
            rules={[{ required: true }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="house_number"
            label={t("house_number")}
            rules={[{ required: false }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="zip" label={t("zip")} rules={[{ required: true }]}>
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="city"
            label={t("city_name")}
            rules={[{ required: true }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="country"
            label={t("country")}
            rules={[{ required: true }]}
          >
            <Select
              className="select-request"
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              disabled={!isEdit}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value="Deutschland">{t("germany")}</Option>
              <Option value="Österreich">{t("austria")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phone"
            label={t("phone")}
            rules={[{ required: false }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="email"
            label={t("email")}
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item name="more_addresses" label={t("more_addresses")}>
            <Button className="button-style">
              <PlusCircleTwoTone className="icon-style" />{" "}
              {t("more_addresses")}
            </Button>
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select
              className="select-request"
              showSearch={true}
              searchBy="label"
              optionFilterProp="children"
              disabled={!isEdit || statusCheck}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) === 0
              }
            >
              <Option value={0}>{t("accepted")}</Option>
              <Option value={1}>{t("not_accepted")}</Option>
              <Option value={2}>{t("in_progress")}</Option>
              <Option value={3}>{t("done")}</Option>
              <Option value={4}>{t("not_active")}</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <CreateAddress isVisible={isVisibleCreate} onClose={toggleDrawer} /> */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="hrb_number"
            label={t("hrb_number")}
            rules={[{ required: false }]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="ustid_number"
            label={t("ustid_number")}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="tax_number"
            label={t("tax_number")}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input className="input-request" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
