import React, { useEffect, useState } from "react";
import { Button, Col } from "antd";
import { useTranslation } from "react-i18next";
import { CreateRoles } from "../../../components/Drawer/Roles/CreateRoles";
import { EditRole } from "../../../components/Drawer/Roles/EditRole";
import { toastNotification } from "../../../utils/toastNotification";
import { permissionsFilter } from "../../../utils/permissionsFilter";
import { SearchBy } from "../../../components/Tables/Roles/SearchBy";
import RolesTable from "../../../components/Tables/Roles";
import Api from "../../../api";
import "../../../public/sass/pages/_roles.scss";

const RolesContainer = () => {
  const { t, i18n } = useTranslation();
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [editData, setData] = useState([]);

  useEffect(() => {
    handleGetAllRoles();
  }, []);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllRoles = () => {
    Api("roles", "GET", null)
      .then((data) => {
        setRoles(data.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getRoleById = (id) => {
    if (!id) return message.error("Id is missing!");
    setLoading(true);
    Api("roles/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || `${t("something_went_wrong")}`);
        setLoading(false);
      });
  };

  const deleteRole = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("roles/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", `${t("role_successfully_deleted")}`);
        handleGetAllRoles();
      })
      .catch(() => toastNotification("error", `${t("error_delete_role")}`));
  };

  const handleSearch = (e) => {
    if (!e) return;
    let value = e.toLowerCase();

    if (value.length > 2) {
      setLoading(true);

      Api(`roles?name=` + value, "GET", null)
        .then((data) => {
          if (data) {
            setRoles(data?.data);

            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      handleGetAllRoles();
    }
  };

  return (
    <div className="roles-container">
      <div className="header">
        <Col flex="1 1 200px">
          <SearchBy onSearch={handleSearch} t={t} />
        </Col>

        {permissionsFilter("Roles", "RoleCreate") && (
          <Col flex="0 1 300px">
            <Button
              style={{ float: "right" }}
              className="button"
              onClick={() => handleModal("create", true)}
            >
              {t("add_role")}
            </Button>
          </Col>
        )}

        <CreateRoles
          onClose={handleModal}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          handleGetAllRoles={handleGetAllRoles}
        />

        <EditRole
          data={editData}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllRoles={handleGetAllRoles}
          onClose={handleModal}
        />
      </div>
      <RolesTable
        roles={roles}
        getRoleById={getRoleById}
        deleteRole={deleteRole}
        loading={loading}
      />
    </div>
  );
};
export default RolesContainer;
