import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "./MyDocumentStyle";

export const MyDocument = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.title}>
          <Text>Ausgewählte Postionen aus der Datenbanktabelle</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1Header}>
              <Text style={styles.tableCellHeader}>ID</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Beschreibung</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Kategorie</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Zerschlagungswert</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Fortführungswert</Text>
            </View>
            <View style={styles.tableCol1Header}>
              <Text style={styles.tableCellHeader}>Marktwert</Text>
            </View>
          </View>
          {data.map((item, idx) => (
            <View
              key={idx}
              style={
                idx % 2 === 0
                  ? { backgroundColor: "#ffffff" }
                  : { backgroundColor: "#f8f8f8" }
              }
            >
              <View style={styles.tableRows} key={idx}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    {item?.product_id || "No product ID"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item?.short_description || "No description"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item?.category || "No category"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item?.breakdown_value || "No breakdown value"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item?.going_concern_value || "No gcv"}
                  </Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    {item?.market_value || "No market value"}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
