const enLanguage = {
  login: "Login",
  email_is_required: "Email is required",
  password_is_required: "Password is required",
  invalid_email: "The input is not a valid email",
  input_email: "Please input your Email!",
  input_password: "Please input your password!",
  input_confirm_password: "Please confirm your password!",
  welcome: "Welcome",
  back: "back",
  password: "Password",
  submit: "Submit",
  register: "Register",
  confirm_password: "Confirm Password",
  remember_me: "Remember Me",
  forgot_password: "Forgot Password",
  not_registered_yet: "Not registered yet",
  register_here: "Register here",
  reset_password: "Reset Password",
  old_password: "Old Password",
  new_password: "New Password",
  change_password: "Change Password",
  back_to_login: "Back to Login",
  client: "Client",
  procedure: "Procedure",
  property: "Property",
  add_deals: "Add Deals",
  add_client: "Add Clients",
  logout: "Logout",
  home: "Home",
  report: "Report",
  company: "Company",
  admin: "Administrator",
  search: "Search",
  create_new_user: "Create a New User",
  create_new_role: "Create a New Role",
  cancel: "Cancel",
  name: "Name",
  enter_user_name: "Please enter user name",
  enter_url: "Please enter URL",
  owner: "Owner",
  select_owner: "Please select an owner",
  type: "Type",
  choose_type: "Please choose the type",
  approver: "Approver",
  choose_approver: "Please choose the approver",
  start_date: "Start date",
  description: "Description",
  enter_description: "Please enter URL description",
  tel: "Phone",
  enter_tel: "Please enter phone number",
  customer: "Customer",
  choose_customer: "Choose customer",
  action: "Action",
  add_property: "Add Property",
  add_user: "Add User",
  add_role: "Add Role",
  view_results: "View Results",
  new_property: "New Property",
  property_type: "Property Type",
  value: "Value",
  designation: "Designation",
  position: "Position",
  enter_number: "Please enter number",
  enter_property: "Please enter property",
  enter_property_type: "Please enter property type",
  enter_value: "Please enter value",
  enter_status: "Please enter status",
  enter_designation: "Please enter designation",
  enter_position: "Please enter position",
  upload_image: "Upload Image",
  upload_files: "Documents about Verfahren ",
  upload_files_actions: "Dokument bzgl. Verfahren",
  product_status: "Product Status",

  upload: "Upload",

  //CreateUser
  firstname: "Firstname",
  enter_user_firstname: "Please enter firstname",
  lastname: "Lastname",
  enter_user_lastname: "Please enter lastname",
  email: "Email",
  enter_user_email: "Please enter email",
  phone: "Phone number",
  enter_user_phone: "Please enter phone number",
  country: "Country",
  enter_user_country: "Please enter country",
  city: "City",
  enter_user_city: "Please enter city",
  street: "Street",
  enter_user_street: "Please enter street",
  house_number: "House number",
  enter_user_house_number: "Please enter house number",
  zip: "ZIP",
  enter_user_zip: "Please enter zip",
  role: "Role",
  choose_user_role: "Please choose user role",
  choose_user_company: "Please choose company",
  status: "Status",
  choose_user_status: "Please choose status",

  add_company: "Add Company",
  enter_company_name: "Please enter company name",
  owner_firstname: "Owner Firstname",
  enter_owner_firstname: "Please enter owner firstname",
  owner_lastname: "Lastname",
  enter_owner_lastname: "Please enter owner lastname",
  company_type: "Type",
  choose_company_type: "Please choose company type",
  hrb_number: "HRB number",
  enter_company_hrb_number: "Please enter HRB number",
  ustid_number: "USTID number",
  enter_company_ustid_number: "Please enter USTID number",
  tax_number: "TAX number",
  ClientAnfragen: "Customer",
  enter_company_tax_number: "Please enter TAX number",
  user_id_required: "User id is required",

  my_profile: "My Profile",
  create_new_company: "Create New Company",
  create_new_client: "Create New Client",
  are_you_sure: "Are you sure?",
  active: "Active",
  not_active: "Not Active",
  submit_2: "Submit",
  not_accepted: "Not accepted",

  //categories
  create_new_category: "Create new category",
  enter_category_name: "Please enter category name",
  category_position: "Position",
  enter_category_position: "Please enter position",
  decription: "Description",
  enter_category_description: "Please enter description",
  add_category: "Create category",

  inventory_number: "Inventory number",
  enter_inventory_number: "Please enter inventory number",
  product_name: "Productname",
  enter_product_name: "Please enter product name",
  short_description: "Short description",
  enter_short_description: "Please enter short description",
  category: "Category",
  enter_category: "Please enter category",
  quantity: "Quantity",
  enter_quantity: "Please enter quantity",
  packaging_unit: "Packaging unit",
  enter_packaging_unit: "Please enter packaging unit",
  qty_packaging_unit: "Qty of packaging unit",
  enter_qty_packaging_unit: "Please enter QTY of packaging unit",
  assessment_type: "Type of assessment",
  enter_assessment_type: "Please enter assessment type",
  breakdown_value: "Breakdown value",
  enter_breakdown_value: "Please enter breakdown value",
  premium_percent: "Premium percent",
  enter_premium_percent: "Please enter premium percent",
  premium: "Premium",
  enter_premium: "Please enter premium",
  breakdown_premium: "Breakdown & Premium",
  enter_breakdown_premium: "Please enter Breakdown & Premium",
  subtotal: "Subtotal",
  enter_subtotal: "Please enter subtotal",
  tax_type: "Tax Type",
  enter_tax_type: "Please enter tax type",
  tax_costs: "Tax costs",
  enter_tax_costs: "Please enter tax costs",
  total_incl_tax: "Total incl. Tax",
  enter_total_incl_tax: "Please enter total incl. tax",
  going_concern_value: "Going concern value",
  enter_going_concern_value: "Please enter the going concern value",
  market_value: "Market value",
  enter_market_value: "Please enter market value",
  comment: "Comment",
  enter_comment: "Please enter comment",
  asset_type: "Asset type",
  enter_asset_type: "Please enter asset type",
  address_from_product: "Address from product",
  enter_address_from_product: "Please enter address from product",
  location_from_product: "Location from product",
  enter_location_from_product: "Please enter location form product",
  created_at: "Creation Date",

  new_deal: "New Deal",
  enter_admin: "Enter admin name",
  edit_company: "Edit Company",
  city_name: "City",
  enter_city_name: "Please enter city name",
  company_number: "Company number",
  enter_company_number: "Please enter company number",
  more_addresses: "Add Addresses",
  more_products: "Add Products",
  edit_user: "Edit User",
  edit_role: "Edit Role",
  edit_role_form: "Role",
  user_number: "User Number",
  edit_client: "Edit Client",
  product_number: "Product number",
  product_order_successful: "Product order updated succesfully.",
  mileage: "Mileage",
  select: "Select",
  type_of_mileage: "Type of mileage",
  approved: "Approved",
  accepted: "Accepted",
  rights: "Separation and segregation rights",
  segregation_rights: "Segragation rights",
  client_number: "Client Number",
  from_customer: "From Customer",
  enter_customer: "Please enter customer",
  customer_kunde: "Customer",
  type_of_prcedure: "Type of procedure",
  enter_type_of_procedure: "Please enter type of procedure",
  disposal_costs: "Disposal costs",
  enter_disposal_costs: "Please enter disposal costs",
  category_type: "Category Type",
  enter_category_type: "Please enter category type",
  category_number: "Category Number",
  category_description: "Category Description",
  subcategory_from: "Subcategory from",
  choose_subcategory: "Choose subcategory",
  choose_client: "Please choose client",
  enter_mileage: "Please enter mileage",
  enter_type_of_mileage: "Please enter type of mileage",
  enter_other: "",
  select_rights: "Please select",
  company_name: "ADDRESS",
  save: "Save",
  edit_category: "Edit Category",
  edit_customer: "Edit Customer",
  edit_customer_request: "Edit Customer",
  customer_number: "Customer Number",
  enter_customer_name: "Please enter the customer name",
  third_party: "Third Party App",
  choose_third_party: "Choose third party",
  subcategory: "Subcategory",
  category_name: "Category name",
  piece: "Piece",
  litre: "Litre",
  assessment: "Assessment",
  desktop_assessment: "Desktoprating",
  create_new_customer: "Create new customer",
  create_new_customer_request: "Create new customer",
  create_new_position: "Create new position",
  edit_position: "Edit Position",
  add_position: "Add Position",
  no_positions_found: "No positions found, please create...",
  create_new_word: "Create new word",
  edit_word: "Edit word",
  add_word: "Add word",
  no_words_found: "No words found, please create...",
  word: "Word",
  permissions_role: "Name",

  add_third_party: "Add third party",
  create_new_third_party: "Create new third party",
  edit_third_party: "Edit third party",
  add_open_request: "Add open request",
  address: "Address",
  new_address: "New Address",
  addresses: "Addresses",
  add_new_address: "Add new address",
  user_profile: "User Profile",
  password_2: "Password",
  search_product: "Search product",
  search_by_name: "Search by name",
  search_by_name_role: "Search by role",
  password_successfully_changed: "Password successfully changed",
  please_login_with_the_new_password: "Please login with the new password",
  success: "Success",

  //Menu and sub menu
  Sachaufnahme: "Asset Capture",
  Drittrechte: "Third Party Rights",
  Adressverwaltung: "Address Management",
  assets: "Asset Capture",

  //toastNotification
  address_edited_successfully: "Address edited successfully!",
  error_edit_address: "Error while editing address!",
  address_deleted_successfully: "Address was deleted successfully!",
  error_delete_address: "Error while deleting address!",
  user_created_successfully: "User was created successfully!",
  error_create_user: "Error while creating user!",
  category_edited_successfully: "Category edited successfully!",
  error_edit_category: "Error while editing category!",
  address_created_successfully: "Address successfully created!",
  error_create_address: "Error while creating address!",
  product_created_successfully: "Product successfully created!",
  error_create_products: "Error while creating products!",
  word_created_successfully: "Word successfully created!",
  error_create_words: "Error while creating words!",
  successfully_edited_client: "Client edited successfully!",
  error_edit_client: "Error while editing client!",
  successfully_created_company: "Company successfully created!",
  error_create_company: "Error while creating company!",
  successfully_edited_company: "Company edited successfully!",
  error_edit_company: "Error while editing company!",
  customer_created_successfully: "Customer successfully created!",
  error_create_customer: "Error while creating customer!",
  customer_edited_successfully: "Customer successfully edited!",
  error_edit_customer: "Error while editing customer!",
  product_successfully_edited: "Product successfully edited!",
  error_edit_product: "Error while editing product!",
  word_successfully_edited: "Word successfully edited!",
  error_edit_word: "Error while editing word!",
  successfully_created_third_party: "Successfully created Third Party!",
  error_create_third_party: "Error while creating Third Party!",
  successfully_edited_third_party: "Successfully edited Third Party!",
  error_edit_third_party: "Error while editing third party!",
  successfully_edited_user: "User edited successfully!",
  error_edit_user: "Error while editing user!",
  password_successfully_changed: "Password was successfully changed!",
  error_change_password: "Error while changing password!",
  error_send_mail: "Error while sending email!",
  email_sent: "Email sent!",
  category_successfully_created: "Category successfully created!",
  error_create_category: "Error while creating category!",
  login_with_new_password: "Please login with new password!",
  something_went_wrong: "Something went wrong!",
  category_successfully_deleted: "Category successfully deleted!",
  error_delete_category: "Error while deleting category!",
  client_successfully_deleted: "Client successfully deleted!",
  error_delete_client: "Error while deleting client!",
  company_successfully_deleted: "Company was successfully deleted!",
  error_delete_company: "Error while deleting company!",
  customer_successfully_deleted: "Customer successfully deleted!",
  error_delete_customer: "Error while deleting customer!",
  product_successfully_deleted: "Product successfully deleted!",
  error_delete_product: "Error while deleting product!",
  third_party_successfully_deleted: "Third Party successfully deleted!",
  error_delete_third_party: "Error while deleting third party!",
  user_successfully_deleted: "User successfully deleted!",
  error_delete_user: "Error while deleting user!",
  role_successfully_created: "Role successfully created!",
  role_successfully_deleted: "Role successfully deleted!",
  error_delete_role: "Error while deleting role!",
  error_create_role: "Error while creating role!",
  create_role: "Role",

  austria: "Austria",
  germany: "Germany",

  disposal: "Disposal",
  disposal_e: "Disposal",
  no_comment: "No comment",
  fixed_installation: "Fixed installation",
  real_estate_adapted: "Real Estate Adapted",
  without_rating: "Without rating",
  not_visited: "Not visited",
  other: "Other",
  free_mass: "Free Mass",
  segregation: "Segregation",
  foreign_property: "Foreign property",
  simple_retention_of_title: "Simple retention of title",
  error_change_password_repeat:
    "The two passwords that you entered do not match!",

  new_request: "New request",
  details: "Details",
  positions: "Positions",
  upload_excel: "Upload Excel file",
  add_customer: "Add customer",
  add_customer_client: "Add customer",
  rental_fee: "Rental fee",
  hire_purchase: "Hire purchase",
  other_option: "Other (with the option of manual input)",
  financing_loan_protection: "Financing / loan protection",
  transfer_by_security_way: "Transfer by way of security",
  area_security_transfer: "Area security transfer",
  landlords_lien: "Landlord's lien",
  extended_title_reservation: "Extended / extended reservation of title",
  add_procedure: "Add procedure",

  in_progress: "In Progress",
  done: "Done",
  download_csv: "Download CSV",
  choose_type_of_procedure: "Choose type of procedure",
  download_pdf: "Download PDF",
  download: "download",

  user_limit: "User limit",
  enter_user_limit: "Please enter user limit",
  user: "User",
  invoice: "Invoice",
  send_email: "Send email",
  permissions: "Permissions",
  database: "Database",
  databasetwo: "Database II",
  image: "Upload image",
  field_required: "This field is required",

  status_accepted: "Accepted",
  status_not_accepted: "Not accepted",
  status_in_progress: "In Progress",
  status_done: "Done",
  status_not_active: "Not active",
  click_to_upload: "Click to upload",
  click_sort: "Click to sort",

  specifications: "Appraiser specifications",
  document_info: "Comments on the report",
  material_records_info: "Comment on the recording of the matter",
  date_one: "Start",
  date_two: "Finished",

  open_new_tab: "Open in new tab",
  share_docs: "Share your documents",
  enter_message: "Enter your message...",
  close: "Close",
  clear_filter: "Clear filter",
  user_qty: "User quantity",
  words: "Words",

  contract: "Contract",
  appriauser_type: "Appriauser type",
  uploadfiles: "Upload Files",
  uploadImage: "Upload",
  role: "Role",
  reset: "Reset",
  product_order_message: "Product order set succesfully.",
  set: "Set",
};
export default enLanguage;
