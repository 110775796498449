import { toastNotification } from "./toastNotification";

// permissionsFilter parameters should be the value of the feature inside permissions.json
// For the whole feature to be included just add the parent object value inside permissions.json

export const permissionsFilter = (param1, param2) => {
    const getAllPermissions = localStorage.getItem("user_permissions");

    if (!param1) {
        return toastNotification("error", `The first parameter is required for permissionsFilter hook.`);
    } else {
        return [param1, param2 && param2].some(r => getAllPermissions?.includes(r))
    }
}