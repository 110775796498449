import React from "react";
import { useTranslation } from "react-i18next";
import { Space, Input, Select } from "antd";

import "../../../public/sass/components/_filters.scss";

const { Option } = Select;
const { Search } = Input;

export const Filters = ({ onSearch }) => {
  const { t, i18n } = useTranslation();



  return null;
  return (
    <div className="wrapper">
      <Space direction="horizontal" className="filters">
        <Select
          defaultValue={t("property")}
          style={{ width: 100 }}
          className="filter-style"
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
        <Select
          defaultValue="Status"
          style={{ width: 100 }}
          className="filter-style"
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
        <Search
          placeholder={t("search")}
          onSearch={onSearch}
          style={{ width: 100 }}
        />
      </Space>
    </div>
  );
};
