import React, { useEffect, useState } from "react";
import { Divider, Popconfirm, Modal, Button } from "antd";

import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import Api from "../../../api";

import "../../../public/sass/layout/_index.scss";
import { toastNotification } from "../../../utils/toastNotification";
import { SortableTable } from "../../SortableTable";

const PositionsActionTable = ({
  products,
  loading,
  deleteProduct,
  getProductById,
  id,
  lockEditById,
  lock,
  setProducts
}) => {
  const { t, i18n } = useTranslation();
  const [isQRCode, setQRCode] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    products.forEach((item, index) => {
      item.action_order_nr = index + 1;
    });
  }, [products]);

  const setPositions = () => {
    Api("actions/" + id, "PATCH", { is_set: true })
      .then(() => {
        toastNotification("success", `${t("product_order_message")}`);
        lockEditById();
      })
      .catch((err) => toastNotification("error", err.error || "error"));
  };

  return (
    <div className="table-wrapper">
      <Divider />
      <SortableTable
        loading={loading}
        deleteProduct={deleteProduct}
        setProducts={setProducts}
        products={products}
        lock={lock}
        getProductById={getProductById}
      />

      <Popconfirm title={t("are_you_sure")} onConfirm={() => setPositions()}>
        <Button className={lock && "button-clicked"}>{t("set")}</Button>{" "}
      </Popconfirm>

      <Modal
        width={350}
        style={{ textAlign: "center" }}
        title={name}
        centered
        visible={isQRCode}
        onOk={() => setQRCode(!isQRCode)}
        onCancel={() => setQRCode(!isQRCode)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <p
            style={{
              margin: "10px 25px",
              textAlign: "center",
              paddingBottom: "20px",
              color: "#9c9c9c",
            }}
          >
            Scan this QR code to get this product and import into our database.
          </p> */}
          <QRCode size={150} value={Math.floor(Math.random() * 100000 + 1)} />
          {/* <p
            style={{
              margin: "10px 25px",
              textAlign: "center",
              fontSize: "12px",
              paddingTop: "25px",
              color: "#9c9c9c",
            }}
          >
            This is a single use code for use only. Get a new code each time you
            get a product
          </p> */}
        </div>
      </Modal>
    </div>
  );
};
export default PositionsActionTable;
