import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";

import { Popconfirm, Space, Table } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { ExclamationOutlined, MenuOutlined } from "@ant-design/icons";
import { toastNotification } from "../utils/toastNotification";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../utils/formatPrice";
import exclamationMark from "../public/images/dashboard/exclamationMark.svg";
import Api from "../api";

import Eye from "../public/images/dashboard/tables/eye.svg";
import Delete from "../public/images/dashboard/tables/delete.svg";

const DragHandle = sortableHandle(() => <MenuOutlined />);

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const SortableTable = ({
  loading,
  lock,
  getProductById,
  setProducts,
  products,
  deleteProduct,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [products]);

  const getColumns = () => {
    return [
      {
        title: "Sort",
        dataIndex: "",
        className: "drag-visible",
        hidden: lock,
        render: (d, dd, i) => (
          <>
            <DragHandle active={selectedItems.includes(i + 1)} />
          </>
        ),
      },
      {
        title: "#",
        dataIndex: "action_order_nr",
        key: "action_order_nr",
        sorter: {
          compare: (a, b) => a.action_order_nr - b.action_order_nr,
          multiple: 2,
        },
      },
      {
        title: "Nr",
        dataIndex: "product_id",
        key: "product_id",
        render: (product_id) => <>P{product_id}</>,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        render: (name) => <>{name == "undefined" ? "" : name}</>,
      },
      {
        title: "Aus- und Absonderungsrechte",
        dataIndex: "rights",
        key: "rights",
        sorter: (a, b) => a.rights.length - b.rights.length,
        render: (rights) => <>{rights == "undefined" ? "" : rights}</>,
      },
      {
        title: "Zerschlagungswert",
        dataIndex: "breakdown_value",
        key: "breakdown_value",
        sorter: (a, b) => a.breakdown_value.length - b.breakdown_value.length,
        render: (breakdown_value) => (
          <>
            <p>
              {breakdown_value == "undefined"
                ? ""
                : formatPrice(breakdown_value)}
            </p>
          </>
        ),
      },
      {
        title: "",
        dataIndex: "role",
        key: "role",
        render: (e) => {
          return e?.name == "Data Creator" && <img src={exclamationMark} />;
        },
      },
      {
        title: "Aktion",
        key: "action",
        render: (e) => (
          <Space size="middle">
            <a onClick={() => getProductById(e?.id)}>
              <img src={Eye} alt="Edit" />
            </a>
            <Popconfirm
              title={t("are_you_sure")}
              onConfirm={() => deleteProduct(e?.id)}
            >
              <a>
                <img src={Delete} alt="Delete" />
              </a>
            </Popconfirm>
          </Space>
        ),
      },
    ].filter((item) => !item.hidden == true);
  };

  const merge = (a, b, i = 0) => {
    let aa = [...a];
    return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tempDataSource = products;

    if (oldIndex !== newIndex) {
      if (!selectedItems.length) {
        let movingItem = tempDataSource[oldIndex];
        let dropItem = tempDataSource[newIndex];

        movingItem.action_order_nr = newIndex + 1;
        dropItem.action_order_nr = oldIndex + 1;

        tempDataSource.splice(oldIndex, 1);
        tempDataSource = merge(tempDataSource, [movingItem], newIndex);
      } else {
        let filteredItems = [];
        selectedItems.forEach((d) => {
          filteredItems.push(tempDataSource[d]);
        });

        let newData = [];
        tempDataSource.forEach((d, i) => {
          if (!selectedItems.includes(i)) {
            newData.push(d);
          }
        });

        tempDataSource = [...newData];
        tempDataSource = merge(tempDataSource, filteredItems, newIndex);
      }

      setTimeout(() => {
        Api("products/update-order", "POST", tempDataSource)
          .then(() => {
            toastNotification("success", `${t("product_order_successful")}`);
          })
          .catch((err) => toastNotification("error", err.error || "Error"));
      }, 10);

      setProducts(tempDataSource);
      setSelectedItems([]);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = products.findIndex((x) => x.id === restProps["data-row-key"]);
    return (
      <SortableItem
        index={index}
        {...restProps}
        selected={selectedItems.length}
      />
    );
  };

  return (
    <>
      <Table
        loading={loading}
        pagination={false}
        dataSource={products}
        columns={getColumns()}
        rowKey="id"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </>
  );
};
