import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { EditForm } from "./EditForm";
import { PositionsContainer } from "../PositionsContainer";
import "../../../../public/sass/components/drawer/_tabs.scss";

export const EditRequestTabs = ({
  onClose,
  id,
  data,
  isVisible,
  getOpenRequests,
  getFinishedRequests,
  type,
  setEdit,
  isEdit,
}) => {
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const [statusCheck, setStatus] = useState(false);

  useEffect(() => {
    setStatus(false);
    data?.products.map((item) => {
      if (item.status !== 0) {
        setStatus(true);
      }
    });
  },[isVisible])

  return (
    <Tabs
      defaultActiveKey="1"
      className="tab-style"
    >
      <TabPane tab={t("details")} key="1" className={`${data.status == 3 ? " done-position" : 'tab-pane-style'}`} >
        <EditForm
          onClose={onClose}
          id={id}
          isVisible={isVisible}
          data={data}
          getOpenRequests={getOpenRequests}
          getFinishedRequests={getFinishedRequests}
          type={type}
          setEdit={setEdit}
          isEdit={isEdit}
          statusCheck={statusCheck}
        />
      </TabPane>
      <TabPane tab={t("positions")} key="3" className="tab-pane-style">
        <PositionsContainer onClose={onClose} data={data} id={id} setStatus={setStatus} />
      </TabPane>
    </Tabs>
  );
};
