import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import moment from "moment";

export const LastPageOne = ({ document_info, city, date_two }) => {
  return (
    <Page
      size={"A4"}
      style={{
        backgroundColor: "#fff",
        padding: "40px",
        // fontFamily: "Open Sans",
      }}
    >
      <PdfHeader imgUrl="https://i.imgur.com/BUQFkU3.jpg" />
      <View
        style={{
          margin: "30px 0",
          fontSize: "12px",
          lineHeight: "1.5",
          fontWeight: 500,
        }}
      >
        <View style={{ marginTop: "50px", color: '#3E5078' }}>
             <Text style={{ fontSize: "18px", marginBottom: 10 }}>
              Bemerkung zum Gutachten
            </Text>

          <Text style={{ paddingBottom: "15px", lineHeight: 1.7 }}>
            {document_info}
          </Text>
          {/* <Text>{`${city}, ${moment(date_two || new Date()).format("DD.MM.YYYY")} `}</Text> */}
        </View>
      </View>
    </Page>
  );
};
