// Icons
import React from "react";
import {
  LineChartOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Route, Switch } from "react-router-dom";
// Utils Routes
import Loading from "./shared/Loading";
import NotFound from "./shared/NotFound";

// Authentication Routes
import Login from "../containers/Auth/Login";
import Register from "../containers/Auth/Register";
import ResetPassword from "../containers/Auth/ResetPassword";
import ChangePassword from "../containers/Auth/ChangePassword";

import "../public/sass/pages/_dashboard.scss";

// Container Routes
import App from "../containers/App";

// Lazy loading
const DashboardMain = React.lazy(() =>
  import("../containers/Dashboard/DashboardMain")
);
const DashboardView = React.lazy(() =>
  import("../containers/Dashboard/DashboardView")
);

// Routing levels
import CustomersContainer from "../containers/Dashboard/Customers/CustomersContainer";
import PositionsContainer from "../containers/Dashboard/Positions/PositionsContainer";
import OpenRequestsContainer from "../containers/Dashboard/OpenRequests/OpenRequestsContainer";
import FinishedRequestsContainer from "../containers/Dashboard/FinishedRequests/FinishedRequestsContainer";
import ClientsContainer from "../containers/Dashboard/Clients/ClientsContainer";
import DatabaseContainer from "../containers/Dashboard/Database/DatabaseContainer";
import DatabaseTwoContainer from "../containers/Dashboard/DatabaseTwo/DatabaseTwoContainer";

import UsersContainer from "../containers/Dashboard/Users/UsersContainer";
import CompaniesContainer from "../containers/Dashboard/Companies/CompaniesContainer";
import CategoriesContainer from "../containers/Dashboard/Categories/CategoriesContainer";
import ThirdPartyContainer from "../containers/Dashboard/ThirdParty/ThirdPartyContainer";
import MyProfile from "../containers/Dashboard/MyProfile";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import ClientsRequestsContainer from "../containers/Dashboard/Clients-Requests/ClientsRequestsContainer";
import WordsContainer from "../containers/Dashboard/Worter/WordsContainer";
import RolesContainer from "../containers/Dashboard/Roles/RolesContainer";

const ROUTES = [
  {
    path: "/",
    key: "ROOT",
    exact: true,
    component: (props) => <App {...props} />,
  },
  {
    path: "/dashboard",
    key: "Main",
    component: (props) => <DashboardMain {...props} />,
    routes: [
      {
        path: "/dashboard",
        key: "dashboard",
        exact: true,
        permissions: [
          "Categories",
          "CategoryCreate",
          "CategoryRead",
          "CategoryUpdate",
          "CategoryDelete",
          "Products",
          "ProductCreate",
          "ProductRead",
          "ProductUpdate",
          "ProductDelete",
          "Words",
          "WordCreate",
          "WordRead",
          "WordUpdate",
          "WordDelete",
        ],
        categoryName: "Sachaufnahme",
        icon: LineChartOutlined,
        component: (props) => <DashboardView {...props} />,
        children: [
          {
            path: "/dashboard/assessment-properties",
            key: "sachanlagen",
            permissions: [
              "Categories",
              "CategoryCreate",
              "CategoryRead",
              "CategoryUpdate",
              "CategoryDelete",
            ],
            categoryName: "Assets",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/assessment-positions",
            key: "Positions-sub",
            permissions: [
              "Products",
              "ProductCreate",
              "ProductRead",
              "ProductUpdate",
              "ProductDelete",
            ],
            categoryName: "Positions",
            icon: LineChartOutlined,
          },
        ],
      },
      {
        path: "/dashboard/users",
        key: "users",
        exact: true,
        permissions: [
          "Request Clients",
          "RequestClientCreate",
          "RequestClientRead",
          "RequestClientUpdate",
          "RequestClientDelete",
          "Open Requests",
          "OpenRequestCreate",
          "OpenRequestRead",
          "OpenRequestUpdate",
          "OpenRequestDelete",
          "Finished Requests",
          "FinishedRequestCreate",
          "FinishedRequestRead",
          "FinishedRequestUpdate",
          "FinishedRequestDelete",
        ],
        categoryName: "Requests",
        icon: FolderOutlined,
        component: () => <div>Welcome to Anfragen</div>,
        children: [
          {
            path: "/dashboard/requests-clients",
            key: "dashboard-sub-users-clients",
            permissions: [
              "Request Clients",
              "RequestClientCreate",
              "RequestClientRead",
              "RequestClientUpdate",
              "RequestClientDelete",
            ],
            categoryName: "ClientAnfragen",
            icon: FolderOutlined,
            component: () => <div>Welcome to Client Requests</div>,
          },
          {
            path: "/dashboard/requests-open",
            key: "dashboard-sub-users",
            permissions: [
              "Open Requests",
              "OpenRequestCreate",
              "OpenRequestRead",
              "OpenRequestUpdate",
              "OpenRequestDelete",
            ],
            categoryName: "Open",
            icon: FolderOutlined,
            component: () => <div>Welcome to Anfragen</div>,
          },
          {
            path: "/dashboard/requests-finished",
            key: "dashboard-sub-users-2",
            permissions: [
              "Finished Requests",
              "FinishedRequestCreate",
              "FinishedRequestRead",
              "FinishedRequestUpdate",
              "FinishedRequestDelete",
            ],
            categoryName: "Done",
            icon: FolderOutlined,
            component: () => <div>Welcome to Anfragen</div>,
          },
        ],
      },
      {
        path: "/dashboard/address",
        key: "address",
        exact: true,
        permissions: [
          "Report Clients",
          "ReportClientCreate",
          "ReportClientRead",
          "ReportClientUpdate",
          "ReportClientDelete",
          "Proceedings",
          "ProceedingCreate",
          "ProceedingRead",
          "ProceedingUpdate",
          "ProceedingDelete",
          "Third Party Rights",
          "ThirdPartyRightCreate",
          "ThirdPartyRightRead",
          "ThirdPartyRightUpdate",
          "ThirdPartyRightDelete",
        ],
        categoryName: "Report",
        icon: FolderOpenOutlined,
        component: (props) => <CustomersContainer {...props} />,
        children: [
          {
            path: "/dashboard/address-customers",
            key: "dashboard-sub-address-client",
            permissions: [
              "Report Clients",
              "ReportClientCreate",
              "ReportClientRead",
              "ReportClientUpdate",
              "ReportClientDelete",
            ],
            categoryName: "Client",
            icon: FolderOpenOutlined,
          },
          {
            path: "/dashboard/address-clients",
            key: "dashboard-sub-address",
            permissions: [
              "Proceedings",
              "ProceedingCreate",
              "ProceedingRead",
              "ProceedingUpdate",
              "ProceedingDelete",
            ],
            categoryName: "Proceedings",
            icon: FolderOpenOutlined,
          },
          {
            path: "/dashboard/address-drittrechte",
            key: "dashboard-sub-address-drittrechte",
            permissions: [
              "Third Party Rights",
              "ThirdPartyRightCreate",
              "ThirdPartyRightRead",
              "ThirdPartyRightUpdate",
              "ThirdPartyRightDelete",
            ],
            categoryName: "Drittrechte",
            icon: FolderOpenOutlined,
          },
        ],
      },
      {
        path: "/dashboard/database",
        key: "database",
        exact: true,
        permissions: ["Database 1", "Database 2"],
        categoryName: "Evaluations",
        icon: DatabaseOutlined,
        component: (props) => <DatabaseContainer {...props} />,
        children: [
          {
            path: "/dashboard/database",
            key: "database",
            // id: "fdf2c060-20fc-4ab4-804e-eb10945c5297",
            exact: true,
            permissions: ["Database 2"],
            categoryName: "Database2",
          },
          {
            path: "/dashboard/database2",
            key: "databasetwo",
            exact: true,
            permissions: ["Database 1"],
            categoryName: "Database",
          },
        ],
      },
      {
        path: "/dashboard/administration",
        key: "administration",
        exact: true,
        permissions: [
          "Companies",
          "CompanyCreate",
          "CompanyRead",
          "CompanyUpdate",
          "CompanyDelete",
          "Users",
          "UserCreate",
          "UserRead",
          "UserUpdate",
          "UserDelete",
          "Roles",
          "RoleCreate",
          "RoleRead",
          "RoleUpdate",
          "RoleDelete",
        ],
        categoryName: "Administration",
        icon: LineChartOutlined,
        component: (props) => <DashboardView {...props} />,
        children: [
          {
            path: "/dashboard/administration-companies",
            key: "companies",
            permissions: [
              "Companies",
              "CompanyCreate",
              "CompanyRead",
              "CompanyUpdate",
              "CompanyDelete",
            ],
            categoryName: "Companies",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/administration-users",
            key: "users",
            permissions: [
              "Users",
              "UserCreate",
              "UserRead",
              "UserUpdate",
              "UserDelete",
            ],
            categoryName: "Users",
            icon: LineChartOutlined,
          },
          ,
          {
            path: "/dashboard/administration-roles",
            key: "roles",
            permissions: [
              "Roles",
              "RoleCreate",
              "RoleRead",
              "RoleUpdate",
              "RoleDelete",
            ],
            categoryName: "Roles",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/assessment-worter",
            key: "Worter-sub",
            permissions: [
              "Words",
              "WordCreate",
              "WordRead",
              "WordUpdate",
              "WordDelete",
            ],
            categoryName: "Words",
            icon: LineChartOutlined,
          },
        ],
      },
      // Render child routes
      {
        hidden: true,
        categoryName: "Sachaufnahme",
        subCategory: "Assets",
        path: "/dashboard/assessment-properties",
        component: (props) => <CategoriesContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Sachaufnahme",
        subCategory: "Positions",
        path: "/dashboard/assessment-positions",
        component: (props) => <PositionsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Administration",
        subCategory: "Words",
        path: "/dashboard/assessment-worter",
        component: (props) => <WordsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Requests",
        subCategory: "Client",
        path: "/dashboard/requests-clients",
        component: (props) => <ClientsRequestsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Requests",
        subCategory: "Open",
        path: "/dashboard/requests-open",
        component: (props) => <OpenRequestsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Requests",
        subCategory: "Done",
        path: "/dashboard/requests-finished",
        component: (props) => <FinishedRequestsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Report",
        subCategory: "Client",
        path: "/dashboard/address-customers",
        component: (props) => <CustomersContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Report",
        subCategory: "Proceedings",
        path: "/dashboard/address-clients",
        component: (props) => <ClientsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Report",
        subCategory: "Drittrechte",
        path: "/dashboard/address-drittrechte",
        component: (props) => <ThirdPartyContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Evaluations",
        subCategory: "Database",
        path: "/dashboard/database",
        component: (props) => <DatabaseContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Evaluations",
        subCategory: "Database2",
        path: "/dashboard/database2",
        component: (props) => <DatabaseTwoContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Administration",
        subCategory: "Users",
        path: "/dashboard/administration-users",
        component: (props) => <UsersContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Administration",
        subCategory: "Roles",
        path: "/dashboard/administration-roles",
        component: (props) => <RolesContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Administration",
        subCategory: "Companies",
        path: "/dashboard/administration-companies",
        component: (props) => <CompaniesContainer {...props} />,
      },
      {
        hidden: true,
        path: "/dashboard/my-profile",
        key: "Profile",
        component: (props) => <MyProfile {...props} />,
      },
    ],
  },

  {
    path: "/login",
    key: "AUTH",
    exact: true,
    component: (props) => <Login {...props} />,
  },
  {
    path: "/register",
    key: "AUTH",
    exact: true,
    component: (props) => <Register {...props} />,
  },
  {
    path: "/reset-password",
    key: "AUTH",
    exact: true,
    component: (props) => <ResetPassword {...props} />,
  },
  {
    path: "/change-password",
    key: "AUTH",
    exact: true,
    component: (props) => <ChangePassword {...props} />,
  },
  {
    path: "/forgot-password",
    key: "AUTH",
    exact: true,
    component: (props) => <ForgotPassword {...props} />,
  },
];

export default ROUTES;

export const RenderRoutes = ({ routes }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        {routes.map((route) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route component={(props) => <NotFound {...props} />} />
      </Switch>
    </React.Suspense>
  );
};

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
